<template>
  <div class="main">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'Main',
}
</script>

<style lang="less" scoped>
.main {
  width: 100%;
  min-height: calc(100vh - 70px);
  padding-top: 62px;
  box-sizing: border-box;
}
</style>
