/*
 * @Author: liz
 * @Date: 2023-01-19 16:02:34
 * @version:
 * @Descripttion:
 */
import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

import userModule from "./modules/user.store";

const store = new Vuex.Store({
  modules: {
    user: userModule,
  },
  state: {
    navs: [],
    isLogin: false,
    visitId: "",
    prescription: "",
    patientId: "",
    // 继续就诊详情数据
    continueVisitDetail: {},
    pageInfo: {},
  },
  getters: {
    getVisitId: (state) => {
      return state.visitId;
    },
    getPatientId: (state) => {
      return state.patientId;
    },
    getContinueVisitDetail: (state) => {
      return state.continueVisitDetail;
    },
    pageInfo: (state) => {
      return state.pageInfo;
    },
  },
  mutations: {
    setVisitId(state, val) {
      state.visitId = val;
    },
    setPatientId(state, val) {
      state.patientId = val;
    },
    setContinueVisitDetail(state, val) {
      state.continueVisitDetail = val;
    },
    setPageInfo(state, val) {
      state.pageInfo = val;
    },
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
});

export default store;
