<template>
  <div id="app">
    <router-view />
    <div class="mask">
      <div class="mask-body">
        <div class="mask-title">老系统停用公告</div>
        <div class="mask-desc"  style="margin-top: 20px; font-weight: 500;">亲爱的善济云用户您好</div>
        <div class="mask-desc" style="margin-top: 12px; text-indent: 2em;">为了您在使用系统时，获得更好的体验，善济云系统正在全面升级为新系统，新系统需要用新的网址进行登录，请您点击下边按钮跳转新网址进行使用。默认您的账号信息已经迁移到新系统，如您账号在新系统不能登录，请您联系客服人员处理（联系电话：400-180-6162）</div>
        <div class="mask-desc" style="text-align: center; margin-top: 16px;">汇医必达·善济云团队</div>
        <div class="mask-btn" @click="goNewSystem">立即进入新系统</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  beforeCreate() {
    const url = window.location.href;
    console.log(url, url.search("huaxin.huiyishanji.com"),'url获取')
    if (url.search("huaxin.huiyishanji.com") !== -1) {
      return;
    } else {
      // const UA = window.navigator.userAgent.toLowerCase();

      // const isAndroid = UA && UA.indexOf("android") > 0;
      // const isIOS = UA && /iphone|ipad|ipod|ios/.test(UA);

      // console.log(isAndroid, isIOS);
      // console.log(window.location.href);
      // if (isAndroid || isIOS) {
      //   console.log("动端环境");

      //   if (url.search("https://pad.huiyishanji.com") == -1) {
      //     window.location.href = "https://pad.huiyishanji.com";
      //   }
      // } else {
      //   console.log("PC");
      // }
    }
  },
  methods: {
    goNewSystem() {
      location.replace('https://www.shanjiyun.com')
    }
  }
};
</script>

<style>
.strong {
  font-weight: 600;
}
* {
  margin: 0;
  padding: 0;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
*::-webkit-scrollbar {
  display: none;
}
#app {
  width: 100%;
  height: 100%;
}

html,
body {
  width: 100%;
  height: 100%;
}
.el-message {
  top: 100px !important;
}
.confirmbtn {
  color: #ffffff;
  background-color: #13bfba !important;
  border-color: #13bfba !important;
}
.video-js .vjs-big-play-button {
  transform: scale(0.8);
}
.el-input.is-active .el-input__inner, .el-input__inner:focus {
  border-color: #13bfba !important;
}
</style>
<style lang="less" scoped>
.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999999;
  background-color: rgba(0,0,0,.8);
  display: flex;
  align-items: center;
  justify-content: center;
  .mask-body {
    width: 516px;
    height: 418px;
    background: url('@/assets/images/mask_bg.png') no-repeat center;
    background-size: 100% 100%;
    padding: 0 50px;
    box-sizing: border-box;
    .mask-title {
      height: 21px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      font-size: 22px;
      color: #333333;
      text-align: center;
      margin-top: 98px;
    }
    .mask-desc {
      font-weight: 400;
      font-size: 16px;
      color: #333333;
    }
    .mask-btn {
      width: 226px;
      height: 58px;
      line-height: 58px;
      background: #25AAA3;
      border-radius: 17px;
      font-weight: 400;
      font-size: 18px;
      color: #FFFFFF;
      text-align: center;
      margin: 20px auto 0;
      cursor: pointer;
    }
  }
}
</style>