import axios from 'axios'
import jsonp from 'jsonp'
import { getEnc } from '@/utils/getUtils'
import router from '@/router'

const instance = axios.create({
	timeout: 10000,
	headers: {
		'Content-Type': 'application/json;charset=UTF-8',
	},
	withCredentials: true,
})

const { VUE_APP_BASE_URL: BASE_URL, NODE_ENV } = process.env

if (NODE_ENV !== 'production') {
  instance.defaults.baseURL = '/dev'
} else {
  instance.defaults.baseURL = BASE_URL + '/api'
}

// 请求拦截
instance.interceptors.request.use(
	(request) => {
		const userInfo = JSON.parse(sessionStorage.getItem('userInfo') || '{}')
    if (userInfo.token) {
			const token = {
				userId: userInfo.userId,
				source: userInfo.userType == 8 ? 7 : 5,
				routeId: -1,
				token: userInfo.token
			}
			request.headers.Authenticate = getEnc(JSON.stringify(token))	
    }
		return request
	},
	(error) => { }
)

// 响应拦截
instance.interceptors.response.use(
	(response) => {
		if (response.data.code == 401) {
			router.replace({
				path: '/login'
			})
		}
		return response.data
	},
	(error) => {
		
	}
)

function fetchJsonp(url, params) {
	let requestUrl = url
	if (params) {
		const timeStamp = new Date().toString()
		params._ = Date.parse(timeStamp)
		const dataString = url.indexOf('?') === -1 ? '?' : '&'
		requestUrl = `${requestUrl}${dataString}`
		for (const k in params) {
			requestUrl += `&${k}=${params[k]}`
		}
	}
	return new Promise((resolve, reject) => {
		jsonp(requestUrl, { param: 'jsoncallback' }, (err, res) => {
			if (err) {
				reject(err)
			} else {
				resolve(res)
			}
		})
	})
}

export { instance, fetchJsonp }
