<template>
  <div class="header-container">
    <div class="header-wrap">
      <div class="header-left">
        <img
          class="header-img"
          :src="pageInfo.centerLogoUrl"
          alt=""
          v-if="pageInfo.centerLogoUrlState == 1"
        />
        <span v-if="pageInfo.centerWebsiteNameState == 1">{{
          pageInfo.centerWebsiteName
        }}</span>
      </div>
      <div class="header-menu">
        <el-menu
          v-if="!isLoginPage"
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          background-color="#1bb2ad"
          text-color="#fff"
          active-text-color="#1bb2ad"
          @select="handleSelect"
        >
          <template v-if="userInfo.userType != 8">
            <el-menu-item index="1">首页</el-menu-item>
            <el-menu-item index="2">中医临床</el-menu-item>
            <el-menu-item index="3">六经辨证</el-menu-item>
            <el-menu-item index="4">体质辨识</el-menu-item>
            <!-- <el-menu-item index="5">新冠专题</el-menu-item> -->
            <!-- <el-menu-item index="6">操作演示</el-menu-item> -->
            <el-menu-item
              index="7"
              v-if="userInfo.userId == 1679091837127757832"
              >视频咨询</el-menu-item
            >
          </template>
          <template v-else>
            <el-menu-item index="8">专家咨询</el-menu-item>
          </template>
        </el-menu>
      </div>
      <div class="header-right">
        <div
          v-if="!isLoginPage"
          class="header-user"
          @click="toggleUserMenu"
          v-click-outside="outsideClick"
        >
          <img
            class="avatar-img"
            src="@/assets/images/user_nav_header_icon.png"
            alt=""
          />
          <div v-if="userInfo.userName" class="name">
            {{ userInfo.userName }}
          </div>
          <div v-else @click="goLogin" class="name">登录</div>
          <span class="arrow_down"></span>
          <div
            v-if="showUserMenu"
            :class="['user-menu', userInfo.userType != 8 && 'posi']"
          >
            <div
              v-if="userInfo.userType != 8"
              class="user-menu-item"
              @click="handleCommand('fixpassword')"
            >
              <img class="icon" src="@/assets/images/lock_icon.png" alt="" />
              修改密码
            </div>
            <div class="user-menu-item" @click="handleCommand('logout')">
              <img class="icon" src="@/assets/images/lagout_icon.png" alt="" />
              退出登录
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { logoutPost, logoutPost2 } from "@/service/user";

export default {
  name: "Header",
  data() {
    return {
      hidePathNames: ["login", "forgetPassword", "clinic", "demonstrate"],
      isShow: false,
      isLoginPage: false,
      activeIndex: "1",
      showUserMenu: false,
      curPageName: "",
    };
  },
  watch: {
    $route(to, from) {
      const name = to.name;
      this.filterCurIndex(name);
    },
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
      isLogin: (state) => state.user.isLogin,
    }),
    ...mapGetters(["pageInfo"]),
  },
  created() {
    const { name } = this.$route;
    this.isLoginPage = this.hidePathNames.indexOf(name) > -1;
    this.filterCurIndex(name);
  },
  methods: {
    handleSelect(tab) {
      if (tab == 1) this.$router.push({ path: "/home" });
      if (tab == 2) this.$router.push({ path: "/clinical" });
      if (tab == 3) this.$router.push({ path: "/channels" });
      if (tab == 4) this.$router.push({ path: "/physique" });
      if (tab == 5) this.$router.push({ path: "/covid19" });
      if (tab == 6) this.$router.push({ path: "/demonstrate" });
      if (tab == 7) this.$router.push({ path: "/videoConsultation" });
      if (tab == 8) this.$router.push({ path: "/doctorVideo" });
    },
    async handleCommand(command) {
      if (command == "logout") {
        try {
          const request =
            this.userInfo.userType == 8 ? logoutPost2 : logoutPost;
          const { code, msg } = await request();
          if (code != 200) return this.$message.error(msg);
          this.$store.commit("user/setUserInfo", {});
          this.$store.commit("user/setIsLogin", false);
          sessionStorage.removeItem("userInfo");
          let url = localStorage.getItem('url');
          window.location.href = url;
        } catch (error) {
          console.log(error);
        }
      } else {
        this.$router.push({
          name: "fixPassword",
        });
      }
    },
    toggleUserMenu() {
      this.showUserMenu = !this.showUserMenu;
    },
    goLogin() {
      this.$router.push({
        name: "login",
      });
    },
    filterCurIndex(name) {
      if (name == "home") this.activeIndex = "1";
      if (name == "clinical") this.activeIndex = "2";
      if (name == "channels") this.activeIndex = "3";
      if (name == "physique") this.activeIndex = "4";
      if (name == "covid19") this.activeIndex = "5";
      if (name == "videoConsultation") this.activeIndex = "7";
      if (name == "doctorVideo" || name == "agora") this.activeIndex = "8";
    },
    outsideClick() {
      this.showUserMenu = false;
    },
  },
};
</script>

<style lang="less" scoped>
.header-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  min-width: 1200px;
  height: 62px;
  background-color: #19b2ad;

  .header-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1200px;
    height: 62px;
    margin: 0 auto;
  }

  .header-left {
    display: flex;
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    align-items: center;

    .header-img {
      flex-shrink: 0;
      width: 99px;
      height: 40px;
      margin-right: 16px;
    }
  }

  .header-right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
  }

  .header-menu {
    min-width: 528px;

    /deep/.el-menu {
      height: 62px;

      .el-menu-item {
        border-bottom: none;
      }
    }

    .el-menu.el-menu--horizontal {
      border-bottom: none;
    }

    /deep/.el-menu-item.is-active {
      font-weight: 600;
      color: #fff !important;
      background-color: #13bfba !important;
    }

    /deep/.el-menu--horizontal > .el-menu-item {
      height: 62px;
      line-height: 62px;
      font-size: 16px;
      font-weight: 400;
    }
  }

  .header-user {
    position: relative;
    display: flex;
    align-items: center;
    color: #fff;
    .user-menu {
      position: absolute;
      left: 0;
      bottom: -54px;
      width: 130px;
      padding: 4px 0;
      border-radius: 2px;
      box-shadow: 0px 0px 6px 0px rgba(38, 43, 48, 0.15);
      background-color: #fff;
      overflow: hidden;
      &.posi {
        bottom: -89px;
      }
      .user-menu-item {
        display: flex;
        align-items: center;
        // justify-content: center;
        width: 100%;
        height: 36px;
        line-height: 36px;
        font-size: 14px;
        color: #383f47;
        padding-left: 12px;
        box-sizing: border-box;
        cursor: pointer;
        &:hover {
          background-color: #f2f5f7;
        }
        .icon {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }
      }
    }
    .avatar-img {
      width: 32px;
      height: 32px;
    }

    .name {
      max-width: 60px;
      font-size: 14px;
      color: #fff;
      margin: 0 4px 0 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
    }
    .arrow_down {
      width: 16px;
      height: 16px;
      background: url("@/assets/images/arrow_down.png") no-repeat center;
      background-size: 16px;
    }
  }
}
</style>
