export default  [
    {
      "id": 1675686944622907400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/6fda2222562e49c4bd56918b73b0c059.png",
      "organ": "肾、膀胱",
      "effect": "利水消肿，渗湿",
      "property": "甘、淡、平",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.水肿；\n2.小便不利；\n3.泄泻。",
      "medicine_name": "猪苓",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944618713000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/1482618a6a4742f9bc7991c3615643c4.png",
      "organ": "肝、胆、心",
      "effect": "活血止痛，行气解郁，清心凉血，利胆退黄",
      "property": "辛、苦、寒",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.气滞血瘀痛证；\n2.热病神昏，癫痫痰闭；\n3.吐血，旭血，倒经，尿血，血淋；\n4.肝胆湿热黄疸，胆石证。",
      "medicine_name": "郁金",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944572575700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/ce5260f805b84790ae776c1bd85ecbfe.png",
      "organ": "肝",
      "effect": "破血通经，解毒消肿，杀虫",
      "property": "苦、寒、有毒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.跌打损伤；\n2.月经不调，产后腹痛；\n3.心腹疼痛，癥瘕结块；\n4.风湿痹痛。",
      "medicine_name": "鬼箭羽",
      "suggest_dosage": "4.5～9g"
    },
    {
      "id": 1675686944572575700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/9e1ec6eb602841949fe297e0f89fabd0.png",
      "organ": "心、脾、肾",
      "effect": "利水消肿，渗湿，健脾，宁心",
      "property": "甘、淡、平",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.水肿；\n2.痰饮；\n3.脾虚泄泻；\n4.心悸，失眠。",
      "medicine_name": "茯苓",
      "suggest_dosage": "10～15g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/e45943be8816420fb6729de7434a05cd.png",
      "organ": "肝、胃",
      "effect": "活血通经，下乳消痈，利尿通淋",
      "property": "苦、平",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.血瘀经闭，痛经，难产；\n2.产后乳汁不下，乳痈肿痛；\n3.热淋，血淋，石淋。",
      "medicine_name": "炒王不留行",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944622907400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/9cfe802145fe440980bcf292551ae3d4.png",
      "organ": "心、肾、肺",
      "effect": "安神益智，祛痰开窍，消散痈肿",
      "property": "苦、辛、温",
      "dict_name": "国标",
      "consumption": 2.4,
      "indications": "1.失眠多梦，心悸怔忡，健忘；\n2.癫痫惊狂；\n3.咳嗽痰多；\n4.痈疽疮毒，乳房肿痛，喉痹。",
      "medicine_name": "远志",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944618713000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/f4b799aaa12e4381810640a35b484315.png",
      "organ": "肾、肝",
      "effect": "补肾壮阳，祛风除湿",
      "property": "辛、甘、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.肾阳虚衰，阳痿尿频，腰膝无力；\n2.风寒湿痹，肢体麻木。",
      "medicine_name": "淫羊藿",
      "suggest_dosage": "6～10g"
    },
    {
      "id": 1675686944585158700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/97f351ed89024add9a344f88c283c3d0.png",
      "organ": "辛、肺、三焦",
      "effect": "泻火除烦，清热利尿，凉血解毒焦梔子：凉血止血",
      "property": "苦、寒",
      "dict_name": "企标",
      "consumption": 6,
      "indications": "1.热病心烦；\n2.湿热黄疸；\n3.血淋涩痛；\n4.血热吐旭；\n5.目赤肿痛；\n6.火毒疮疡。",
      "medicine_name": "焦栀子",
      "suggest_dosage": "6～10g"
    },
    {
      "id": 1675686944585158700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/f746690c987b4fd3a338f954c4a1b50d.png",
      "organ": "肾、大肠",
      "effect": "补肾阳，益精血，润肠通便",
      "property": "甘、咸、温",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.肾阳不足，精血亏虚，阳痿不孕；\n2.腰膝酸软，筋骨无力；\n3.肠燥便秘。",
      "medicine_name": "酒苁蓉",
      "suggest_dosage": "6～10g"
    },
    {
      "id": 1675686944614518800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/908b1380c74d422abbd4f145c5669b64.png",
      "organ": "肝、脾、肺、大肠",
      "effect": "敛肺止咳，涩肠止泻，安蛔止痛，生津止渴",
      "property": "酸、涩、平",
      "dict_name": "企标",
      "consumption": 15,
      "indications": "1.肺虚久咳；\n2.久泻，久痢；\n3.蛔厥腹痛，呕吐；\n4.虚热消渴。",
      "medicine_name": "乌梅炭",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944610324500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/d0aae8f1378c42dc9eb5d5f44c95b609.png",
      "organ": "肝",
      "effect": "清热利湿，祛风除痹，解毒消肿，止血",
      "property": "淡、微涩、平",
      "dict_name": "企标",
      "consumption": 15,
      "indications": "1.黄疸，消化不良，呕吐；\n2.风湿痹痛；\n3.消化道癌肿，痈疡疮疖；\n4.跌打损伤，外伤出血，乳汁不下。",
      "medicine_name": "藤梨根",
      "suggest_dosage": "20～40g"
    },
    {
      "id": 1675686944610324500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/fd4f9baa34034cb391e362e3b7bdfc47.png",
      "organ": "肝",
      "effect": "舒肝解郁；行气止痛",
      "property": "苦、平、无毒",
      "dict_name": "企标",
      "consumption": 3,
      "indications": "1.气郁胃痛；\n2.下痢腹痛；\n3.肝区痛，胸脯不适。",
      "medicine_name": "素馨花",
      "suggest_dosage": "15～30g"
    },
    {
      "id": 1675686944610324500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/1b9ee6a437f84a1fadf53c139b00e5d5.png",
      "organ": "肝、脾",
      "effect": "祛风湿，舒筋络，强腰膝，除痹痛",
      "property": "辛、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.风寒湿痹，筋骨疼痛腰膝无力；\n2.咳嗽气喘，肾虚咳嗽，阳痿。",
      "medicine_name": "石楠藤",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944610324500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/5e58de6961a34cdca792dc3d522a3011.png",
      "organ": "脾、胃",
      "effect": "杀虫消积",
      "property": "甘、温、无毒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.蛔虫病，蛻虫病；\n2.小儿疳积。",
      "medicine_name": "使君子",
      "suggest_dosage": "9～12g"
    },
    {
      "id": 1675686944610324500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/4eb9e1e532044f6fa7ec530317c6f809.png",
      "organ": "肺、胃、肝",
      "effect": "通络，活血，祛风",
      "property": "甘、平",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.痹痛拘挛；\n2.胸胁胀痛；\n3.乳汁不通。",
      "medicine_name": "丝瓜络炭",
      "suggest_dosage": "5～12g"
    },
    {
      "id": 1675686944606130200,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/7a3ff3dd3b6b4546bcbd9d2ab482b508.png",
      "organ": "心、肝、肾",
      "effect": "清热凉血，养阴生津",
      "property": "甘、苦、寒",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.热入营血，舌绛烦渴，斑疹吐；\n2.阴虚内热，骨蒸劳热；\n3.津伤口渴，内热消渴，肠燥便秘。",
      "medicine_name": "生地黄炭",
      "suggest_dosage": "10～15g"
    },
    {
      "id": 1675686944601936000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/163e2d73ccfb416eb1eaf5befb09dec7.png",
      "organ": "肺、胃",
      "effect": "补气，益肺，祛暑，生津",
      "property": "苦、甘、寒",
      "dict_name": "企标",
      "consumption": 6,
      "indications": "1.气虚咳嗽，暑热烦躁，津伤口渴，头目不清，四肢倦乏。",
      "medicine_name": "人参叶",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944597741600,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/9a5621355a1745e2ada8bea242e77867.png",
      "organ": "肺",
      "effect": "清肝明目，去湿热，疮毒",
      "property": "苦、辛、凉、有小毒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.痈肿疮毒；\n2.感冒发热；\n3.目赤肿痛；\n4.泄泻痢疾；\n5.皮肤湿疹。",
      "medicine_name": "千里光",
      "suggest_dosage": "15～30g"
    },
    {
      "id": 1675686944597741600,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/f6de02c5ffd2498c9ce8d5193779a7eb.png",
      "organ": "脾、胃",
      "effect": "杀虫消积",
      "property": "苦、辛、平",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.蛔虫病，蛲虫病，绦虫病；\n2.虫积腹痛，小儿疳积。",
      "medicine_name": "南鹤虱",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944597741600,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/fd11821daa2144109e71af37bf3fde78.png",
      "organ": "肺、",
      "effect": "补虚润肺，强筋活络",
      "property": "甘、平",
      "dict_name": "企标",
      "consumption": 8,
      "indications": "1.腰肌劳损，风湿性关节炎；\n2.肺结核，慢性支气管炎；3.慢性肝炎；\n4.遗精，白带。",
      "medicine_name": "牛大力",
      "suggest_dosage": "15～30g"
    },
    {
      "id": 1675686944593547300,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/ed9cb4c28aab4a35bd77e1c0cac606a0.png",
      "organ": "大肠",
      "effect": "清热利湿，解毒",
      "property": "甘、淡、凉",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.泄泻，痢疾；\n2.痔疮出血。",
      "medicine_name": "木棉花",
      "suggest_dosage": "6～9g"
    },
    {
      "id": 1675686944593547300,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/bfa8b589ffe647b486a97eca02ebadc8.png",
      "organ": "肝",
      "effect": "活血通脉，消肿止痛，清热解毒",
      "property": "苦、平",
      "dict_name": "企标",
      "consumption": 25,
      "indications": "1.风热感冒，肺热喘咳，喉头水肿，扁桃体炎；\n2.痢疾；\n3.冠心病，脑血管意外所致的偏瘫，血栓闭塞性脉管炎；\n4.丹毒，烫伤；\n5.中心性视网膜炎，葡萄膜炎，以及皮肤急性化脓性炎症。",
      "medicine_name": "毛冬青",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944589353000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/e162b996c9aa446baf9467931ebe60d3.png",
      "organ": "肝",
      "effect": "活血定痛，化瘀止血，生肌敛疮",
      "property": "甘、咸、平",
      "dict_name": "企标",
      "consumption": 1,
      "indications": "1.跌打损伤；\n2.心腹瘀痛；\n3.外伤出血；\n4.疮疡不敛。",
      "medicine_name": "龙血竭",
      "suggest_dosage": "3～6g"
    },
    {
      "id": 1675686944589353000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/97892eb5bbd14586b3b6160ed46b9759.png",
      "organ": "心、肾",
      "effect": "固肾涩精",
      "property": "甘、涩、平",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.遗精滑精，带下；\n2.尿频。",
      "medicine_name": "莲须",
      "suggest_dosage": "3～5g"
    },
    {
      "id": 1675686944589353000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/718a5ba0162f48d885b3e6bcb2b68cac.png",
      "organ": "胃、肝",
      "effect": "活血化瘀，行气止痛，祛风通络，解毒消肿",
      "property": "辛、苦、微寒、有小毒",
      "dict_name": "企标",
      "consumption": 25,
      "indications": "1.跌扑损伤；\n2.胃痛，牙痛；\n3.风湿痹痛；\n4.毒蛇咬伤；\n5.烧烫伤。",
      "medicine_name": "两面针",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944589353000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/df26443f179e41d78073737b985451ec.png",
      "organ": "肺、大肠",
      "effect": "清热润肺，利咽开音，滑肠通便",
      "property": "甘、凉",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.肺热燥咳；2.咽痛失音；3.肠燥便秘。",
      "medicine_name": "罗汉果",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944593547300,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/e425e3f8b25c44f3bd418dbf19e90ae6.png",
      "organ": "心、胃",
      "effect": "清热解毒，消暑，利水",
      "property": "甘、寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.痈肿疮毒；\n2.暑热烦渴；\n3.药食中毒；\n4.水肿，小便不利。",
      "medicine_name": "绿豆",
      "suggest_dosage": "15～30g"
    },
    {
      "id": 1675686944580964400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/5cf104925ac946e888d2cc8d0b184244.png",
      "organ": "肺、胃、心、胆",
      "effect": "清热化痰，除烦，止呕",
      "property": "甘、微寒",
      "dict_name": "企标",
      "consumption": 15,
      "indications": "1.痰热咳嗽；\n2.胆火挟痰，惊悸不宁，心烦失眠；\n3.中风痰迷，舌强不语；\n4.胃热呕吐；\n5.妊娠恶阻，胎动不安。",
      "medicine_name": "姜竹茹",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944585158700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/6906c0c8af42484090b6bc04126e6ae2.png",
      "organ": "肺、胃、肝",
      "effect": "清热解毒；活血消痈；祛风除湿",
      "property": "酸、苦、寒",
      "dict_name": "企标",
      "consumption": 9,
      "indications": "1.肺痈，肺热咳喘，咽喉肿痛；2.痢疾；\n3.风湿痹证，跌打损伤，痈肿疮毒，蛇虫咬伤。",
      "medicine_name": "金荞麦",
      "suggest_dosage": "15～45g"
    },
    {
      "id": 1675686944580964400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/a11c885d2b3a4a3790af4d0df94fe076.png",
      "organ": "肝、胆、肺",
      "effect": "清热利湿，止血",
      "property": "甘、辛、平、无毒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.肺热咯血，衄血，吐血，便血，痔疮出血；\n2.发热，湿热黄疸；\n3.小儿惊风；\n4.淋病；\n5.水肿，水火烫伤。",
      "medicine_name": "江南卷柏",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944585158700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/efe3a429246349498341ebd09ea6f8f4.png",
      "organ": "肺、肝",
      "effect": "祛风解表，透疹消疮，止血",
      "property": "辛、微温",
      "dict_name": "企标",
      "consumption": 15,
      "indications": "1.外感表证；\n2.麻疹不透，风疹瘙痒；\n3.疮疡初起兼有表证；\n4.吐下血。",
      "medicine_name": "荆芥炭",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944585158700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/70329517e6bc4862a058603d2b919be7.png",
      "organ": "辛、脾、胃、胆、大肠",
      "effect": "清热燥湿，泻火解毒",
      "property": "苦、寒",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.湿热痞满，呕吐吞酸；\n2.湿热泻痢；\n3.高热神昏，心烦不寐，血热吐；\n4.痈肿疳疮，目赤牙痛；5.消渴；\n6.外湿疹，湿疮，耳道流脓。",
      "medicine_name": "酒黄连",
      "suggest_dosage": "2～5g"
    },
    {
      "id": 1675686944580964400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/13e04521a6fe4abe83366cd27b66f64d.png",
      "organ": "脾、胃",
      "effect": "消食和中，健脾开胃",
      "property": "甘、微温",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.食积不消，腹胀口臭；\n2.脾胃虚弱，不饥食少。",
      "medicine_name": "焦谷芽",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944580964400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/7d9e318043a64ea7b2961771ec6f23ef.png",
      "organ": "脾、胃",
      "effect": "消食和中，健脾开胃",
      "property": "甘、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.食积不消，腹胀口臭；\n2.脾胃虚弱，不饥食少。",
      "medicine_name": "焦稻芽",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944580964400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/5a703de5d522498fb33041428e092919.png",
      "organ": "肺、大肠",
      "effect": "清热解暑，利湿，止咳，预防中暑",
      "property": "甘、微苦、凉",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.腹泻，消化不良，小儿疳积；\n2.传染性肝炎；\n3.支气管炎。",
      "medicine_name": "鸡蛋花",
      "suggest_dosage": "5～15g"
    },
    {
      "id": 1675686944580964400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/5262fe29c22e44d49dced83bcb09ee26.png",
      "organ": "脾、胃",
      "effect": "燥湿温中，除痰截疟",
      "property": "辛、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.寒湿中阻证；\n2.疟疾。",
      "medicine_name": "姜草果仁",
      "suggest_dosage": "3～6g"
    },
    {
      "id": 1675686944580964400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/f837f1e47da44220ad174d0bcee68dbb.png",
      "organ": "大肠、肺",
      "effect": "清热解毒，利湿消滞，凉血止痒，明止退翳",
      "property": "微酸、微涩、凉",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.痢疾，消化不良，肝炎，感冒，扁桃体炎，咽喉炎，白喉，百日咳；\n2.角膜云翳，乳腺炎，霉菌性阴道炎，白带，疖肿，小儿脓疱，湿疹，毒蛇咬伤。",
      "medicine_name": "火炭母",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944576770000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/2ba83ffcc92149438e099f3d54a0700a.png",
      "organ": "脾、胃、肺、大肠",
      "effect": "燥湿消痰，下气除满",
      "property": "苦、辛、温",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.湿阻中焦，脘腹胀满；\n2.食积气滞，腹胀便秘，痰饮喘咳。",
      "medicine_name": "厚朴花",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944576770000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/88f70fb5657847978bdfb2e8ae6b042f.png",
      "organ": "肝、肾",
      "effect": "祛风湿，补肝肾，强筋骨，安胎元",
      "property": "甘、苦、平",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.风湿痹痛，腰膝酸软，筋骨无力；\n2.崩漏经多，妊娠漏血；3.胎动不安；\n4.头晕目眩。",
      "medicine_name": "槲寄生",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944576770000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/06d4a3bc4ba4491bb79b2c171e1c15f0.png",
      "organ": "胃、脾、肾",
      "effect": "温中止痛，杀虫止痒",
      "property": "辛、热",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.脘腹冷痛，呕吐泄泻，虫积腹痛；\n2.湿疹，阴痒。",
      "medicine_name": "花椒目",
      "suggest_dosage": "3～6g"
    },
    {
      "id": 1675686944576770000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/86e365e382dc46a3aa01eeb99768705e.png",
      "organ": "肝、大肠",
      "effect": "凉血止血，清肝泻火",
      "property": "苦、微寒",
      "dict_name": "企标",
      "consumption": 7,
      "indications": "1.便血，痔血，血痢，崩漏，吐血，衄血；\n2.肝热目赤，头痛眩晕。",
      "medicine_name": "槐花炭",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944576770000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/c0f63e1c0f684a429791ae0fb65d5cf3.png",
      "organ": "脾、肾",
      "effect": "活血，利水，祛风，解毒",
      "property": "甘、平",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.水肿胀满，风毒脚气，黄疸浮肿；\n2.风痹筋挛；\n3.产后风痉，口噤；\n4.痈肿疮毒，解药毒。",
      "medicine_name": "黑豆",
      "suggest_dosage": "9～30g"
    },
    {
      "id": 1675686944572575700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/7b7e83e68c3647c1835516525e9d8c4d.png",
      "organ": "肝、肾",
      "effect": "活血续伤，补肾强骨",
      "property": "苦、温",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.跌打损伤或创伤，筋骨损伤，瘀滞肿痛；\n2.肾虚腰痛脚弱，耳鸣耳聋，牙痛，久泻。",
      "medicine_name": "骨碎补",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944572575700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/a2d9fcc40a764ec1920d9a77008f9ee1.png",
      "organ": "脾、胃",
      "effect": "理气止痛，开郁醒脾，外用祛湿消肿",
      "property": "辛、甘、温",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.脘腹胀满，食欲不振；\n2.呕吐；\n3.外牙痛；\n4.脚气肿毒。",
      "medicine_name": "甘松",
      "suggest_dosage": "3～6g"
    },
    {
      "id": 1675686944572575700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/0cfa07957dc94326ba06dbd242b81236.png",
      "organ": "肺、胃",
      "effect": "清热解毒，生津，利咽，散瘀止痛",
      "property": "苦、微甘、凉",
      "dict_name": "企标",
      "consumption": 17,
      "indications": "1.流感高热，急性扁桃体炎，咽喉炎，肺脓肿，跌打损伤；\n2.疥疮，颈淋巴结核。",
      "medicine_name": "岗梅",
      "suggest_dosage": "15～30g"
    },
    {
      "id": 1675686944589353000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/8e626c63fd664430b2f4bcc4e34d4b27.png",
      "organ": "肝、脾、胃",
      "effect": "杀虫，疗廨",
      "property": "苦、寒、有毒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.蛔虫病，蛻虫病，钩虫病；\n2.疥廨，湿疮。",
      "medicine_name": "苦楝皮",
      "suggest_dosage": "3～6g"
    },
    {
      "id": 1675686944572575700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/5df3ba1daf2b4e4a8b118085ec87923e.png",
      "organ": "肝、肾",
      "effect": "利水除湿，祛瘀止痛",
      "property": "甘、温",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.黄疸，水肿；2.跌打损伤，肿痛。",
      "medicine_name": "赶黄草",
      "suggest_dosage": "15～30g"
    },
    {
      "id": 1675686944572575700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/0bb91a8b61084862843206f127542bf8.png",
      "organ": "脾、胃",
      "effect": "解酒醒脾，止血",
      "property": "甘、凉",
      "dict_name": "企标",
      "consumption": 4,
      "indications": "1.伤酒烦热口渴，头痛头晕，脘腹胀满，呕逆吐酸，不思饮食；2.吐血，肠风下血。",
      "medicine_name": "葛花",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944568381400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/be9cf92a0dac45efbc63aae0a33c0316.png",
      "organ": "脾、肺、肾",
      "effect": "补脾养胃，生津益肺，补肾涩精",
      "property": "甘、平",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.脾虚证；\n2.肺虚证；\n3.肾虚证；\n4.消渴气阴两虚证。",
      "medicine_name": "麸炒山药",
      "suggest_dosage": "15～30g"
    },
    {
      "id": 1675686944568381400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/7873c4c180aa4ca8b1a5b39bb1c50543.png",
      "organ": "肝、脾",
      "effect": "破血行气，消积止痛",
      "property": "辛、苦、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.症痕积聚，经闭，心腹瘀痛；2.食积脘腹胀痛。",
      "medicine_name": "莪术",
      "suggest_dosage": "6～9g"
    },
    {
      "id": 1675686944568381400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/85c75622c72f4f6ba4f38f66070c78de.png",
      "organ": "肺、心、肝",
      "effect": "坠痰下气，平肝镇惊",
      "property": "甘、咸、平",
      "dict_name": "企标",
      "consumption": 60,
      "indications": "1.顽痰胶结；\n2.咳逆喘急；\n3.癫痫发狂；\n4.烦躁胸闷；\n5.惊风抽搐。",
      "medicine_name": "煅金礞石",
      "suggest_dosage": "3～6g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/78ff5dcb0dd74d7798e77be1181271a5.png",
      "organ": "肝、膀胱",
      "effect": "祛风利湿，活血通经",
      "property": "淡、微苦、凉",
      "dict_name": "企标",
      "consumption": 13,
      "indications": "1.风湿筋骨痛，跌打损伤，腰肌劳损，贫血头晕，四肢麻木；\n2.月经不调。",
      "medicine_name": "穿破石",
      "suggest_dosage": "9～30g"
    },
    {
      "id": 1675686944555798500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/750676ba9f684b46bd163a1168c182ff.png",
      "organ": "脾、胃",
      "effect": "消食化滞，清热利湿",
      "property": "微酸、凉",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.饮食积滞；\n2.感冒发热；\n3.湿热黄疸。",
      "medicine_name": "布渣叶",
      "suggest_dosage": "15～30g"
    },
    {
      "id": 1675686944555798500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/757e03cdb08242a3aed2af550e13d786.png",
      "organ": "肺",
      "effect": "敛肺定喘，止带缩尿痰多喘咳，带下白浊，遗尿尿频",
      "property": "甘、苦涩、平、有小毒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.痰多喘咳；\n2.带下白浊；\n3.遗尿尿频。",
      "medicine_name": "白附片",
      "suggest_dosage": "3～6g"
    },
    {
      "id": 1675686944555798500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/656b56e83dba45faabf4a65ab00a9cae.png",
      "organ": "肝、脾、肾",
      "effect": "温经止血，散寒调经，安胎",
      "property": "辛、苦、温、小毒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.出血证；\n2.月经不调，痛经；\n3.胎动不安。",
      "medicine_name": "艾叶炭",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944597741600,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/50d79bc4dffd485089ebb0e7585c4d87.png",
      "organ": "肺、胃",
      "effect": "外用清热解毒，内服清肺化痰",
      "property": "甘、咸、凉",
      "dict_name": "企标",
      "consumption": 1,
      "indications": "1.咽喉肿痛，口舌生疮，目赤翳障；\n2.痰热咳嗽。",
      "medicine_name": "硼砂",
      "suggest_dosage": "1.5～3g"
    },
    {
      "id": 1675686944555798500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/d6cd6556146d47578187bdb1d28f8226.png",
      "organ": "肺、脾、肝、大肠",
      "effect": "外用解毒杀虫，燥湿止痒；内服止血止泻，祛除风",
      "property": "酸、涩、寒",
      "dict_name": "企标",
      "consumption": 1,
      "indications": "1.湿疹，疥癣，脱肛，痔疮；\n2.久泻不止，便血，崩漏，癫痫发狂。",
      "medicine_name": "白矾",
      "suggest_dosage": "0.6～1.5g"
    },
    {
      "id": 1675686944580964400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/267c2f564bf54ddfa9e844e5fb1337b8.png",
      "organ": "肝、肺、胃",
      "effect": "祛风定惊，化痰散结",
      "property": "咸、辛、平",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.惊痫抽搐；\n2.风中经络，口眼咼斜；\n3.风热头痛，目赤，咽痛，风疹瘙痒；\n4.痰核，療痂。",
      "medicine_name": "僵蚕",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944593547300,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/d39c84ec7ea14274b0f26c334f66e963.png",
      "organ": "肺",
      "effect": "清热解毒，利咽，止血",
      "property": "辛、平",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.咽喉肿痛，咳嗽失音；\n2.吐血血，外伤出血。",
      "medicine_name": "马勃",
      "suggest_dosage": "2～6g"
    },
    {
      "id": 1675686944597741600,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/61894465921e433b8fb3f0add1dc94ec.png",
      "organ": "肺、胃",
      "effect": "清肺止咳，降逆止呕",
      "property": "苦、微寒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.肺热咳嗽，气逆喘急；2.胃热呕吐，啰逆。",
      "medicine_name": "枇杷叶",
      "suggest_dosage": "6～10g"
    },
    {
      "id": 1675686944589353000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/04b8a4a94b7347aabce86345e009be42.png",
      "organ": "肺",
      "effect": "润肺下气，止咳化痰",
      "property": "辛、微苦、温",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.咳嗽气喘。",
      "medicine_name": "款冬花",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944614518800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/22069f0eac564af9adde92594e12fee2.png",
      "organ": "肝、肾、脾、胃",
      "effect": "散寒止痛，理气和胃",
      "property": "辛、温",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.寒疝腹痛，睾丸偏坠胀痛，少腹冷痛，痛经；\n2.中焦虚寒气滞证。",
      "medicine_name": "小茴香",
      "suggest_dosage": "3～6g"
    },
    {
      "id": 1675686944614518800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/72293f7417df4bb984c2ff40e9b42439.png",
      "organ": "肝、脾、胃、肾",
      "effect": "散寒止痛，降逆止呕，助阳止泻",
      "property": "辛、苦、热小毒",
      "dict_name": "企标",
      "consumption": 7,
      "indications": "1.寒凝疼痛；\n2.胃寒呕吐；\n3.虚寒泄泻。",
      "medicine_name": "吴茱萸",
      "suggest_dosage": "2～5g"
    },
    {
      "id": 1675686944597741600,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/ab397c4691b34ac5b926bbc55cd81946.png",
      "organ": "脾、肾",
      "effect": "益肾固精，补脾止泻，祛湿止带",
      "property": "甘、涩、平",
      "dict_name": "企标",
      "consumption": 14,
      "indications": "1.梦遗滑精，\n遗尿尿频；\n2.脾虚久泻；\n3.白浊，带下。",
      "medicine_name": "芡实",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944593547300,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/3747f13407f44df7af209537b2d6cbb1.png",
      "organ": "膀胱、肝、胃",
      "effect": "疏散风热，清利头目",
      "property": "辛、苦",
      "dict_name": "企标",
      "consumption": 13,
      "indications": "1.风热感冒，头昏头痛；\n2.目赤肿痛，耳鸣耳聋。",
      "medicine_name": "蔓荆子",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944589353000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/92e9a0bed3164f809240de33087bca74.png",
      "organ": "肺、脾、胃",
      "effect": "消食除胀，降气化痰",
      "property": "辛、甘、平",
      "dict_name": "企标",
      "consumption": 11,
      "indications": "1.食积气滞；\n2.咳喘痰多，胸闷食少。",
      "medicine_name": "莱菔子",
      "suggest_dosage": "5～12g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/3ed357f828be4ce48bac9fd2525596e9.png",
      "organ": "肺、大肠",
      "effect": "止咳平喘，润肠通便",
      "property": "苦、微温、小毒",
      "dict_name": "企标",
      "consumption": 14,
      "indications": "1.咳嗽气喘；\n2.肠燥便秘。",
      "medicine_name": "燀苦杏仁",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944585158700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/e85b677a2e9a4ba0a396f9e37515e7ee.png",
      "organ": "肝、大肠",
      "effect": "清热明目，润肠通便",
      "property": "甘、苦、咸、微寒",
      "dict_name": "企标",
      "consumption": 17,
      "indications": "1.目赤肿痛，羞明多泪，目暗不明；\n2.头痛，眩晕；\n3.肠燥便秘。",
      "medicine_name": "决明子",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944585158700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/c118075a5bfd42efb65ce571e3ab1e4b.png",
      "organ": "肺",
      "effect": "温肺豁痰利气，散结通络止痛",
      "property": "辛、温、有毒",
      "dict_name": "企标",
      "consumption": 13,
      "indications": "1.寒痰咳嗽；\n2.胸胁胀痛；\n3.痰滞经络；\n4.关节麻木，疼痛；\n5.痰湿流注，阴疽肿毒。",
      "medicine_name": "芥子",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944564187100,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/8572746097c14f359730cdc2e76657d4.png",
      "organ": "肺、大肠",
      "effect": "清热利尿，消肿排脓，消痰止咳",
      "property": "甘、寒、无毒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.水肿胀满，小便不利；\n2.肺痈，肠痈；\n3.痰热咳嗽；\n4.白油，白带。",
      "medicine_name": "冬瓜子",
      "suggest_dosage": "10～15g"
    },
    {
      "id": 1675686944627101700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/ffb9d190790741fc8041fb3366dbf4a3.png",
      "organ": "肺",
      "effect": "润肺化痰止咳",
      "property": "苦、辛、甘、微温",
      "dict_name": "企标",
      "consumption": 3,
      "indications": "1.痰多喘咳；\n2.新久咳嗽，劳嗽咳血。",
      "medicine_name": "紫菀",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944601936000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/d1a7da4698dd4acdbbbfd3e901afe9eb.png",
      "organ": "肝、脾",
      "effect": "破血行气，消积止痛",
      "property": "辛、苦、平",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.症瘕痞块；\n2痛经，瘀血经闭；\n3.胸痹心痛；4食积胀痛。",
      "medicine_name": "三棱",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944576770000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/2a82f57dafdf49558f5985fac688bc5e.png",
      "organ": "脾、肺、肾",
      "effect": "补气养阴，健脾，润肺，益肾",
      "property": "甘、平",
      "dict_name": "企标",
      "consumption": 3,
      "indications": "1.阴虚肺燥，干嗽少痰，肺肾阴虚，劳嗽久咳；\n2.脾胃虚弱；\n3.肾精亏虚，内热消渴。",
      "medicine_name": "黄精",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944564187100,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/b503c4480c60473ea57fc0e5a47ee318.png",
      "organ": "脾、胃、大肠、肝、心包",
      "effect": "泻下攻积，清热泻火，凉血解毒，逐瘀通经",
      "property": "苦、寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.积滞便秘；\n2.血热吐,目赤咽肿；\n3.热毒疮疡，烧烫伤；\n4.瘀血诸证；\n5.湿热痢疾，黄疸，淋证。",
      "medicine_name": "大黄炭",
      "suggest_dosage": "3～15g"
    },
    {
      "id": 1675686944576770000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/282013376097404aa43082363b379aad.png",
      "organ": "脾、胃、肺、大肠",
      "effect": "燥湿消痰，下气除满",
      "property": "苦、辛、温",
      "dict_name": "企标",
      "consumption": 15,
      "indications": "1.湿阻中焦，脘腹胀满；\n2.食积气滞，腹胀便秘，痰饮喘咳。",
      "medicine_name": "厚朴",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944614518800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/c6f4eeb78cea4e17b5179214658b77b2.png",
      "organ": "肺、心、肾",
      "effect": "收敛固涩，益气生津，补肾宁心",
      "property": "酸、甘、温",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.久咳虚喘；\n2.自汗，盗汗；\n3.遗精，滑精；\n4.久泻不止；\n5.津伤口渴，消渴；\n6.心悸，失眠，多梦。",
      "medicine_name": "五味子",
      "suggest_dosage": "2～6g"
    },
    {
      "id": 1675686944555798500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/9e951bd6386c430b813bf984ecebf8ab.png",
      "organ": "肝、胆",
      "effect": "清热解毒，祛风利湿，抗癌",
      "property": "苦、微寒、有小毒",
      "dict_name": "企标",
      "consumption": 15,
      "indications": "1.肿疮毒；\n2.风火赤眼；\n3.湿热黄疸；\n4.带下；\n5.风湿搏痛。",
      "medicine_name": "白英",
      "suggest_dosage": "25～50g"
    },
    {
      "id": 1675686944610324500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/dea0f45fb84749cabe1610e785e9e20e.png",
      "organ": "心、肝、胆",
      "effect": "养心益肝，安神，敛汗",
      "property": "甘、酸、平",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.心悸失眠；\n2.自汗，盗汗。",
      "medicine_name": "酸枣仁",
      "suggest_dosage": "10～15g"
    },
    {
      "id": 1675686944601936000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/93d3b582a6214e0e8d06fe10229187a6.png",
      "organ": "肝、肾",
      "effect": "补益肝肾，收涩固脱",
      "property": "酸、涩、微温",
      "dict_name": "企标",
      "consumption": 3,
      "indications": "1.眩晕耳鸣，腰膝酸痛，阳痿遗精，遗尿尿频；\n2.崩漏带下；\n3.大汗虚脱，内热消渴。",
      "medicine_name": "山萸肉",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944555798500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/6e784f59491c47dab30996dcb4286354.png",
      "organ": "脾、胃、肝",
      "effect": "燥湿健脾，祛风散寒",
      "property": "辛、苦、温",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.湿阻中焦；\n2.风湿痹证；\n3.风寒挟湿表证。",
      "medicine_name": "苍术",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944593547300,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/ed6c782eab8d48b0bc6bc73ac9fd12b0.png",
      "organ": "肺、大肠",
      "effect": "清肺化痰，止咳平喘，清肠消痔",
      "property": "苦、微辛、寒",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.肺热咳嗽；\n2.痔疮肿痛或出血。",
      "medicine_name": "蜜马兜铃",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944564187100,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/d397d2d825074919ab1de39db5cc8d0b.png",
      "organ": "肝、大肠",
      "effect": "清热解毒，凉血止血，利湿退黄",
      "property": "辛、平",
      "dict_name": "企标",
      "consumption": 6,
      "indications": "1.痢疾，泄泻；\n2.咯血，尿血，便血，崩漏；\n3.疮疖痈肿，湿热黄疸。",
      "medicine_name": "地锦草",
      "suggest_dosage": "9～20g"
    },
    {
      "id": 1675686944601936000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/bb76ad84dba84f68894c804309c30080.png",
      "organ": "肝、胆、胃",
      "effect": "疏肝破气，消积化滞",
      "property": "苦、辛、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.肝郁气滞证；\n2.气滞脘腹疼痛；\n3.虫积腹痛；\n4.症痕积聚，久疟痞块。",
      "medicine_name": "青皮",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944618713000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/c6ddc9e21246406788709ad075dfb66d.png",
      "organ": "肾、脾",
      "effect": "暖肾固精缩尿，温脾开胃摄唾",
      "property": "辛、温",
      "dict_name": "企标",
      "consumption": 14,
      "indications": "1.下兀虚寒，遗精，遗尿，小便频数；\n2.脾胃虚寒，腹痛吐泻，口涎自流。",
      "medicine_name": "益智仁",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944572575700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/5005f75c1ce449a7b423ba689fbcf2c5.png",
      "organ": "肺、肝",
      "effect": "疏散风热，明目退翳",
      "property": "辛、甘、平",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.风热目赤，肿痛羞明；\n2.眼生翳膜；\n3.风热头痛。",
      "medicine_name": "谷精草",
      "suggest_dosage": "4.5～9g"
    },
    {
      "id": 1675686944597741600,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/2cc8cea70b0d42699338550158076cf6.png",
      "organ": "肺、肾、大肠",
      "effect": "泻下逐水，去积杀虫",
      "property": "苦、寒、有毒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.水肿，鼓胀；\n2.痰饮喘咳；\n3.虫积腹痛。",
      "medicine_name": "牵牛子",
      "suggest_dosage": "3～6g"
    },
    {
      "id": 1675686944585158700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/d4cd8d12d8924f969c2d08bbbb17572b.png",
      "organ": "肺、脾、胃、大肠",
      "effect": "消痰，燥湿，止泻，止血，解毒，杀虫",
      "property": "酸、涩、寒、有毒",
      "dict_name": "企标",
      "consumption": 1,
      "indications": "1.癫痫，喉痹；\n2.黄疸，水肿，泻痢；\n3.衄血，口舌生疮；\n4.疮痔疥癣，水，火，虫伤。",
      "medicine_name": "枯矾",
      "suggest_dosage": "0.6～1.5g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/ad50174abebf46ed9ec3f231f9c4701d.png",
      "organ": "肝、胆",
      "effect": "解表退热，疏肝解郁，升举阳气",
      "property": "苦、辛、微寒",
      "dict_name": "企标",
      "consumption": 8,
      "indications": "1.表证发热，少阳证；\n2.肝郁气滞；\n3.气虚下陷，脏器脱垂。",
      "medicine_name": "醋北柴胡",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944585158700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/6d51dd048d6841d6ab1f088ec4e9a863.png",
      "organ": "辛、脾",
      "effect": "清热解毒，散结消肿",
      "property": "辛、苦、寒、有小毒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.时疫感冒，咽喉肿痛；\n2.疔疮肿痛，痈疽发背，痄腮丹毒。",
      "medicine_name": "苦地丁",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944580964400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/1fbf54f3ba374c40af2b845e7bbf8c04.png",
      "organ": "肝、大肠",
      "effect": "收敛止带，止血，止痢",
      "property": "甘、涩、凉",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.带下；\n2.崩漏，便血痔血；\n3.赤白下痢，久痢不止。",
      "medicine_name": "鸡冠花",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944572575700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/6118fdd2d8f3498e911e4ce407930334.png",
      "organ": "肺、胃、大肠",
      "effect": "清热化痰，利气宽胸",
      "property": "甘、微苦、寒",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.痰热咳嗽；\n2.胸闷胁痛。",
      "medicine_name": "瓜蒌皮",
      "suggest_dosage": "6～10g"
    },
    {
      "id": 1675686944610324500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/cf2322dc894144a5be3f99063068037f.png",
      "organ": "肝、胃",
      "effect": "理气宽中，和胃止痛",
      "property": "甘、温",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.胸腹胀闷；\n2.胃脘疼痛。",
      "medicine_name": "娑罗子",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944580964400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/8781e4645a114297a132832f48511da0.png",
      "organ": "脾、肝",
      "effect": "破血，软坚，消积",
      "property": "微苦、温、有小毒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.癥瘕痞块；\n2.经闭；\n3.噎膈。",
      "medicine_name": "急性子",
      "suggest_dosage": "3～5g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/2986cf8eb250409b90e46ea6b6ff71e0.png",
      "organ": "脾、胃",
      "effect": "消食和胃",
      "property": "肝、辛、温",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "饮食积滞",
      "medicine_name": "炒建曲",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/31f1bb19265c416482b98049fcf1e0b3.png",
      "organ": "肝、脾、肾",
      "effect": "补肾清肝，明目，利尿",
      "property": "甘、寒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.肝肾不足，腰膝酸软，虚劳骨蒸，头晕目昏；\n2.目生翳膜；\n3.水肿胀满。",
      "medicine_name": "楮实子",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944555798500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/52cceb0064f94ac1afa75f6fd101aa35.png",
      "organ": "肾、肝",
      "effect": "补肾助阳，祛风除湿",
      "property": "辛、甘、微温",
      "dict_name": "企标",
      "consumption": 3,
      "indications": "1.阳痿不举，宫冷不孕，小便频数；\n2.风湿腰膝疼痛，肾虚腰膝酸软。",
      "medicine_name": "巴戟天",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/526f277884c54669a07be1a6e76b5b12.png",
      "organ": "肝、胃、小肠、膀胱",
      "effect": "行气止痛，杀虫",
      "property": "苦、寒、小毒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.肝郁化火诸痛证；\n2.虫积腹痛。",
      "medicine_name": "川楝子",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944555798500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/c47f3c7aabe3486db39b11a12ff6005b.png",
      "organ": "肺、肝",
      "effect": "止咳化痰，清热利湿，活血化瘀止血",
      "property": "平、苦、辛",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.肺热咳嗽，寒痰咳喘，肺痈咳吐浓痰，肺痨咳喘；\n2.湿热黄疸，水肿；\n3.跌打损伤，风湿痹痛，经闭腹痛；\n4.睾丸肿痛；\n5.劳伤吐血。",
      "medicine_name": "矮地茶",
      "suggest_dosage": "15～30g"
    },
    {
      "id": 1675686944627101700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/b5efadc05f74478dbf0ee0a3642b28f0.png",
      "organ": "肺、大肠",
      "effect": "降气化痰，止咳平喘，润肠通便",
      "property": "辛、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.咳嗽痰多；\n2.肠燥便秘。",
      "medicine_name": "紫苏子",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944597741600,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/463a636f880341939fcf3b451b771458.png",
      "organ": "肺、胃",
      "effect": "疏散风热，宣肺祛痰，利咽透疹，解毒消肿",
      "property": "辛、苦、寒",
      "dict_name": "企标",
      "consumption": 13,
      "indications": "1.风热感冒，温病初起；\n2.麻疹不透，风疹瘙痒；\n3.痈肿疮毒，丹毒，定腮，喉痹。",
      "medicine_name": "牛蒡子",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944568381400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/ea6e15e143f840e38914dd7a74c24070.png",
      "organ": "心、肺、肝",
      "effect": "坠痰下气，平肝镇惊",
      "property": "甘、咸、平",
      "dict_name": "企标",
      "consumption": 60,
      "indications": "1.顽痰胶结；\n2.咳逆喘急；\n3.癫痫发狂；\n4.烦躁胸闷；\n5.惊风抽搐。",
      "medicine_name": "煅青礞石",
      "suggest_dosage": "10～15g"
    },
    {
      "id": 1675686944610324500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/f56e793de763469ea3b65f48350cc9d8.png",
      "organ": "大肠",
      "effect": "涩肠止泻，杀虫，收敛止血",
      "property": "酸、涩、温",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.久泻，久而；\n2.虫积腹痛；\n3.崩蕭，便血。",
      "medicine_name": "石榴皮",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944601936000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/4afd6a812d6e4945a05e570f21af4e21.png",
      "organ": "肝",
      "effect": "清肝，明目，退翳",
      "property": "苦、微寒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.肝热目赤；\n2.眼生翳膜；\n3.视物昏花；\n4.肝火眩晕。",
      "medicine_name": "青葙子",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944606130200,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/b52d372fc4d5438e92fb015d6c64e858.png",
      "organ": "肝、大肠",
      "effect": "凉血止血，清肝泻火",
      "property": "苦、微寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.血热出血证；\n2.目赤，头痛。",
      "medicine_name": "生槐花",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944597741600,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/e84db85adf054c9594761a6b3c35a596.png",
      "organ": "肝、肾",
      "effect": "祛风湿，强筋骨",
      "property": "苦、辛、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.风寒湿痹。",
      "medicine_name": "千年健",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944627101700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/5dccb82abebc4c0fa1bf3f34281c68ca.png",
      "organ": "肺、脾胃",
      "effect": "宽胸利膈，顺气安胎",
      "property": "辛、甘、微温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.胸腑气滞，痞闷做胀及胎动不安；\n2.胸胁胀痛。",
      "medicine_name": "紫苏梗",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944627101700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/cda0793074b6423fab5424fb60f4a6de.png",
      "organ": "肝、心、膀胱",
      "effect": "清热解毒，安胎，止血",
      "property": "寒、甘",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.感冒发热，麻疹高烧，尿路感染，肾炎水肿；\n2.孕妇腹痛，胎动不安，先兆流产；\n3.跌打损伤，骨折，疮疡肿痛；\n4.出血性。",
      "medicine_name": "苎麻根",
      "suggest_dosage": "10～30g"
    },
    {
      "id": 1675686944622907400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/5283122b4b314d738d6dd9d101c97bba.png",
      "organ": "肝",
      "effect": "清热解毒，消肿止痛，凉肝定惊",
      "property": "苦、微寒、有小毒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.疔疮痈肿，咽喉肿痛，蛇虫咬伤；\n2.跌扑伤痛；\n3.惊风抽搐。",
      "medicine_name": "重楼",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944622907400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/16cd4b0c75354bfb892f2a5422d68e0a.png",
      "organ": "肝、脾、胃、肾",
      "effect": "散寒止痛，降逆止呕，助阳止泻",
      "property": "辛、苦、热小毒",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.寒凝疼痛；\n2.胃寒呕吐；\n3.虚寒泄泻。",
      "medicine_name": "制吴茱萸",
      "suggest_dosage": "2～5g"
    },
    {
      "id": 1675686944622907400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/ab50138a95454fbf8fa1b6927bcca849.png",
      "organ": "胃、肝",
      "effect": "祛风痰，定惊搐，解毒散结，止痛",
      "property": "辛、温",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.中风痰壅，口眼唱斜，语言謇涩；\n2.惊风癫痫，破伤风；\n3.痰厥头痛，偏正头痛；\n4.瘰疬痰核，毒蛇咬伤。",
      "medicine_name": "制白附子",
      "suggest_dosage": "3～6g"
    },
    {
      "id": 1675686944622907400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/ca21602de49f4c6a956536b499aa3130.png",
      "organ": "肝、心",
      "effect": "平肝潜阳，安神，定惊明目",
      "property": "咸、寒",
      "dict_name": "企标",
      "consumption": 30,
      "indications": "1.肝阳上亢，头晕目眩；\n2.惊悸失眠，心神不宁；\n3.目赤翳障，视物昏花。",
      "medicine_name": "珍珠母",
      "suggest_dosage": "10～25g"
    },
    {
      "id": 1675686944622907400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/10d8ed3817654a2590d24b0fbf03a7b4.png",
      "organ": "肝、脾",
      "effect": "活血调经，祛瘀消痈，利水消肿",
      "property": "苦、辛、微温",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.血瘀调经闭，痛经，产后瘀滞腹痛；\n2.跌打损伤，瘀肿疼痛，疮痈肿毒；\n3.水肿，腹水。",
      "medicine_name": "泽兰",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944622907400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/db94e4f12c9948588eef4955b0201dde.png",
      "organ": "脾、大肠、小肠",
      "effect": "润肠通便",
      "property": "辛、苦、甘、平",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.肠燥便秘；\n2.水肿胀满，利水消肿。",
      "medicine_name": "郁李仁",
      "suggest_dosage": "6～10g"
    },
    {
      "id": 1675686944618713000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/d50de69c474d474d8cbbaa9bbaf2140e.png",
      "organ": "肺",
      "effect": "清热解毒，消痈排脓，利尿通淋",
      "property": "辛、微寒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.肺痈吐痰，肺热咳嗽；\n2.热毒疮痈；\n3.湿热淋证。",
      "medicine_name": "鱼腥草",
      "suggest_dosage": "15～25g"
    },
    {
      "id": 1675686944618713000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/6f535b1f8fcd4b4db7e99d97b9e4cd9a.png",
      "organ": "肾、脾",
      "effect": "暖肾固精缩尿，温脾开胃摄唾",
      "property": "辛、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.下兀虚寒，遗精，遗尿，小便频数；\n2.脾胃虚寒，腹痛吐泻，口涎自流。",
      "medicine_name": "盐益智仁",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944610324500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/0521783e886c4bfaabdc06e0918ca902.png",
      "organ": "脾、胃、心、肺",
      "effect": "行气止痛，散寒调中",
      "property": "辛、温",
      "dict_name": "企标",
      "consumption": 1,
      "indications": "胸腹寒凝气滞",
      "medicine_name": "檀香",
      "suggest_dosage": "2～5g"
    },
    {
      "id": 1675686944610324500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/30db77fd7d21422fb9e72bb51d91f3d8.png",
      "organ": "心、肝",
      "effect": "活血疗伤，祛瘀通经",
      "property": "甘、咸、辛、平",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.跌打损伤，骨折筋伤，瘀滞月巾痛；\n2.血滞经闭，产后瘀阻腹痛，通经，心腹疼痛，痈肿疮毒。",
      "medicine_name": "苏木",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944610324500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/125b42097fd94882ae0ef390c5f2e476.png",
      "organ": "肝",
      "effect": "破血",
      "property": "苦、平、寒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.小儿痞块积聚；\n2.消一切年深日久坚积，妇人石瘕症。",
      "medicine_name": "水红花子",
      "suggest_dosage": "15～30g"
    },
    {
      "id": 1675686944610324500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/fbd5bf20d1a448a3b35be1938cb6cb0f.png",
      "organ": "胃",
      "effect": "降气止呃",
      "property": "苦、涩、平",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "呃逆",
      "medicine_name": "柿蒂",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944606130200,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/62785cded0b64699abfeba7fed3d6cac.png",
      "organ": "心、胃",
      "effect": "开窍醒神，化湿和胃，宁神益志",
      "property": "辛、苦、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.痰蒙清窍，神志昏迷；\n2.湿阻中焦，脘腹痞满，胀闷疼痛；\n3.噤口痢；\n4.健忘，失眠，耳鸣，耳聋。",
      "medicine_name": "石菖蒲",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944606130200,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/81442d8fed744ce4a4e2eff1bde5a92f.png",
      "organ": "肺、脾、胃",
      "effect": "解表散寒，温中止呕，温肺止咳",
      "property": "辛、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.风寒感冒；\n2.脾胃寒证；\n3.胃寒呕吐；\n4.肺寒咳嗽。",
      "medicine_name": "生姜",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944606130200,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/5e10b002d6c3421e91cc489b7f529a80.png",
      "organ": "心、肝、肾经",
      "effect": "清热凉血，养阴，生津",
      "property": "甘、苦、寒",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.热病舌绛烦渴；\n2.阴虚内热，骨蒸劳热；\n3.内热消渴；\n4.吐血，衄血，发斑发疹。",
      "medicine_name": "生地黄",
      "suggest_dosage": "10～15g"
    },
    {
      "id": 1675686944606130200,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/29b84914e79d48648247ef6416189a41.png",
      "organ": "肺、肝、脾",
      "effect": "凉血止血，化痰止咳，生发乌发",
      "property": "苦、涩、寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.血热出血证；\n2.肺热咳嗽；\n3.脱发，须发早白。",
      "medicine_name": "生侧柏叶",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944597741600,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/53daae89a86b44828d8a00fcded50dc9.png",
      "organ": "肺、肝",
      "effect": "疏散风热，明目退翳",
      "property": "甘、苦、平",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.风热目赤，迎风流泪，目生翳障；\n2.出血证。",
      "medicine_name": "木贼",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944593547300,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/c1e7de694ea446f1b91fe82d2f40bff3.png",
      "organ": "肾、胃",
      "effect": "利湿去浊，祛风除痹",
      "property": "苦、平",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.膏淋，白浊，白带过多；2.风湿痹痛，关节不利，腰膝疼痛。",
      "medicine_name": "绵萆薢",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944593547300,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/bb6d217e1bee422e99994d7af2a795e9.png",
      "organ": "肺",
      "effect": "润肺止咳，杀虫灭虱",
      "property": "甘、苦、微温",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.新久咳嗽，百日咳，肺捞咳嗽；\n2.蛻虫，阴道滴虫，头虱及疥廨。",
      "medicine_name": "蜜百部",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944593547300,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/4540b1be0534488f927852543424674b.png",
      "organ": "肝、脾",
      "effect": "活血散瘀，解毒，利水，退黄，截疟",
      "property": "苦、微寒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.癥瘕积聚，痛经经闭；\n2.喉痹，痈肿；\n3.水肿；\n4.黄疸，疟疾。",
      "medicine_name": "马鞭草",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944589353000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/d59175b977f5436fa0fbb5443e192dac.png",
      "organ": "肝",
      "effect": "平肝安神，清热利水",
      "property": "甘、苦、凉",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.肝阳眩晕；2.心悸失眠；3.浮肿尿少。",
      "medicine_name": "罗布麻叶",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944589353000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/e92feed3c3b24aa3be85e912b2bd85c9.png",
      "organ": "胃",
      "effect": "清热解毒，消痈散结，通经下乳，舒筋通脉",
      "property": "苦、寒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.乳痈肿痛，療痂疮毒；\n2.乳汁不下；\n3.湿痹拘挛。",
      "medicine_name": "漏芦",
      "suggest_dosage": "5～9g"
    },
    {
      "id": 1675686944589353000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/b4bf303ffcb8497e93c8508c8efd1f3e.png",
      "organ": "心、肺、肝、肾",
      "effect": "补气安神，止咳平喘",
      "property": "甘、平",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.心神不宁，失眠，惊悸；2.咳嗽痰多；\n3.虚劳证。",
      "medicine_name": "灵芝",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944589353000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/02a0313890bd400ea505b212a119fc06.png",
      "organ": "脾、肾、心",
      "effect": "固精止带，补脾止泻，益肾养心",
      "property": "甘、涩、平",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.遗精滑精；\n2.带下；\n3.脾虚泄泻；\n4.心悸，失眠。",
      "medicine_name": "莲子",
      "suggest_dosage": "6～15g"
    },
    {
      "id": 1675686944580964400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/880ffda53d3a41678bd0d12544ea9181.png",
      "organ": "肺、胆、脾、胃、大肠、小肠",
      "effect": "清热燥湿，泻火解毒，止血，安胎",
      "property": "苦、寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.湿温，暑湿，胸闷呕恶，湿热痞满，黄疸泻痢；\n2.肺热咳嗽，高热烦渴；\n3.血热吐；\n4.痈肿疮毒；\n5.胎动不安。",
      "medicine_name": "黄芩",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944576770000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/42b1f021e1dc49799b2cddf53bee67a1.png",
      "organ": "肾、膀胱、大肠",
      "effect": "清热燥湿，泻火除蒸，解毒疗疮",
      "property": "苦、寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.湿热带下，热淋涩痛；\n2.湿热泻痢，黄疸；\n3.湿热脚气，痿证；\n4.骨蒸劳热，盗汗，遗精；\n5.疮疡肿毒，湿疹瘙痒。",
      "medicine_name": "黄柏",
      "suggest_dosage": "3～12g"
    },
    {
      "id": 1675686944576770000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/6e4ff8a868cd4a23977a6d3fce3d4579.png",
      "organ": "脾、胃、肾",
      "effect": "温中止呕，杀虫止痒",
      "property": "辛、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.中寒腹痛，寒湿吐泻；\n2.虫积腹痛，湿疹，阴痒。",
      "medicine_name": "花椒",
      "suggest_dosage": "3～6g"
    },
    {
      "id": 1675686944576770000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/73f09ad9dccf4f8591bebcd2735bbe5e.png",
      "organ": "胃、大肠",
      "effect": "退虚热，除疳热，清湿热",
      "property": "苦、寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.骨蒸潮热；\n2.小儿疳热；\n3.湿热泻痢。",
      "medicine_name": "胡黄连",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944576770000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/d0984e319b044b57bd7a9b9e3801fde2.png",
      "organ": "心、肝、肺",
      "effect": "解郁安神，活血消肿",
      "property": "甘、平",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.心神不宁，愤怒忧郁，烦躁失眠；\n2.跌打骨折，血瘀肿痛；\n3.肺痈，疮痈肿毒。",
      "medicine_name": "合欢皮",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944576770000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/e5171437e2c5488d8c164e1d097d4909.png",
      "organ": "肝、肾",
      "effect": "杀虫，止痛，止痒，祛风湿，通经络",
      "property": "苦、平、无毒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.风湿痹痛，痢疾；2.牙痛；3.疥癣；4.腹泻痢疾；5.跌打骨折。",
      "medicine_name": "海桐皮",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944572575700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/2386658cc7884b9b9ce931cbd7ed8419.png",
      "organ": "心、肺、膀胱",
      "effect": "发汗解肌，温通经脉，助阳化气",
      "property": "辛、甘、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.风寒感冒；\n2.寒凝血滞诸痛证；\n3.痰饮，蓄水证；\n4.心悸。",
      "medicine_name": "桂枝",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944572575700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/198720a53e874f019d20e1e47ff628fc.png",
      "organ": "脾、胃、肺",
      "effect": "化湿，止呕，解暑",
      "property": "辛、微温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.湿阻中焦；\n2.呕吐；\n3.暑湿或湿温初起。",
      "medicine_name": "广藿香",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944572575700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/0ef5dfbe713745348fa761dbe3354abd.png",
      "organ": "肝、肾",
      "effect": "滋补肝肾，益精明目",
      "property": "甘、平",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.肝肾阴虚及早衰诸证。",
      "medicine_name": "枸杞子",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944572575700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/0feb9ebbcaae4c889d415c87dfe12af9.png",
      "organ": "脾、胃",
      "effect": "解肌退热，透疹，生津止渴，升阳止泻",
      "property": "甘、辛、凉",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.表证发热，项背强痛；\n2.麻疹不透；\n3.热病口渴，阴虚消渴；\n4.热泄热痢，脾虚泄泻。",
      "medicine_name": "葛根",
      "suggest_dosage": "10～15g"
    },
    {
      "id": 1675686944606130200,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/eb7d1298ef794b318c8c34baf065ed66.png",
      "organ": "肝、脾",
      "effect": "活血化瘀，清热利湿，散结消肿",
      "property": "辛、苦、微寒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.月经不调，痛经，经闭，崩漏，便血；\n2.湿热黄疸，热毒血痢，淋痛，带下；\n3.风湿骨痛，瘰疬，疮肿，乳痈，带状疱疹，麻风，跌打伤肿。",
      "medicine_name": "石见穿",
      "suggest_dosage": "6～15g"
    },
    {
      "id": 1675686944568381400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/e1fce696d16544e1960101a910eb1cde.png",
      "organ": "脾、胃、肝",
      "effect": "燥湿健脾，祛风散寒",
      "property": "辛、苦、温",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.湿阻中焦；\n2.风湿痹证；\n3.风寒挟湿表证。",
      "medicine_name": "麸炒苍术",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944568381400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/b6fb6a6e0ec64ba496e4525d345dac3a.png",
      "organ": "膀胱、肺",
      "effect": "祛风湿，止痛，利水消肿",
      "property": "苦、辛、寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.风湿痹症；\n2.水肿，小便不利，脚气；\n3.湿疹疮毒。",
      "medicine_name": "防己",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944568381400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/c0f5c7f5fc2847f29ca929cf37a677b3.png",
      "organ": "肺",
      "effect": "祛瘀止痛止血理气",
      "property": "辛、温",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.跌打损伤，目赤肿痛。",
      "medicine_name": "鹅不食草",
      "suggest_dosage": "6～9g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/305d3fd95cfc4185842654bf7aea088e.png",
      "organ": "心包、肝",
      "effect": "活血调经，清肝明目",
      "property": "辛、苦、微寒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.月经不调，经闭痛经；\n2.目赤翳障；\n3.头晕胀痛。",
      "medicine_name": "茺蔚子",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/6df915e1ddb140ff808fecfbbdc25159.png",
      "organ": "脾、肺",
      "effect": "理气健脾，燥湿化痰",
      "property": "辛、苦、温",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.脾胃气滞证；\n2.呕吐，呃逆；\n3.湿痰，寒痰咳嗽；\n4.胸痹。",
      "medicine_name": "陈皮",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/df959e9aded246c89aebd5f8ad116a55.png",
      "organ": "辛、肺、三焦",
      "effect": "泻火除烦，清热利尿，凉血解毒焦梔子：凉血止血",
      "property": "苦、寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.热病心烦；\n2.湿热黄疸；\n3.血淋涩痛；\n4.血热吐旭；\n5.目赤肿痛；\n6.火毒疮疡。",
      "medicine_name": "炒栀子",
      "suggest_dosage": "6～10g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/c150293f0d87497fae247e447c617219.png",
      "organ": "脾、胃、肝",
      "effect": "消食化积，行气散瘀",
      "property": "酸、甘、微温",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.饮食积滞；\n2.泻痢腹痛，疝气痛；\n3.瘀阻胸腹痛，痛经。",
      "medicine_name": "炒山楂",
      "suggest_dosage": "9～12g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/e90c38c7480f47cea571abaf355aa5fb.png",
      "organ": "肺、大肠",
      "effect": "止咳平喘，润肠通便",
      "property": "苦、微温、小毒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.咳嗽气喘；\n2.肠燥便秘。",
      "medicine_name": "炒苦杏仁",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944618713000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/e6ebe9ab3dea47a7ac2242eb1aa97065.png",
      "organ": "肝、肾、脾、胃",
      "effect": "散寒止痛，理气和胃",
      "property": "辛、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.寒疝腹痛，睾丸偏坠胀痛，少腹冷痛，痛经；\n2.中焦虚寒气滞证。",
      "medicine_name": "盐小茴香",
      "suggest_dosage": "3～6g"
    },
    {
      "id": 1675686944618713000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/2f063b1775c84d16870823acc6d571ab.png",
      "organ": "肝、肾、肺、小肠",
      "effect": "利尿通淋，渗湿止泻，明目，祛痰",
      "property": "甘、微寒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.淋证，水肿；\n2.泄泻；\n3.目赤肿痛，目暗昏花，翳障；\n4.痰热咳嗽。",
      "medicine_name": "盐车前子",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944564187100,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/c7714f0a5df24c9f91eb8c812d3b9371.png",
      "organ": "肝、大肠",
      "effect": "凉血止血，解毒敛疮",
      "property": "苦、酸、涩、微寒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.便血，痔血，血痢，崩漏；\n2.水火烫伤，痈肿疮毒。",
      "medicine_name": "地榆炭",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/67520e69e4e64a3ba7e4d60a3acd40f5.png",
      "organ": "肝、肺、胃",
      "effect": "祛风定惊，化痰散结",
      "property": "咸、辛、平",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.惊痫抽搐；\n2.风中经络，口眼咼斜；\n3.风热头痛，目赤，咽痛，风疹瘙痒；\n4.痰核，療痂。",
      "medicine_name": "炒僵蚕",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/8410505a33c74683b2a4900fc89e8861.png",
      "organ": "脾、胃",
      "effect": "消食和中，健脾开胃",
      "property": "甘、微温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.食积不消，腹胀口臭；\n2.脾胃虚弱，不饥食少。",
      "medicine_name": "炒谷芽",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/3fbd4d3c76344780a21d4e1d02c8d32c.png",
      "organ": "肝、胃、小肠、膀胱",
      "effect": "行气止痛，杀虫",
      "property": "苦、寒、小毒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.肝郁化火诸痛证；\n2.虫积腹痛。",
      "medicine_name": "炒川楝子",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944555798500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/5560fddf759e4ee8956294329455eba6.png",
      "organ": "肺、肝、脾",
      "effect": "凉血止血，化痰止咳，生发乌发",
      "property": "苦、涩、寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.血热出血证；\n2.肺热咳嗽；\n3.脱发，须发早白。",
      "medicine_name": "侧柏炭",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944555798500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/93200c6763ad4284a317d4c7a4a179d3.png",
      "organ": "膀胱",
      "effect": "利尿，止痒，清热，杀虫",
      "property": "苦、寒、无毒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.热淋，癃闭，黄疸，阴蚀，白带；\n2.蛔虫；\n3.疳积；\n4.痔肿；\n5.湿疮。",
      "medicine_name": "萹蓄",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944555798500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/de6713271ee34572b8c80d1b41ec635c.png",
      "organ": "肝、胆",
      "effect": "解表退热，疏肝解郁，升举阳气",
      "property": "苦、辛、微寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.表证发热，少阳证；\n2.肝郁气滞；\n3.气虚下陷，脏器脱垂。",
      "medicine_name": "北柴胡",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944555798500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/af2d66148252460dbe39df3ec58a3b6e.png",
      "organ": "胃、大肠、肝",
      "effect": "清热解毒，消痈排脓，祛瘀止痛",
      "property": "辛、苦、微寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.肠痈肺痈，痈肿疮毒；\n2.产后瘀阻腹痛。",
      "medicine_name": "败酱草",
      "suggest_dosage": "15～30g"
    },
    {
      "id": 1675686944555798500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/0b853ac5e28246cba0165aeac02206e0.png",
      "organ": "肺、胃、大肠",
      "effect": "解表散寒，祛风止痛，通鼻窍，燥湿止带，消肿排脓",
      "property": "辛、温",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.风寒感冒；\n2.头痛，牙痛，风湿痹痛；\n3.鼻渊；\n4.带下证；\n5.疮疡肿毒。",
      "medicine_name": "白芷",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944555798500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/f0a4c2db47b0406581296207978e5f47.png",
      "organ": "胃、大肠",
      "effect": "清热解毒，凉血止痢",
      "property": "苦、寒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.热毒血痢；\n2.疮痈肿毒。",
      "medicine_name": "白头翁",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944555798500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/218a535c1aa54ec79439627a36c62dec.png",
      "organ": "心、胃",
      "effect": "疮痈肿毒，消痈散结，敛疮生肌",
      "property": "苦、辛、微寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.疮痈肿毒，療痂痰核；2.水火烫伤，手足皺裂。",
      "medicine_name": "白蔹",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944564187100,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/387dc89b741b441aacd4234a1029c397.png",
      "organ": "肝、脾、三焦",
      "effect": "疏肝解郁，调经止痛，理气调中",
      "property": "辛、微苦、微甘、平",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.肝郁气滞胁痛，腹痛；\n2.月经不调，痛经，乳房胀痛；3.气滞腹痛。",
      "medicine_name": "醋香附",
      "suggest_dosage": "6～10g"
    },
    {
      "id": 1675686944564187100,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/f5c38d2524a54b268d2e805395d4ad3f.png",
      "organ": "肝、脾",
      "effect": "破血行气，消积止痛",
      "property": "辛、苦、平",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.症瘕痞块；\n2.痛经，瘀血经闭；\n3胸痹心痛；\n4.食积胀痛。",
      "medicine_name": "醋三棱",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/64a4bf8535294675a4caa513acb17e6c.png",
      "organ": "心、肺、肾",
      "effect": "温肾暖宫，镇心安神，温肺平喘",
      "property": "甘、温、无毒",
      "dict_name": "企标",
      "consumption": 60,
      "indications": "1.肾阳亏虚，宫冷不孕；\n2.惊悸不安，失眠多梦；\n3.虚寒咳喘。",
      "medicine_name": "醋煅紫石英",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/7cde732653fe4056b0224372cf54de2a.png",
      "organ": "心、肝、胆",
      "effect": "利湿退黄，清热解毒",
      "property": "甘、淡、微酸、微寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.黄疸；\n2.痈肿疮疡，喉痛，蛇伤，烫伤。",
      "medicine_name": "垂盆草",
      "suggest_dosage": "15～30g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/feaf93ff23f1430d87d3121be801b32c.png",
      "organ": "肝、胆、心包",
      "effect": "活血行气，祛风止痛",
      "property": "辛、温",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.胸痹心痛，胸胁刺痛；\n2.跌扑肿痛；\n3.月经不调，经闭痛经，癥瘕腹痛；\n4.头痛；\n5.风湿痹痛。",
      "medicine_name": "川芎",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944618713000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/2ab9f7e3fbb14a54913b6e11a3a940a8.png",
      "organ": "肾、脾",
      "effect": "补肾壮阳，固精缩尿，温脾止泻，纳气平喘",
      "property": "苦、辛、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.肾虚阳痿，腰膝冷痛；\n2.肾虚遗精，遗尿，尿频；\n3.脾肾阳虚，五更泄泻；\n4.肾不纳气，虚寒喘咳。",
      "medicine_name": "盐补骨脂",
      "suggest_dosage": "6～10g"
    },
    {
      "id": 1675686944618713000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/351538505ea24d49a6a32277ff9fb48f.png",
      "organ": "肺、胃、肾",
      "effect": "清热凉血，泻火解毒，滋阴",
      "property": "甘、苦、咸、微寒",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.温邪入营，内陷心包，温毒发斑；\n2.热病伤阴，津伤便秘，骨蒸劳嗽；\n3.目赤咽痛，療痂，白喉，痈肿疮毒。",
      "medicine_name": "玄参",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944614518800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/5c1f12e855204405867eee2d91e78257.png",
      "organ": "肺、胃",
      "effect": "发散风寒，通鼻窍",
      "property": "辛、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.风寒感冒；\n2.鼻塞，鼻渊。",
      "medicine_name": "辛夷",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944614518800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/f1efaa59719240faa06c186f1bb4306f.png",
      "organ": "肺、脾、胃",
      "effect": "发汗解表，化湿和中，利水消肿",
      "property": "辛、微温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.风寒感冒；\n2.水肿脚气。",
      "medicine_name": "香薷",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944614518800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/99f6027a06c849f988c1e8ec29483232.png",
      "organ": "心、肝",
      "effect": "收敛止血，止痢，截疟，补虚",
      "property": "苦、涩、平",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.出血证；\n2.腹泻，痢疾；\n3.疟疾寒热；\n4.脱力劳伤。",
      "medicine_name": "仙鹤草",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944614518800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/5fb7d7e7f453419d9f0b837e2996f51c.png",
      "organ": "肺、心、肾、脾",
      "effect": "补气养阴，清热生津",
      "property": "甘、微苦、凉",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.气阴两伤证；\n2.肺气虚及肺阴虚证；\n3.热病气虚津伤口渴及消渴。",
      "medicine_name": "西洋参",
      "suggest_dosage": "3～6g"
    },
    {
      "id": 1675686944614518800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/b61ba1cc30bb4c1bac0d47d18b27bfc7.png",
      "organ": "肺、肝二",
      "effect": "祛风除湿，解毒止痛",
      "property": "甘、辛、温",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.风湿关节痛；\n2.外疮疡肿毒。",
      "medicine_name": "透骨草",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944610324500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/905b5fcda75249f0bbf57d4160b3dd49.png",
      "organ": "肝",
      "effect": "息风止痉，平抑肝阳，祛风通络",
      "property": "甘、平",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.肝风内动，惊痫抽搐；\n2.眩晕头痛；\n3.肢体麻木，手足不遂，风湿痹痛。",
      "medicine_name": "天麻",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944610324500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/3d32a0b2987b426ea5f8eb01c182ee45.png",
      "organ": "心、肝、大肠",
      "effect": "活血祛瘀，润肠通便，止咳平喘",
      "property": "苦、甘、平、小毒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.瘀血阻滞诸证；\n2.肺痈，肠痈；\n3.肠燥便秘；\n4.咳嗽气喘。",
      "medicine_name": "桃仁",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944601936000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/16bec2c73f21474a95845b9a782ba759.png",
      "organ": "肺、肝、大肠",
      "effect": "清热，凉血，消肿，解毒",
      "property": "甘、苦、寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.热病；\n2.惊痫；\n3.咳嗽，吐血；\n4.咽喉肿痛，痢疾，痈肿，疔疮，蛇虫咬伤，汤火伤。",
      "medicine_name": "蛇莓",
      "suggest_dosage": "9-15g"
    },
    {
      "id": 1675686944601936000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/0c2f9dfc823f4d20acfb1b6fb09393c8.png",
      "organ": "肝、脾",
      "effect": "清热解毒，化痰散结",
      "property": "甘、微辛、凉",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.痈肿疔毒；\n2.瘰疬痰核；\n3.蛇虫咬伤。",
      "medicine_name": "山慈菇",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944601936000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/9016adefb5be4bfb82828aa1dd4eb984.png",
      "organ": "心、肝、肾",
      "effect": "补血滋阴，生津润燥",
      "property": "甘、酸、寒",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.眩晕耳鸣；\n2.心悸失眠；\n3.须发早白；\n4.津伤口渴，内热消渴，血虚便秘。",
      "medicine_name": "桑椹",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944597741600,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/9827ceb195214bcb9e424fd0f55d4d01.png",
      "organ": "肝、肺",
      "effect": "清热解毒，凉血消斑，清肝泻火，定惊",
      "property": "咸、寒",
      "dict_name": "企标",
      "consumption": 1,
      "indications": "1.温毒发斑，血热吐；\n2.咽痛口疮，火毒疮疡；\n3.咳嗽胸痛，痰中带血；\n4.暑热惊痫，惊风抽搐。",
      "medicine_name": "青黛",
      "suggest_dosage": "1～3g"
    },
    {
      "id": 1675686944597741600,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/890470d0dd754c248ee8d5956c8e4a9f.png",
      "organ": "肝、心、",
      "effect": "凉血止血，活血化瘀",
      "property": "苦、寒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.血热咯血，吐血，衄血，尿血，便血，崩漏，经闭；2.产后瘀阻腹痛，跌打损伤；\n3.风湿痹痛；\n4.黄疸，疮痈，痔肿。",
      "medicine_name": "茜草炭",
      "suggest_dosage": "6～10g"
    },
    {
      "id": 1675686944597741600,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/087d91bd3ce74b34bb98f767f1aed304.png",
      "organ": "脾、肝",
      "effect": "破血，行气，通经，止痛",
      "property": "辛、苦、温",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.心腹痞满胀痛，臂痛，症瘕；2.妇女血瘀经闭，产后瘀停腹痛；3.跌扑损伤，痈肿。",
      "medicine_name": "片姜黄",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944597741600,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/d41effdb11eb4d8ca1d8e92a572e9cfa.png",
      "organ": "肝、肺、胃",
      "effect": "收敛止血，化瘀",
      "property": "甘、涩、平",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.吐血；2.咯血；3.衄血；4.尿血；5.崩漏。",
      "medicine_name": "藕节炭",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944585158700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/63b027c7483f4e3594f5c1ffa437e690.png",
      "organ": "辛、肝、胃、大",
      "effect": "清热燥湿，杀虫，利",
      "property": "苦、寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.湿热泻痢，便血，黄疸；\n2.湿热带下，阴肿。",
      "medicine_name": "苦参",
      "suggest_dosage": "4.5～9g"
    },
    {
      "id": 1675686944585158700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/dd2fa71fe32c4ff09605a4cac7c1d438.png",
      "organ": "脾、肝、胃、肾",
      "effect": "通络，理气，化痰",
      "property": "苦、平、无毒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.经络气滞；\n2.久咳胸痛；\n3.痰中带血；\n4.伤酒口渴；\n5.痰多。",
      "medicine_name": "橘络",
      "suggest_dosage": "2.5～4.5g"
    },
    {
      "id": 1675686944585158700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/0c423d2b0aff42ba8c36620e66a41166.png",
      "organ": "肺",
      "effect": "宣肺，祛痰，利咽，排脓",
      "property": "苦、辛、平",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.咳嗽痰多，胸闷不畅；\n2.咽喉肿痛，失音；\n3.肺痈吐脓。",
      "medicine_name": "桔梗",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944585158700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/f7a6ee2a0588407c9d27ee8e922f78f0.png",
      "organ": "肺、胆、脾、胃、大肠、小肠",
      "effect": "清热燥湿，泻火解毒，止血，安胎",
      "property": "苦、寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.湿温，暑湿，胸闷呕恶，湿热痞满，黄疸泻痢；2.肺热咳嗽，高热烦渴；3.血热吐；4.痈肿疮毒；5.胎动不安。",
      "medicine_name": "酒黄芩",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944585158700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/f5942abcfc8541f8b54c3b61742ae81c.png",
      "organ": "肝、脾",
      "effect": "养血敛阴，柔肝止痛，平抑肝阳",
      "property": "苦、酸、微寒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.肝血亏虚，月经不调；\n2.肝脾不和，胸胁脘腹疼痛，四肢挛急疼痛；\n3.肝阳上亢，头痛眩晕。",
      "medicine_name": "酒白芍",
      "suggest_dosage": "6～15g"
    },
    {
      "id": 1675686944585158700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/779d802195014a5dac35752c71b07ebe.png",
      "organ": "肝、胆、肾、膀胱",
      "effect": "利湿退黄，利尿通淋，解毒消肿",
      "property": "甘、咸、微寒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.湿热黄疸；\n2.石淋，热淋；\n3.痈肿疔疮，毒蛇咬伤。",
      "medicine_name": "金钱草",
      "suggest_dosage": "15～60g"
    },
    {
      "id": 1675686944580964400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/7578b77d3e8e4ff68e659c62eabcefb5.png",
      "organ": "脾、胃、肝",
      "effect": "消食健胃，回乳消胀",
      "property": "甘、平",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.米面薯芋食滞；\n2.断乳，乳房胀痛。",
      "medicine_name": "焦麦芽",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944580964400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/36eb395ebdad4899a15b3a33ba61b514.png",
      "organ": "胃、大肠",
      "effect": "杀虫消积，行气，利水，截疟",
      "property": "苦、辛、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.肠道寄生虫病；\n2.食积气滞，泻痢后重；\n3.水肿，脚气肿痛；\n4.疟疾。",
      "medicine_name": "焦槟榔",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944580964400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/45e18e3bfc754638800101b29a692b7b.png",
      "organ": "脾、胃、肺、大肠",
      "effect": "燥湿消痰，下气除满",
      "property": "苦、辛、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.湿阻中焦，脘腹胀满；\n2.食积气滞，腹胀便秘，痰饮喘咳。",
      "medicine_name": "姜厚朴",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944572575700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/91f069313f9342b091fe629e881720ba.png",
      "organ": "肝、肾",
      "effect": "固精缩尿，益肝肾明目",
      "property": "甘、酸、微温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.遗精滑精，遗尿尿频；\n2.肝肾不足，目暗不明。",
      "medicine_name": "覆盆子",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944568381400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/4f75d3fe477d4a949fd6e39e97821350.png",
      "organ": "胃、脾",
      "effect": "理气宽中，行滞消胀",
      "property": "苦、辛、酸、微寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.胸胁气滞，胀满疼痛；\n2.食积不化；\n3.痰饮内停；\n4.脏器下垂。",
      "medicine_name": "麸炒枳壳",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944627101700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/e04c8e73c0a24e3a839788a2277311d9.png",
      "organ": "肝、脾、大肠",
      "effect": "收敛止血",
      "property": "苦、涩、平",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.吐血，衄血，便血，尿血，血崩；\n2.外伤出血。",
      "medicine_name": "棕榈炭",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944627101700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/c479f4c03c5d4e4d9d0b8f51d149f764.png",
      "organ": "肺、脾",
      "effect": "解表散寒，行气宽中",
      "property": "辛、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.风寒感冒；\n2.脾胃气滞，胸闷呕吐。",
      "medicine_name": "紫苏叶",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944627101700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/d81c5c91cd89461297a6f0120e2a6139.png",
      "organ": "心、肝",
      "effect": "清热解毒，凉血消肿",
      "property": "苦、辛、寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.疔疮肿毒，乳痈肠痈；\n2.毒蛇咬伤。",
      "medicine_name": "紫花地丁",
      "suggest_dosage": "15～30g"
    },
    {
      "id": 1675686944622907400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/683b4fdf841048dd80052c4b579b07a8.png",
      "organ": "肺、胃",
      "effect": "清热化痰，除烦止呕",
      "property": "甘、微寒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.肺热咳嗽，痰热心烦不寐；\n2.胃热呕吐，B娠恶阻。",
      "medicine_name": "竹茹",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944622907400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/559474cb9c6d4ab7adbdc13ed6311d09.png",
      "organ": "肝、大肠",
      "effect": "祛风活血，清热解毒",
      "property": "辛苦、平",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.风湿痹痛，肢体麻木，跌打损损，损伤骨折；\n2.痛经，产后瘀滞，腹痛。",
      "medicine_name": "肿节风",
      "suggest_dosage": "9～30g"
    },
    {
      "id": 1675686944622907400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/28ffda5b3c254d5596bc19eef9f8fa93.png",
      "organ": "肾、肝",
      "effect": "补肾壮阳，祛风除湿",
      "property": "辛、甘、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.肾阳虚衰，阳痿尿频，腰膝无力；\n2.风寒湿痹，肢体麻木。",
      "medicine_name": "炙淫羊藿",
      "suggest_dosage": "6～10g"
    },
    {
      "id": 1675686944622907400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/6a828ebf4b4d4ea6b5fb19e65b7a0e54.png",
      "organ": "脾、肺",
      "effect": "健脾补中，升阳举陷，益卫固表，利尿，托毒生肌",
      "property": "甘、微温",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.脾气虚证；\n2.肺气虚证；\n3.气虚自汗；\n4.气血亏虚，疮疡难溃难腐，或溃久难敛。",
      "medicine_name": "炙黄芪",
      "suggest_dosage": "9～30g"
    },
    {
      "id": 1675686944622907400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/660ebde7d8224354aebf18d7b8c545e4.png",
      "organ": "心、肺、脾、胃",
      "effect": "补脾益气，祛痰止咳，缓急止痛，清热解毒，调和诸药",
      "property": "甘、平",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.心气不足，脉结代，心动悸；\n2.脾气虚；\n3.咳喘；\n4.脘腹，四肢挛急疼痛；\n5.热毒疮疡，咽喉肿痛，药食中毒；\n6.调和药性。",
      "medicine_name": "炙甘草",
      "suggest_dosage": "2～10g"
    },
    {
      "id": 1675686944622907400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/ad0af5144c8b48d790063ab16f2229ff.png",
      "organ": "心、肾、肺",
      "effect": "安神益智，祛痰开窍，消散痈肿",
      "property": "苦、辛、温",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.失眠多梦，心悸怔忡，健忘；\n2.癫痫惊狂；\n3.咳嗽痰多；\n4.痈疽疮毒，乳房肿痛，喉痹。",
      "medicine_name": "制远志",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944622907400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/4d6a1d8efeb245788747c5adf5dfb52b.png",
      "organ": "肝、肾",
      "effect": "制用：补益精血生用：解毒，截疟，润肠通便",
      "property": "苦、甘、涩、微温",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.精血亏虚，头晕眼花，须发早白，腰膝酸软；\n2.久疟，痈疽，療痂，肠燥便秘。",
      "medicine_name": "制何首乌",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944622907400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/81daa89ca23c4fdb8a9cb69a213912a5.png",
      "organ": "心、肝、肾、脾",
      "effect": "祛风湿，温经止痛",
      "property": "辛、苦、热、有大毒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.风寒湿痹；\n2.心腹冷痛，寒疝冷痛；\n3.跌打损伤，麻醉止痛。",
      "medicine_name": "制川乌",
      "suggest_dosage": "1.5～3g"
    },
    {
      "id": 1675686944622907400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/a8a38981c2034fccbb55a09fbefc9876.png",
      "organ": "心、肝、肾、脾",
      "effect": "祛风除湿，温经止痛",
      "property": "辛、苦、热",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.风寒湿痹，关节疼痛；\n2.心腹冷痛；\n3.寒疝作痛及麻醉止痛。",
      "medicine_name": "制草乌",
      "suggest_dosage": "1.5～3g"
    },
    {
      "id": 1675686944622907400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/c5e4e58d4bc84c408fdc3b3c9a6bc7d5.png",
      "organ": "肾、肝",
      "effect": "补肾助阳，祛风除湿",
      "property": "辛、甘、微温",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.阳痿不举，宫冷不孕，小便频数；\n2.风湿腰膝疼痛，肾虚腰膝酸软。",
      "medicine_name": "制巴戟天",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944622907400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/0b2e48886a264a46a5d8552cd51f5dba.png",
      "organ": "胃",
      "effect": "解酒毒，止渴除烦，止呕，利大小便",
      "property": "甘、平",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.醉酒；\n2.烦渴；\n3.呕吐；\n4.二便不利。",
      "medicine_name": "枳椇子",
      "suggest_dosage": "8～12g"
    },
    {
      "id": 1675686944622907400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/10e45fc32f02446792851d251d499df4.png",
      "organ": "肺、胃、肾",
      "effect": "清热泻火，生津润燥",
      "property": "苦、甘、寒",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.热病烦渴；\n2.肺热燥咳；\n3.骨蒸潮热；\n4.内热消渴；\n5.肠燥便秘。",
      "medicine_name": "知母",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944622907400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/8eb53b2a07ad4e59b1bbe9557efddded.png",
      "organ": "肺、心",
      "effect": "清热化痰，散结消痈",
      "property": "苦、寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.风热，痰热咳嗽；\n2.療疡，瘻瘤，乳痈疮毒，肺痈。",
      "medicine_name": "浙贝母",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944622907400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/b2f5a8933ce54d9d8fdc47f6be51053f.png",
      "organ": "肾、膀胱",
      "effect": "利水消肿，渗湿，泄热",
      "property": "甘、寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.水肿，小便不利，泄泻；\n2.淋证。",
      "medicine_name": "泽泻",
      "suggest_dosage": "6～10g"
    },
    {
      "id": 1675686944622907400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/865879a1ae5a4fa3a04c1da7cd8f8bf9.png",
      "organ": "肝、胃",
      "effect": "消肿托毒，排脓，杀虫",
      "property": "辛、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.痈疽初起或脓成不溃；\n2.外疥癣麻风。",
      "medicine_name": "皂角刺",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944622907400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/e92fbdc1ea494b7b8d12570696e19fca.png",
      "organ": "肝",
      "effect": "活血调经，疏肝解郁",
      "property": "温、甘",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.肝气郁结而致的月经不调，痛经，经闭；\n2.胸腹胀痛，跌打损伤，血瘀肿痛，痈疽肿毒及瘰疬。",
      "medicine_name": "月季花",
      "suggest_dosage": "3～6g"
    },
    {
      "id": 1675686944622907400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/66ef201ea2ee431c9a479905f4a5152c.png",
      "organ": "肝、胆、胃、膀胱",
      "effect": "疏肝理气，活血止痛，除烦利尿",
      "property": "寒、苦",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.肝胃气痛，胃热不思食，腰痛，胁痛，疝气；\n2.痛经，子宫下垂；\n3.烦渴，输尿管结石。",
      "medicine_name": "预知子",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944618713000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/88184430e9654f75a13faa8ea42fcd92.png",
      "organ": "肺、胃",
      "effect": "养阴润燥，生津止渴",
      "property": "甘、微寒",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.肺阴虚证；\n2.胃阴虚证。",
      "medicine_name": "玉竹",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944618713000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/fee42e82014d4c12bfdf87242c0dee5d.png",
      "organ": "膀胱、肝、胆",
      "effect": "利水消肿，利湿退黄",
      "property": "甘、平",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.水肿；\n2.黄疸。",
      "medicine_name": "玉米须",
      "suggest_dosage": "30～60g"
    },
    {
      "id": 1675686944618713000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/55977126da2245f680fd8da8b5a165a7.png",
      "organ": "肝、胃",
      "effect": "清虚热，除疳热",
      "property": "甘、微寒",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.阴虚发热；\n2.疳积发热。",
      "medicine_name": "银柴胡",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944618713000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/8e80f7c326064c0ba6b4d169a28a36a0.png",
      "organ": "脾、胃、肝、胆",
      "effect": "利湿退黄，解毒疗疮",
      "property": "苦、辛、微寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.黄疸；\n2.湿疮瘙痒。",
      "medicine_name": "茵陈",
      "suggest_dosage": "6～15g"
    },
    {
      "id": 1675686944618713000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/adeaf18d16f04163bdcbb55bda0e7c84.png",
      "organ": "心、甘、膀胱",
      "effect": "活血调经，利水消肿，清热解毒",
      "property": "辛、苦、微寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.血滞闭经，痛经，经行不畅，产后恶露不尽，瘀滞腹痛；\n2.水肿，小便不利；\n3.跌打损伤，疮痈肿毒，皮肤瘾疹。",
      "medicine_name": "益母草",
      "suggest_dosage": "9～30g"
    },
    {
      "id": 1675686944618713000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/33bc67a7b58d410890d21461b7ef3dca.png",
      "organ": "肝、心",
      "effect": "清热解毒",
      "property": "苦、辛、微寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.痈疽疔疳，咽喉肿痛；\n2.目赤肿痛，头痛眩晕。",
      "medicine_name": "野菊花",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944618713000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/6058907931fd44deab96709b03b86839.png",
      "organ": "肾",
      "effect": "温肾壮阳",
      "property": "温、咸",
      "dict_name": "企标",
      "consumption": 60,
      "indications": "1.肾阳虚衰，腰膝冷痹，男子阳痿遗精，寒疝腹痛；\n2.女子宫冷不孕，崩漏。",
      "medicine_name": "阳起石",
      "suggest_dosage": "4.5～9g"
    },
    {
      "id": 1675686944610324500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/4b225a4b6b6347afa74110cc1267eddf.png",
      "organ": "肝",
      "effect": "破血通经，逐瘀消癥",
      "property": "咸、苦、平、小毒",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.血瘀经闭，癥核积聚；\n2.跌打损伤，心腹疼痛。",
      "medicine_name": "烫水蛭",
      "suggest_dosage": "1～3g"
    },
    {
      "id": 1675686944610324500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/0a4aa81592804703bb6b56aa3c3b8851.png",
      "organ": "肾、肝",
      "effect": "补肾强骨，续伤止痛",
      "property": "苦、温",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.肾虚腰痛，耳鸣耳聋，牙齿松动；\n2.跌扑闪挫，筋骨折伤，外斑秃，白癜风。",
      "medicine_name": "烫骨碎补",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944610324500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/8fba4d4845d0453cbf202638ba05b5b6.png",
      "organ": "肝、肾",
      "effect": "补肝肾，强腰脊，祛风湿",
      "property": "苦、甘、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.腰膝酸软，下肢无力；\n2.风湿痹痛。",
      "medicine_name": "烫狗脊",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944610324500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/fe7ec80188064cea96fdd57f9046bf36.png",
      "organ": "脾、肺",
      "effect": "补气健脾，生津润肺",
      "property": "甘、微苦、平",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "脾肺气阴两虚证",
      "medicine_name": "太子参",
      "suggest_dosage": "9～30g"
    },
    {
      "id": 1675686944610324500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/3889d67870474b53bca56d055722f437.png",
      "organ": "肝、肾、大肠",
      "effect": "补肾助阳，润肠通便",
      "property": "甘、温",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.肾阳亏虚，精血不足，阳痿，不孕，下肢痿软，筋骨无力；\n2.血虚津亏肠燥便秘。",
      "medicine_name": "锁阳",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944610324500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/c108cc707de845c09feda865679735e8.png",
      "organ": "肺、胃、肝",
      "effect": "通络，活血，祛风",
      "property": "甘、平",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.痹痛拘挛；\n2.胸胁胀痛；\n3.乳汁不通。",
      "medicine_name": "丝瓜络",
      "suggest_dosage": "5～12g"
    },
    {
      "id": 1675686944610324500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/193db3b45ac343a8b620a505e3db972c.png",
      "organ": "肝、肾",
      "effect": "补血养阴，填精益髓",
      "property": "甘、微温",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.血虚诸证；\n2.肝肾阴虚诸证。",
      "medicine_name": "熟地黄",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944610324500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/a2ecee6e18454be2ae701687686fc38d.png",
      "organ": "脾、胃、大肠、肝、心包",
      "effect": "泻热通肠，凉血解毒，逐瘀通经",
      "property": "苦、寒",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.实热便秘，积滞腹痛，泻痢不爽；\n2.湿热黄疸，血热吐衄，目赤，咽肿，肠痈腹痛，痈肿疔疮；\n3.瘀血经闭，跌打损伤，外水火烫伤，上消化道出血。",
      "medicine_name": "熟大黄",
      "suggest_dosage": "3～15g"
    },
    {
      "id": 1675686944610324500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/eb8bc7bcc95f45a7a106bfb8b2e98e9b.png",
      "organ": "心、肝",
      "effect": "养血安神，祛风通络",
      "property": "甘、平",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.心神不宁，失眠多梦；\n2.血虚身痛，风湿痹痛；\n3.皮肤瘙痒。",
      "medicine_name": "首乌藤",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944610324500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/b0080acb378b402089b846269754ed1f.png",
      "organ": "肺、膀胱",
      "effect": "利尿通淋，清肺止咳，凉血止血",
      "property": "甘、苦、微寒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.淋证；\n2.肺热咳喘；\n3.血热出血。",
      "medicine_name": "石韦",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944606130200,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/9b8b4a356442406ea566d3c7cc50f837.png",
      "organ": "肝",
      "effect": "平肝潜阳，清肝明目",
      "property": "咸、寒",
      "dict_name": "企标",
      "consumption": 30,
      "indications": "1.肝阳上亢，头晕目眩；\n2.目赤，翳障，视物昏花。",
      "medicine_name": "石决明",
      "suggest_dosage": "6～20g"
    },
    {
      "id": 1675686944606130200,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/cfd78c6bdfd8446b81da7d791a8cd6aa.png",
      "organ": "胃、肾",
      "effect": "益胃生津，滋阴清热",
      "property": "甘、微寒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.胃阴虚证，热病伤津证；\n2.肾阴虚证。",
      "medicine_name": "石斛",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944606130200,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/1b7b3fedcc084239ac37f7f0ade49013.png",
      "organ": "脾、胃、肺",
      "effect": "利水消肿，渗湿，健脾，除痹，清热排脓",
      "property": "甘、淡、凉",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.水肿，小便不利，脚气；\n2.脾虚泄泻；\n3.湿痹拘挛；\n4.肺痈，肠痈。",
      "medicine_name": "生薏苡仁",
      "suggest_dosage": "9～30g"
    },
    {
      "id": 1675686944606130200,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/04e69a9483bc46beb91405def599a781.png",
      "organ": "肺、胃",
      "effect": "生用：清热泻火，除烦止渴；燼用：敛疮生肌，收湿，止血",
      "property": "甘、辛、大寒",
      "dict_name": "企标",
      "consumption": 60,
      "indications": "1.温热病气分实热证；\n2.肺热咳喘证；\n3.胃火牙痛，头痛，实热消渴；\n4.溃疡不敛，湿疹瘙痒，水火烫伤，外伤出血。",
      "medicine_name": "生石膏",
      "suggest_dosage": "15～60g"
    },
    {
      "id": 1675686944606130200,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/48b52d2f5207474e90b58a0d09b0bd41.png",
      "organ": "脾、胃、肝",
      "effect": "消食化积，行气散瘀",
      "property": "酸、甘、微温",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.饮食积滞；\n2.泻痢腹痛，疝气痛；\n3.瘀阻胸腹痛，痛经。",
      "medicine_name": "生山楂",
      "suggest_dosage": "9～12g"
    },
    {
      "id": 1675686944606130200,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/e30e29abe6ef44e79d7356323b4ed90a.png",
      "organ": "肝、心包",
      "effect": "止血，化瘀，利尿",
      "property": "甘、平",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.出血证；\n2.瘀血痛证；\n3.血淋尿血。",
      "medicine_name": "生蒲黄",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944606130200,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/34b77cdd870c4e3999ef732beecc1328.png",
      "organ": "脾、胃、肝",
      "effect": "消食健胃，回乳消胀",
      "property": "甘、平",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.米面薯芋食滞；\n2.断乳，乳房胀痛。",
      "medicine_name": "生麦芽",
      "suggest_dosage": "10～15g"
    },
    {
      "id": 1675686944606130200,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/9f5c692df3704e45b1b69f9b641891f5.png",
      "organ": "心、肝、肾",
      "effect": "镇惊安神，平肝潜阳，收敛固涩",
      "property": "甘、涩、平",
      "dict_name": "企标",
      "consumption": 30,
      "indications": "1.心神不宁，心悸失眠，惊痫癫狂；\n2.肝阳眩晕；\n3.滑脱诸证；\n4.湿疮痒疹，疮疡久溃不敛。",
      "medicine_name": "生龙骨",
      "suggest_dosage": "15～30g"
    },
    {
      "id": 1675686944606130200,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/1fbb69fd38cc4dd6868c6530fb10ccd9.png",
      "organ": "心、肝",
      "effect": "宁心安神",
      "property": "甘、涩、凉",
      "dict_name": "企标",
      "consumption": 30,
      "indications": "1.惊痫癫狂；\n2.烦热不安；\n3.失眠多梦。",
      "medicine_name": "生龙齿",
      "suggest_dosage": "10～15g"
    },
    {
      "id": 1675686944606130200,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/9c0dfdb644ec43e6ad396f9168240139.png",
      "organ": "心、肺、脾、胃",
      "effect": "补脾益气，祛痰止咳，缓急止痛，清热解毒，调和诸药",
      "property": "甘、平",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.心气不足，脉结代，心动悸；\n2.脾气虚；\n3.咳喘；\n4.脘腹，四肢挛急疼痛；\n5.热毒疮疡，咽喉肿痛，药食中毒；\n6.调和药性。",
      "medicine_name": "生甘草",
      "suggest_dosage": "2～10g"
    },
    {
      "id": 1675686944606130200,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/8ebbeb64b32f4e869ae584f99df53721.png",
      "organ": "肝、肾",
      "effect": "补肝肾，强筋骨，安胎",
      "property": "甘、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.肾虚腰痛及各种腰痛；\n2.胎动不安，习惯性堕胎。",
      "medicine_name": "生杜仲",
      "suggest_dosage": "6～10g"
    },
    {
      "id": 1675686944606130200,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/3dc539c7879544239f2289494d72c8d2.png",
      "organ": "肝、大肠",
      "effect": "凉血止血，解毒敛疮",
      "property": "苦、酸、涩、微寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.血热出血证；\n2.烫伤，湿疹，疮疡痈肿。",
      "medicine_name": "生地榆",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944606130200,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/288349203b404398a5b641831d42b542.png",
      "organ": "脾、胃",
      "effect": "健脾益气，燥湿利尿，止汗，安胎",
      "property": "甘、苦、温",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.脾气虚证；\n2.气虚自汗；\n3.脾虚胎动不安。",
      "medicine_name": "生白术",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944606130200,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/c4e231a9b3af44cd98d7f62e4064f172.png",
      "organ": "肝、脾、肾",
      "effect": "温经止血，散寒调经，安胎",
      "property": "辛、苦、温、小毒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.出血证；\n2.月经不调，痛经；\n3.胎动不安。",
      "medicine_name": "生艾叶",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944597741600,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/9691aa2445a24504af2e776ff6a70da1.png",
      "organ": "脾、胃、大肠、胆、三焦",
      "effect": "行气止痛，健脾消食",
      "property": "辛、苦、温",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.脾胃气滞证；\n2.泻痢里急后重；\n3.腹痛胁痛，黄疸，疝气疼痛；\n4.胸痛。",
      "medicine_name": "木香",
      "suggest_dosage": "3～6g"
    },
    {
      "id": 1675686944593547300,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/15096dce34e9497a810d1b7b3ad5bf91.png",
      "organ": "肺、肝、胃",
      "effect": "清肺利咽，疏肝和胃",
      "property": "苦、甘、凉",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.喉痹音哑，肺热咳嗽；\n2.肝胃气痛。",
      "medicine_name": "木蝴蝶",
      "suggest_dosage": "1～3g"
    },
    {
      "id": 1675686944593547300,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/709e604dc13c400894e42b5ccb9b8469.png",
      "organ": "肝、脾",
      "effect": "舒筋活络，和胃化湿",
      "property": "酸、温",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.风湿痹症；\n2.脚气水肿；\n3.吐泻转筋。",
      "medicine_name": "木瓜",
      "suggest_dosage": "6～9g"
    },
    {
      "id": 1675686944593547300,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/16c380a48f6d4d50925f6b9c0cb88ace.png",
      "organ": "心、肝、肾",
      "effect": "清热凉血，活血祛瘀",
      "property": "苦、甘、微寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.温毒发斑，血热吐旭；\n2.温病伤阴，阴虚发热，夜热早凉，无汗骨蒸；\n3.血滞经闭，痛经，跌打伤痛；\n4.痈肿疮毒。",
      "medicine_name": "牡丹皮",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944593547300,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/069a5a6d9e2e4f41adc980f3d6badc3d.png",
      "organ": "肾、肝",
      "effect": "滋补肝肾，凉血止血",
      "property": "甘、酸、寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.肝肾阴虚，牙齿松动，须发早白，眩晕耳鸣，腰膝酸软，阴虚血热；\n2.吐血，衄血，尿血，血痢，崩漏下血，外伤出血。",
      "medicine_name": "墨旱莲",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944593547300,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/2498b4171c0846718709e983f3d229a5.png",
      "organ": "肝、胃",
      "effect": "清热解毒，止血，杀虫",
      "property": "苦、微寒、有小毒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.时疫感冒，风热头痛，温毒发斑；2.疮疡肿毒；3.崩漏下血；4.虫积腹痛。",
      "medicine_name": "绵马贯众",
      "suggest_dosage": "4.5～9g"
    },
    {
      "id": 1675686944593547300,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/7333303a0531439b9e68713fc6af02d2.png",
      "organ": "肺",
      "effect": "润肺化痰止咳",
      "property": "苦、辛、甘、微温",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.咳嗽有痰。",
      "medicine_name": "蜜紫菀",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944593547300,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/70a9fd0f0b084ee9b220f398e558d19d.png",
      "organ": "肺",
      "effect": "泻肺平喘，利水消肿",
      "property": "甘、寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.肺热咳喘；\n2.水肿。",
      "medicine_name": "蜜桑白皮",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944593547300,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/034f677e8863411598ac097ba105a513.png",
      "organ": "肺、胃",
      "effect": "清肺止咳，降逆止呕",
      "property": "苦、微寒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.肺热咳嗽，气逆喘急；\n2.胃热呕吐，啰逆。",
      "medicine_name": "蜜枇杷叶",
      "suggest_dosage": "6～10g"
    },
    {
      "id": 1675686944593547300,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/46790ad269454faf815a9647e2357758.png",
      "organ": "肺、膀胱",
      "effect": "发汗解表，宣肺平喘，利水消肿",
      "property": "辛、微苦、温",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.风寒感冒；\n2.咳嗽气喘；\n3.风水水肿。",
      "medicine_name": "蜜麻黄",
      "suggest_dosage": "2～10g"
    },
    {
      "id": 1675686944593547300,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/4d4df083c9ee4b12baf4af6cb1108adc.png",
      "organ": "肺",
      "effect": "润肺下气，止咳化痰",
      "property": "辛、微苦、温",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.咳嗽气喘。",
      "medicine_name": "蜜款冬花",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944593547300,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/f62223c984624c7d96ab33a65605d4a7.png",
      "organ": "肝",
      "effect": "清热泻火，养肝明目，退翳",
      "property": "甘、微寒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.目赤肿痛，多泪羞明，目生翳膜；\n2.肝虚目暗，视物昏花。",
      "medicine_name": "密蒙花",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944593547300,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/20c36c5857f443fe97e6b4895e414b53.png",
      "organ": "肝、脾",
      "effect": "疏肝解郁，活血止痛",
      "property": "甘、微苦、温",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.肝胃气痛；\n2.月经不调，经前乳房胀痛；\n3.跌打损伤。",
      "medicine_name": "玫瑰花",
      "suggest_dosage": "3～6g"
    },
    {
      "id": 1675686944593547300,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/0301445614d54d6e822ed28037541666.png",
      "organ": "肝、肺",
      "effect": "解毒；化痰散结",
      "property": "甘、辛、温",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.瘰疬，结核；\n2.咽炎；\n3.疔疮，蛇咬伤；\n4.疟疾；\n5.偏头痛，牙痛。",
      "medicine_name": "猫爪草",
      "suggest_dosage": "15～30g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/bd24ef2b88de426cb9780933c42d2ee6.png",
      "organ": "膀胱、肝、胃",
      "effect": "疏散风热，清利头目",
      "property": "辛、苦",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.风热感冒，头昏头痛；\n2.目赤肿痛，耳鸣耳聋。",
      "medicine_name": "炒蔓荆子",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944593547300,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/641ea4ed92c741d7bb0a14baa4cb03c8.png",
      "organ": "胃、肺、心",
      "effect": "养阴生津，润肺清心",
      "property": "甘、微苦、微寒",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.胃阴虚证；\n2.肺阴虚证；\n3.心阴虚证。",
      "medicine_name": "麦冬",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944593547300,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/b9d822dbb3ae4249b3e014516be97c22.png",
      "organ": "心、肝、脾、大肠",
      "effect": "清热解毒，凉血止血，止痢",
      "property": "酸、寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.热毒血痢，痈肿疔疮，湿疹，丹毒，蛇虫咬伤；\n2.便血，痔血，崩漏下血。",
      "medicine_name": "马齿苋",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944593547300,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/0a081e5ac28646e6a962976d89dbb59c.png",
      "organ": "肺",
      "effect": "固表止汗",
      "property": "甘、微涩、平",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.自汗；\n2.盗汗。",
      "medicine_name": "麻黄根",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944593547300,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/a4a3146a7be94d9e8e81fdb22725ac48.png",
      "organ": "肺、膀胱",
      "effect": "发汗解表，宣肺平喘，利水消肿",
      "property": "辛、微苦、温",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.风寒感冒；\n2.咳嗽气喘；\n3.风水水肿。",
      "medicine_name": "麻黄",
      "suggest_dosage": "2～10g"
    },
    {
      "id": 1675686944593547300,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/e1e695ccbcf04b5989a3500223c70472.png",
      "organ": "心、肝、肾",
      "effect": "祛风通络，凉血消肿",
      "property": "苦、平",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.风湿热痹，筋脉拘挛；\n2.腰膝酸痛；\n3.喉痹；\n4.痈肿；\n5.跌扑损伤。",
      "medicine_name": "络石藤",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944589353000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/424b87847b5d4a0dbb931f0f715e24d1.png",
      "organ": "肝、肾",
      "effect": "祛风通络，利水，通经",
      "property": "苦、平",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.风湿痹痛，中风半身不遂；\n2.跌打损伤；\n3.水肿；\n4.经行不畅，经闭；\n5.乳少，乳汁不通。",
      "medicine_name": "路路通",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944589353000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/42ab32269fc041c1886a2f840c63a535.png",
      "organ": "肝、肾",
      "effect": "祛风湿，强筋骨，止血，止咳",
      "property": "甘、苦、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.风湿痹证；\n2.月经过多，崩漏，咯血，夕卜伤出血；\n3.久咳劳嗽。",
      "medicine_name": "鹿衔草",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944589353000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/40877a93297942ceb830fbca3b7800d8.png",
      "organ": "肺、胃",
      "effect": "清热泻火，生津止渴，除烦，止呕，利尿",
      "property": "甘、寒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.热病烦渴；\n2.胃热呕啰；\n3.肺热咳嗽，肺痈吐脓；\n4.热淋涩痛。",
      "medicine_name": "芦根",
      "suggest_dosage": "15～30g"
    },
    {
      "id": 1675686944589353000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/023dfb8194c24d9380037606275fa0f2.png",
      "organ": "心、脾",
      "effect": "补益心神，养血安神",
      "property": "甘、温",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.思虑过度，劳伤心脾；\n2.惊悸怔中，失眠健忘。",
      "medicine_name": "龙眼肉",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944589353000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/8624daae5f7a4cd8b335adf0b831ba4c.png",
      "organ": "肝、胆",
      "effect": "清热燥湿，泻肝胆火",
      "property": "苦、寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.湿热黄疸，阴肿阴痒，带下，湿疹瘙痒；\n2.肝火头痛，目赤耳聋，胁痛口苦；\n3.惊风抽搐。",
      "medicine_name": "龙胆",
      "suggest_dosage": "3～6g"
    },
    {
      "id": 1675686944555798500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/adaeb26532114278bf26cf01753da382.png",
      "organ": "心、肝、脾",
      "effect": "散瘀止痛，疗伤止血，破血通经，消食化积",
      "property": "苦、温",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.跌打损伤，肿痛出血；\n2.血瘀经闭，产后瘀滞腹痛；\n3.食积腹痛，赤白痢疾。",
      "medicine_name": "北刘寄奴",
      "suggest_dosage": "6～9g"
    },
    {
      "id": 1675686944589353000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/48b59871d6eb45c1af591435e8afafd6.png",
      "organ": "肝",
      "effect": "活血通经，凉血祛风",
      "property": "甘、酸、寒、有毒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.月经不调，经闭癥瘕；\n2.产后乳肿；\n3.风疹发红，皮肤瘙痒，痤疮。",
      "medicine_name": "凌霄花",
      "suggest_dosage": "5～9g"
    },
    {
      "id": 1675686944589353000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/7ca9ea0eeb4745ceb39389ca103e75d2.png",
      "organ": "心、肾",
      "effect": "清心安神，交通心肾，涩精止血",
      "property": "苦、寒、无毒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.热入心包，神昏谵语；\n2.心肾不交，失眠遗精；\n3.血热吐血。",
      "medicine_name": "莲子心",
      "suggest_dosage": "2～5g"
    },
    {
      "id": 1675686944580964400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/2c69f09e1421424ca724bf491519eef1.png",
      "organ": "肝、肾",
      "effect": "行血补血，调经，舒筋通络",
      "property": "苦、微甘、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.月经不调，痛经，闭经；\n2.风湿痹痛，手足麻木，肢体瘫痪，血虚萎黄。",
      "medicine_name": "鸡血藤",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944580964400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/da8d85e891ea47bcbd0f7b7d9fcf1485.png",
      "organ": "脾、胃、大肠",
      "effect": "润燥通便",
      "property": "平",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.肠燥便秘。",
      "medicine_name": "火麻仁",
      "suggest_dosage": "10～15g"
    },
    {
      "id": 1675686944580964400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/122e8426e0e4442d887973924d9a5856.png",
      "organ": "肺、胆、脾、胃、大肠、小肠",
      "effect": "清热燥湿，泻火解毒，止血，安胎",
      "property": "苦、寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.湿温，暑湿，胸闷呕恶，湿热痞满，黄疸泻痢；\n2.肺热咳嗽，高热烦渴；\n3.血热吐；\n4.痈肿疮毒；\n5.胎动不安。",
      "medicine_name": "黄芩炭",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944580964400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/11632088058549f7aeec90cd3e0f31b5.png",
      "organ": "脾、肺",
      "effect": "健脾补中，升阳举陷，益卫固表，利尿，托毒生肌",
      "property": "甘、微温",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.脾气虚证；\n2.肺气虚证；\n3.气虚自汗；\n4.气血亏虚，疮疡难溃难腐，或溃久难敛。",
      "medicine_name": "黄芪",
      "suggest_dosage": "9～30g"
    },
    {
      "id": 1675686944580964400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/4db558925d3546159d406bd82593566d.png",
      "organ": "辛、脾、胃、胆、大肠",
      "effect": "清热燥湿，泻火解毒",
      "property": "苦、寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.湿热痞满，呕吐吞酸；\n2.湿热泻痢；\n3.高热神昏，心烦不寐，血热吐；\n4.痈肿疳疮，目赤牙痛；\n5.消渴；\n6.外湿疹，湿疮，耳道流脓。",
      "medicine_name": "黄连",
      "suggest_dosage": "2～5g"
    },
    {
      "id": 1675686944576770000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/acde959bbf394f62b5e1faff413eecd1.png",
      "organ": "肾、膀胱、大肠",
      "effect": "清热燥湿，泻火除蒸，解毒疗疮",
      "property": "苦、寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.湿热带下，热淋涩痛；\n2.湿热泻痢，黄疸；\n3.湿热脚气，痿证；\n4.骨蒸劳热，盗汗，遗精；\n5.疮疡肿毒，湿疹瘙痒。",
      "medicine_name": "黄柏炭",
      "suggest_dosage": "3～12g"
    },
    {
      "id": 1675686944576770000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/2fd0d12411f9415f8a274178a451d7af.png",
      "organ": "膀胱、肺、胃",
      "effect": "利尿通淋，清热解暑，收湿敛疮",
      "property": "甘、淡、寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.热淋，石淋，尿热涩痛；\n2.暑湿，湿温；\n3.湿疮，湿疹，雅子。",
      "medicine_name": "滑石",
      "suggest_dosage": "10～20g"
    },
    {
      "id": 1675686944576770000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/5c929f1936624c32a75644a5b8f3dce5.png",
      "organ": "心、肝、膀胱",
      "effect": "镇惊安神，活血散瘀，利尿通淋",
      "property": "甘、平",
      "dict_name": "企标",
      "consumption": 1,
      "indications": "1.心神不宁，心悸失眠，惊风，癫痫；\n2.痛经经闭，心腹刺痛，癥核积聚；\n3.淋证，癥闭。",
      "medicine_name": "琥珀",
      "suggest_dosage": "3～6g"
    },
    {
      "id": 1675686944576770000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/abd88d7b001c43d3a6f6c5aaadfe6162.png",
      "organ": "肝、胆、肺",
      "effect": "利湿退黄，清热解毒，散瘀止痛，化痰止咳",
      "property": "微苦、微寒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.湿热黄疸，淋浊，带下；\n2.水火烫伤，痈肿疮毒，毒蛇咬伤；\n3.经闭，癥核，跌打损伤；\n4.肺热咳嗽。",
      "medicine_name": "虎杖",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944564187100,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/0ca48ffc3aed4c279f632835b026c25d.png",
      "organ": "大肠、肝",
      "effect": "清热解毒，活血，祛风，止痛",
      "property": "苦、平",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.肠痈腹痛，热毒疮疡；\n2.跌打损伤，经闭痛经；\n3.风湿痹痛。",
      "medicine_name": "大血藤",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944576770000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/9238a199a435413197cb6685ee515fa7.png",
      "organ": "肺",
      "effect": "益气活血，通脉平喘",
      "property": "甘、涩、寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.气虚血瘀，胸痹心痛；\n2.中风偏瘫；3.倦怠气喘。",
      "medicine_name": "红景天",
      "suggest_dosage": "3～6g"
    },
    {
      "id": 1675686944576770000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/7e599fe8a1594277998016ae4af7cf11.png",
      "organ": "心、肝",
      "effect": "活血痛经，祛瘀止痛",
      "property": "辛、温",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.血滞闭经，痛经，产后瘀滞腹痛；\n2.症痕积聚；\n3.胸痹心痛，血瘀腹痛，胁痛；4.跌打损伤，瘀滞肿痛；5.瘀滞斑疹色暗。",
      "medicine_name": "红花",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944576770000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/cfaa41bb1b3e4733814439077f0c48c8.png",
      "organ": "肝、肾、大肠",
      "effect": "补肝肾，润肠燥",
      "property": "甘、平",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.精血亏虚，头晕眼花，须发早白；\n2.肠燥便秘。",
      "medicine_name": "黑芝麻",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944576770000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/7fc5700839324f4da43343b1926bd487.png",
      "organ": "心、肾、脾",
      "effect": "回阳救逆，补火助阳，散寒止痛",
      "property": "辛、甘、大热、有毒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.亡阳证；2.阳虚症；3.寒痹证（煎服；3.-1.；5.g,先煎0；5.-1.小时，至口尝无麻。",
      "medicine_name": "黑顺片",
      "suggest_dosage": "3～15g"
    },
    {
      "id": 1675686944576770000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/397ce433249b42118f34920a39ae4be7.png",
      "organ": "肝、脾、胃",
      "effect": "清暑化湿，升发清阳，凉血止血",
      "property": "苦、平",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.暑热烦渴，暑湿泄泻；2.脾虚泄泻；3.血热吐衄，便血崩漏。",
      "medicine_name": "荷叶",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944576770000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/903117333ff6452da8e11b21539797d2.png",
      "organ": "心、肝、肺",
      "effect": "解郁安神，活血消肿",
      "property": "甘、平",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.心神不宁，愤怒忧郁，烦躁失眠；2.跌打骨折，血瘀肿痛；3.肺痈，疮痈肿毒。",
      "medicine_name": "合欢花",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944576770000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/6bae12a05d944d0c9d8c76f01b2396d3.png",
      "organ": "肺、大肠",
      "effect": "涩肠止泻，敛肺止咳，利咽",
      "property": "苦、酸、涩、平",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.久泻，久痢；\n2.久咳，失音。",
      "medicine_name": "诃子",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944576770000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/ce6dd4d0d3ca47aa8f1ac477faba6524.png",
      "organ": "肝、肾",
      "effect": "消痰软坚，利水消肿",
      "property": "咸、寒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.瘻瘤，療痂，睾丸肿痛；\n2.痰饮水肿。",
      "medicine_name": "海藻",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944576770000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/1eab141a3d774212967695e6d4d04dc7.png",
      "organ": "肝、肾",
      "effect": "收敛止血，涩精止带，制酸止痛，收湿敛疮",
      "property": "咸、温、有毒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.吐血衄血，崩漏便血；\n2.遗精滑精，赤白带下；\n3.胃痛吞酸；\n4.损伤出血；\n5.湿疹湿疮，溃疡不敛。",
      "medicine_name": "海螵蛸",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944576770000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/7389aa35c24e44f8967dc5ed67cfb505.png",
      "organ": "膀胱、小肠",
      "effect": "利尿通淋，止痛",
      "property": "甘、咸、寒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.淋证。",
      "medicine_name": "海金沙",
      "suggest_dosage": "6～15g"
    },
    {
      "id": 1675686944576770000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/cecedd33dbba4bdf9399d5e9d92e58f2.png",
      "organ": "肝",
      "effect": "祛风湿，通络止痛",
      "property": "辛、苦、微温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.风寒湿痹；\n2.跌打损伤。",
      "medicine_name": "海风藤",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944572575700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/d5871eb5cdd44f1caf9959229121acc6.png",
      "organ": "肝、胆、肾、膀胱",
      "effect": "利湿退黄，利尿通淋，解毒消肿",
      "property": "甘、咸、微寒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.湿热黄疸；\n2.石淋，热淋；\n3.痈肿疔疮，毒蛇咬伤。",
      "medicine_name": "广金钱草",
      "suggest_dosage": "15～30g"
    },
    {
      "id": 1675686944572575700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/47532ce2a9fb42e7a4a9e97c1527b00a.png",
      "organ": "肺、胃、大肠",
      "effect": "润肺化痰，滑肠通便",
      "property": "甘、微苦、寒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.燥咳痰黏；2.肠燥便秘。",
      "medicine_name": "瓜蒌子",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944572575700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/eb6a3d4c95564ef58f79f74a89468c2b.png",
      "organ": "胃、肺、大肠",
      "effect": "清热涤痰，宽胸散结，润燥滑肠",
      "property": "甘、苦、寒",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.肺热咳嗽，痰浊黄稠;\n2.胸痹心痛，结胸痞满；\n3.乳痈，肺痈，肠痈；\n4.大便秘结。",
      "medicine_name": "瓜蒌",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944572575700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/2c392f3bd2a243bea4e57ba3e090de0d.png",
      "organ": "肝、心包",
      "effect": "清热平肝，息风定惊",
      "property": "甘、凉",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.头痛，眩晕；\n2.肝风内动，惊痫抽搐。",
      "medicine_name": "钩藤",
      "suggest_dosage": "3～12g"
    },
    {
      "id": 1675686944572575700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/6319e55ac8244427931a67a4238cc184.png",
      "organ": "胃、肺、肝、大肠",
      "effect": "清热补虚，止咳化痰，泻火退热",
      "property": "苦、寒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.肺痨咳血，骨蒸潮热；\n2.头晕耳鸣，腰酸腿软；\n3.心烦，目赤。",
      "medicine_name": "功劳叶",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944576770000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/b13b14f255774a7da0b72bac5f574a69.png",
      "organ": "肺、肾",
      "effect": "补肺益肾，纳气平喘，助阳益精",
      "property": "咸、平",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.肺虚咳嗽，肾虚作喘，虚劳喘咳；\n2.肾虚阳痿。",
      "medicine_name": "蛤蚧",
      "suggest_dosage": "3～6g"
    },
    {
      "id": 1675686944572575700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/8dc0a5a2eb1c4997b1a7dfe671065062.png",
      "organ": "膀胱",
      "effect": "祛风散寒，除湿止痛",
      "property": "辛、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.风寒感冒，巅顶疼痛；\n2.风寒湿痹。",
      "medicine_name": "藁本",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944572575700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/4233856ecbf54f45818bc66a0b56737a.png",
      "organ": "脾、胃",
      "effect": "散寒止痛，温中止呕",
      "property": "辛、热",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.胃寒冷痛；\n2.胃寒呕吐。",
      "medicine_name": "高良姜",
      "suggest_dosage": "3～6g"
    },
    {
      "id": 1675686944564187100,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/697a6b1469c849b999bf758c747e1fd5.png",
      "organ": "心、心包、肝",
      "effect": "活血调经，祛瘀止痛，凉血消痈，除烦安神",
      "property": "苦、微寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.月经不调，闭经痛经，产后瘀滞腹痛；\n2.血瘀心痛，脘腹疼痛，症核积聚，风湿痹证；\n3.疮痈肿毒；\n4.热病烦躁神昏。",
      "medicine_name": "丹参",
      "suggest_dosage": "10～15g"
    },
    {
      "id": 1675686944564187100,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/5b43cac17ad0429095f3d74650f5cc5e.png",
      "organ": "脾、胃、心",
      "effect": "补中益气，养血安神",
      "property": "甘、温",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.脾虚证；\n2.脏燥，失眠。",
      "medicine_name": "大枣",
      "suggest_dosage": "6～15g"
    },
    {
      "id": 1675686944564187100,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/3dc4cd0f3e6e4039a6b528f11123cae4.png",
      "organ": "心、肝",
      "effect": "凉血止血，散瘀解毒消痈",
      "property": "甘、苦、凉",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.血热出血证；\n2.热毒痈肿。",
      "medicine_name": "大蓟",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944564187100,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/03bff91a7e7a4064b252142a5ab34803.png",
      "organ": "脾、胃、大肠、肝、心包",
      "effect": "泻下攻积，清热泻火，凉血解毒，逐瘀通经",
      "property": "苦、寒",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.积滞便秘；\n2.血热吐,目赤咽肿；\n3.热毒疮疡，烧烫伤；\n4.瘀血诸证；\n5.湿热痢疾，黄疸，淋证。",
      "medicine_name": "大黄",
      "suggest_dosage": "3～15g"
    },
    {
      "id": 1675686944564187100,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/f70a630aa03f4d1b8b2ccccb6096986e.png",
      "organ": "脾、胃、大肠、小肠",
      "effect": "行气宽中，利水消肿",
      "property": "辛、微温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.胃肠气滞，脘腹胀闷，大便不爽；\n2.水肿胀满，脚气浮肿，小便不利。",
      "medicine_name": "大腹皮",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944564187100,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/642c5818d55040ad9cca256ec51164c0.png",
      "organ": "心、肝、脾",
      "effect": "活血，行气，止痛",
      "property": "辛、苦、温",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "气血瘀滞痛诸证",
      "medicine_name": "醋延胡索",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944564187100,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/901ed1f50c2244a1bb7fbcf6365b8012.png",
      "organ": "肺、心、肾",
      "effect": "收敛固涩，益气生津，补肾宁心",
      "property": "酸、甘、温",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.久咳虚喘；\n2.自汗，盗汗；\n3.遗精，滑精；\n4.久泻不止；\n5.津伤口渴，消渴；\n6.心悸，失眠，多梦。",
      "medicine_name": "醋五味子",
      "suggest_dosage": "2～6g"
    },
    {
      "id": 1675686944564187100,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/41d7eb42b1dd449193ecae523c7ad0b5.png",
      "organ": "肝",
      "effect": "活血止痛，化瘀止血",
      "property": "苦、咸、甘、温",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.瘀血阻滞痛证；\n2.瘀血阻滞出血证。",
      "medicine_name": "醋五灵脂",
      "suggest_dosage": "4.5～9g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/0b5d2097f0384d97a7c2d86ecc302f73.png",
      "organ": "肝、胆、胃",
      "effect": "疏肝破气，消积化滞",
      "property": "苦、辛、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.肝郁气滞证；\n2.气滞脘腹疼痛；\n3.虫积腹痛；\n4.症痕积聚，久疟痞块。",
      "medicine_name": "醋青皮",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/22c20e0c8f354734829b6cffaa7a4592.png",
      "organ": "脾、胃、小肠、膀胱",
      "effect": "消食健脾，涩精止遗",
      "property": "甘、平",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.饮食积滞，小儿疳积；\n2.肾虚遗精，遗尿；\n3.砂石淋证，胆结石。",
      "medicine_name": "醋鸡内金",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/8c32c2681dd8466f80691d8bf5a6b2e8.png",
      "organ": "肾、肝、心",
      "effect": "滋阴潜阳，益肾健骨，养血补心",
      "property": "甘、寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.阴虚阳亢，阴虚内热，虚风内动；\n2.肾虚骨痿，囟门不合；\n3.阴血亏虚，惊悸，失眠，健忘。",
      "medicine_name": "醋龟甲",
      "suggest_dosage": "9～24g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/0f63a23173304a9cb7ccf355334487a9.png",
      "organ": "肝、脾",
      "effect": "破血行气，消积止痛",
      "property": "辛、苦、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.症痕积聚，经闭，心腹瘀痛；2.食积脘腹胀痛。",
      "medicine_name": "醋莪术",
      "suggest_dosage": "6～9g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/1e51f7e626f74f0c97af468962c98f3e.png",
      "organ": "肝、脾、肾",
      "effect": "散寒止痛，温经止血",
      "property": "辛、苦、温、有小毒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.少腹冷痛，经寒不调，宫冷不孕；\n2.吐血，衄血，崩漏经多，妊娠下血；\n3.皮肤瘙痒；\n4.虚寒性出血。",
      "medicine_name": "醋艾炭",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/8775103483f1412cbfeed48c0e907f44.png",
      "organ": "脾、肺、心、肾",
      "effect": "益气健脾，补肾安神",
      "property": "甘、微苦、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.脾肺气虚证；\n2.肾虚腰膝酸痛；\n3.心脾不足，失眠，健忘。",
      "medicine_name": "刺五加",
      "suggest_dosage": "9～27g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/349089b3c12e42298e9723ab344405a7.png",
      "organ": "心、肺、大肠、膀胱",
      "effect": "清热解毒，凉血，消肿，燥湿",
      "property": "苦、寒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.外感风热，温病初起；\n2.肺热咳喘，肺痈吐痰，咽喉肿痛；\n3.湿热泻痢，热淋涩痛，湿疹瘙痒；\n4.痈肿疮毒，蛇虫咬伤。",
      "medicine_name": "穿心莲",
      "suggest_dosage": "6～9g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/0ed301f8ec1a460faecbcecb0d8c4bef.png",
      "organ": "肝、肺",
      "effect": "活血消癥，通经下乳，消肿排脓，搜风通络",
      "property": "甘、苦平",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.经闭癥瘕，乳汁不通；\n2.痈肿疮毒；\n3.风湿痹痛；\n4.中风瘫痪，麻木拘挛。",
      "medicine_name": "穿山龙",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/578689642ca34387a4935d41dc2d6eaf.png",
      "organ": "肝、肾",
      "effect": "活血调经，补肝肾，强筋骨，利水通淋，引火（血）下行",
      "property": "苦、甘、酸、平",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.瘀血阻滞经闭，痛经，经行腹痛，胞衣不下，跌打伤痛；\n2.腰膝酸痛，下肢痿软；\n3.淋证，水肿，小便不利；\n4.头痛，眩晕，齿痛，口舌生疮，吐血，血。",
      "medicine_name": "川牛膝",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/8c9274c8b368428f81cd69544060f5bb.png",
      "organ": "心、小肠、膀胱",
      "effect": "利尿通淋，清心火，通经下乳",
      "property": "苦、寒、有毒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.热淋涩痛，水肿；\n2.口舌生疮，心烦尿赤；\n3.经闭乳少。",
      "medicine_name": "川木通",
      "suggest_dosage": "3～6g"
    },
    {
      "id": 1675686944618713000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/d7614a9974784ae49c7467b55709a425.png",
      "organ": "肝、胃",
      "effect": "收敛止血，化瘀利尿",
      "property": "苦、平",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.出血证；\n2.小便不利。",
      "medicine_name": "血余炭",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944618713000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/ee5a497da86d43a7ab3a0b0c9b1f4c64.png",
      "organ": "肝",
      "effect": "活血定痛，化瘀止血，敛疮生肌",
      "property": "甘、咸、平",
      "dict_name": "企标",
      "consumption": 1,
      "indications": "1.跌打损伤，瘀滞心腹疼痛；\n2.外伤出血；\n3.疮疡不敛。",
      "medicine_name": "血竭",
      "suggest_dosage": "1～2g"
    },
    {
      "id": 1675686944618713000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/d93750850dc74f45895f7d00fed0e987.png",
      "organ": "肺、脾、胃、大肠",
      "effect": "降气，消痰，行水，止呕",
      "property": "辛、苦、咸、微温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.风寒咳嗽；\n2.痰饮蓄结，胸膈痞满,喘咳痰多；\n3.呕吐噫气，心下痞硬。",
      "medicine_name": "旋覆花",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944618713000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/9f6c6076d070414f80bb335b31c1079a.png",
      "organ": "肝、肾",
      "effect": "补益肝肾，强筋健骨，止血安胎，疗伤续折",
      "property": "苦、辛、微温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.阳痿不举，遗精遗尿；\n2.腰膝酸痛，寒湿痹痛；\n3.崩漏下血，胎动不安；\n4.跌打损伤，筋伤骨折。",
      "medicine_name": "续断",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944618713000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/2a580c149d8948418451ca90613934a8.png",
      "organ": "肝、胃",
      "effect": "祛风化湿，行气通络",
      "property": "温、辛",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.风湿痹痛；\n2.胃痛胀满，牙痛，痛经，跌打肿痛。",
      "medicine_name": "徐长卿",
      "suggest_dosage": "3～12g"
    },
    {
      "id": 1675686944614518800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/9f581beda3e646229730e67315e5a5da.png",
      "organ": "心、肺、胃、大肠",
      "effect": "通阳散结，行气导滞",
      "property": "温、辛、苦",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.胸痹疼痛，痰饮咳喘；\n2.泻痢后重。",
      "medicine_name": "薤白",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944614518800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/8068cdcb2eb74309a030671d0a34443f.png",
      "organ": "心、肝",
      "effect": "凉血止血，散瘀解毒消痈",
      "property": "甘、苦、凉",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.血热出血证；\n2.热毒痈肿。",
      "medicine_name": "小蓟",
      "suggest_dosage": "5～12g"
    },
    {
      "id": 1675686944614518800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/8110c362cbc14532a135cae6a1979509.png",
      "organ": "肝、脾、肺",
      "effect": "舒肝理气，宽中，化痰",
      "property": "温、辛、苦、酸",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.肝胃气滞，胸肋胀痛；\n2.脘腹痞满，呕吐噫气；\n3.痰多咳嗽。",
      "medicine_name": "香橼",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944614518800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/4e4a2a4f64d14e2885badae7ba36bb20.png",
      "organ": "肾、肝",
      "effect": "温肾壮阳，祛寒除湿",
      "property": "辛、热、有毒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.肾阳不足，命门火衰，阳痿精冷，小便频数；\n2.腰膝冷痛，筋骨痿软。",
      "medicine_name": "仙茅",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944614518800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/801c958aa3804b68a688540ccaaad82c.png",
      "organ": "肝、胆",
      "effect": "清热泻火，明目，散结消肿",
      "property": "辛、苦、寒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.目赤肿痛，头痛眩晕，目珠夜痛；\n2.療痂，瘻瘤；\n3.乳痈肿痛。",
      "medicine_name": "夏枯草",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944614518800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/43deb74359464c7bae1cceeb9f66283d.png",
      "organ": "肺、肾、心",
      "effect": "解表散寒，祛风止痛，通窍，温肺化饮",
      "property": "辛、温、小毒",
      "dict_name": "企标",
      "consumption": 6,
      "indications": "1.风寒感冒；\n2.头痛，牙痛，风湿痹痛；\n3.鼻渊；\n4.肺寒咳喘。",
      "medicine_name": "细辛",
      "suggest_dosage": "1～3g"
    },
    {
      "id": 1675686944614518800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/8e78e2543dd84903b9d4f11d76b5b7ec.png",
      "organ": "肝、肾",
      "effect": "祛风湿，通经络，清热解毒，降血压",
      "property": "辛、苦、寒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.风湿痹痛，肢体麻木，中风，手足不遂；\n2.痈肿疮毒，湿疹瘙痒；\n3.高血压。",
      "medicine_name": "豨莶草",
      "suggest_dosage": "9～12g"
    },
    {
      "id": 1675686944614518800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/398bc27bcefb43a2855a432512724a61.png",
      "organ": "肝、肾",
      "effect": "祛风湿，补肝肾，强筋骨，利水",
      "property": "辛、苦、温",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.风湿痹证；\n2.筋骨痿软，小儿行退，体虚乏力；\n3.水肿，脚气。",
      "medicine_name": "五加皮",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944614518800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/9345eef939f04757b2a5b68c063cb9e0.png",
      "organ": "肺、大肠、肾",
      "effect": "敛肺降火，止咳止汗，涩肠止泻，固精止遗，收敛止血，收湿敛疮",
      "property": "酸、涩、寒",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.咳嗽，咯血；\n2.自汗，盗汗；\n3.久泻，久痢；\n4.遗精，滑精；\n5.崩漏，便血痔血；\n6.湿疮，肿毒。",
      "medicine_name": "五倍子",
      "suggest_dosage": "3～6g"
    },
    {
      "id": 1675686944614518800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/be206c07f88e47659ce3cfa83188e6b6.png",
      "organ": "肺、脾、肾、膀胱",
      "effect": "行气止痛，温肾散寒",
      "property": "辛、温",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.寒凝气滞胸腹诸痛证；\n2.尿频，遗尿。",
      "medicine_name": "乌药",
      "suggest_dosage": "6～10g"
    },
    {
      "id": 1675686944614518800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/f94d88f5ccb743d68ffce7cdc5a35057.png",
      "organ": "肝、脾、肺、大肠",
      "effect": "敛肺止咳，涩肠止泻，安蛔止痛，生津止渴",
      "property": "酸、涩、平",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.肺虚久咳；\n2.久泻，久痢；\n3.蛔厥腹痛，呕吐；\n4.虚热消渴。",
      "medicine_name": "乌梅",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944614518800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/f8d383e12e5049ab9109b4a6d03221d8.png",
      "organ": "膀胱",
      "effect": "祛风湿，通络止痛，消骨煙",
      "property": "辛、咸、温",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.风湿痹症；\n2.骨煙咽喉。",
      "medicine_name": "威灵仙",
      "suggest_dosage": "6～10g"
    },
    {
      "id": 1675686944614518800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/d45bdd00de6343a7989ef7bc1a06894b.png",
      "organ": "肝、胃",
      "effect": "解毒，除湿，通利关节",
      "property": "甘、淡、平",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.杨梅毒疮，肢体拘挛；\n2.淋浊带下，湿疹瘙痒；\n3.痈肿疮毒。",
      "medicine_name": "土茯苓",
      "suggest_dosage": "15～60g"
    },
    {
      "id": 1675686944614518800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/2c1701e4e9ff4d829c198f4fd65d9136.png",
      "organ": "肺、脾",
      "effect": "解毒，散结，消肿",
      "property": "苦、微寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.乳痈；\n2.瘰疬，痰核。",
      "medicine_name": "土贝母",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944610324500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/9f4b4c7171fe45f599901ef7cca63fc5.png",
      "organ": "肺、膀胱",
      "effect": "下气行水，祛痰定喘",
      "property": "辛、苦、寒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.痰壅喘咳；\n2.水肿，腹水。",
      "medicine_name": "葶苈子",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944610324500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/35adcd8078da460caae345da7c036eb7.png",
      "organ": "心、肝",
      "effect": "清热化痰，清心定惊",
      "property": "甘、寒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.小儿惊风，中风癫痫，热病神昏；\n2.痰热咳喘。",
      "medicine_name": "天竺黄",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944610324500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/da09b6fa25c94851bb3ba86bc9796d10.png",
      "organ": "肝、胃",
      "effect": "清热解毒，消肿散结",
      "property": "甘、苦、寒",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.痈肿疔疮，乳痈；\n2.瘰疬，蛇虫咬伤。",
      "medicine_name": "天葵子",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944610324500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/f2886228000747888f2d4452892e3d65.png",
      "organ": "肺、胃",
      "effect": "清热泻火，生津止渴，消肿排脓",
      "property": "甘、微苦、微寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.热病烦渴；\n2.肺热燥咳；\n3.内热消渴；\n4.疮疡肿毒。",
      "medicine_name": "天花粉",
      "suggest_dosage": "10～15g"
    },
    {
      "id": 1675686944610324500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/a8a9cdcc182b4c229c0a7101911da12f.png",
      "organ": "肺、肾、胃",
      "effect": "养阴润燥，清肺生津",
      "property": "甘、苦、寒",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.肺阴虚证；\n2.肾阴虚证；\n3.热病伤津之食欲不振，口渴及肠燥便秘。",
      "medicine_name": "天冬",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944601936000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/63d0c4920d174f28ab42f0db1cab8071.png",
      "organ": "肺、脾、胃、大肠",
      "effect": "解表透疹，清热解毒，升举阳气",
      "property": "辛、微甘、微寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.外感表证；\n2.麻疹不透；\n3.齿痛口疮，咽喉肿痛，温毒发斑；\n4.气虚下陷，脏器脱垂，崩漏下血。",
      "medicine_name": "升麻",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944601936000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/d4adb844208d41d7bf5d520b102941ee.png",
      "organ": "肝、脾、肾",
      "effect": "祛风湿，舒筋活络",
      "property": "微苦、辛、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.风寒湿痹，肢软麻木；\n2.跌打损伤。",
      "medicine_name": "伸筋草",
      "suggest_dosage": "3～12g"
    },
    {
      "id": 1675686944601936000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/96f55b71ee8249d9ac79d6ab69606915.png",
      "organ": "肺",
      "effect": "清热解毒，消痰，利咽",
      "property": "苦、寒",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.咽喉肿痛；\n2.痰盛咳喘。",
      "medicine_name": "射干",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944601936000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/e76f5652c56c411da402980b0ace426b.png",
      "organ": "肾",
      "effect": "温肾壮阳，燥湿，祛风，杀虫",
      "property": "辛、苦、温、有小毒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.阳痿，宫冷；\n2.寒湿带下；\n3.湿痹腰痛；\n4.外阴湿疹，妇人阴痒，滴虫性阴道炎。",
      "medicine_name": "蛇床子",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944601936000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/356ba9cb1c2d4c20a9c2068a32c9c215.png",
      "organ": "脾、肺、肾",
      "effect": "补脾养胃，生津益肺，补肾涩精",
      "property": "甘、平",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.脾虚证；\n2.肺虚证；\n3.肾虚证；\n4.消渴气阴两虚证。",
      "medicine_name": "山药",
      "suggest_dosage": "15～30g"
    },
    {
      "id": 1675686944601936000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/48dd7e2679e548ba8227be382cc8c203.png",
      "organ": "肺、胃",
      "effect": "清热解毒，利咽消肿",
      "property": "苦、寒、有毒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.咽喉肿痛；\n2.牙龈肿痛。",
      "medicine_name": "山豆根",
      "suggest_dosage": "3～6g"
    },
    {
      "id": 1675686944601936000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/e2de49131d9c49bb988659d3b8e7431e.png",
      "organ": "脾、胃、肾",
      "effect": "化湿行气，温中止泻，安胎",
      "property": "辛、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.湿阻中焦及脾胃气滞证；2.脾胃虚寒吐泻；\n3.气滞妊娠恶阻及胎动不安。",
      "medicine_name": "砂仁",
      "suggest_dosage": "3～6g"
    },
    {
      "id": 1675686944601936000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/938c3f55b0024283bde976c66ab07c83.png",
      "organ": "肝",
      "effect": "祛风湿，利关节",
      "property": "微苦、平",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.风湿痹症。",
      "medicine_name": "桑枝",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944601936000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/0e2fa5b5cd234a969cef11434c84ad5d.png",
      "organ": "肺、肝",
      "effect": "疏散风热，清肺润燥，平抑肝阳，清肝明目",
      "property": "甘、苦、寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.风热感冒，温病初起；2.肺热咳嗽，燥热咳嗽；\n3.肝阳上亢眩晕；\n4.目赤昏花。",
      "medicine_name": "桑叶",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944601936000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/1ee24a462f4f4267b5d88934294268b7.png",
      "organ": "肝、肾",
      "effect": "固精缩尿，补肾助阳",
      "property": "甘、咸、平",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.遗精滑精，遗尿尿频；\n2.小便白浊。",
      "medicine_name": "桑螵蛸",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944601936000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/eaff8b36dec247f887f254c695caaf8d.png",
      "organ": "肝、肾经",
      "effect": "祛风湿，补肝肾，强筋骨，安胎元的作用",
      "property": "苦、甘、平",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.风湿痹痛；\n2.腰膝酸软，筋骨无力；\n3.崩漏经多，妊娠漏血；\n4.胎动不安；\n5.头晕目眩。",
      "medicine_name": "桑寄生",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944601936000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/57cfada7d59047c29d4ffaacf4cc6cab.png",
      "organ": "肺",
      "effect": "泻肺平喘，利水消肿",
      "property": "甘、寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.肺热咳喘；\n2.水肿。",
      "medicine_name": "桑白皮",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944601936000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/bcc3607bd93a4ef0a2f048357e6082ad.png",
      "organ": "肝、胃",
      "effect": "化瘀止血，活血定痛",
      "property": "甘、微苦",
      "dict_name": "企标",
      "consumption": 1,
      "indications": "1.出血证；2.跌打损伤，瘀血肿痛。",
      "medicine_name": "三七",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944601936000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/c4ab4e02ab74462e800df0f5bc783a9c.png",
      "organ": "肾、脾、心、肝",
      "effect": "补火助阳，散寒止痛，温经通脉，引火归元",
      "property": "辛、甘、大热",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.阳痿，宫冷；\n2.腹痛，寒疝；3.腹痛，胸痹，阴疽，闭经，痛经；4.虚阳上浮。",
      "medicine_name": "肉桂",
      "suggest_dosage": "1～5g"
    },
    {
      "id": 1675686944601936000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/78074caab99048fa93b71ac6ccf091c9.png",
      "organ": "心、肺",
      "effect": "清热，解毒，通络",
      "property": "甘、寒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.温病发热；2.热毒血痢，痈肿疮毒，筋骨疼痛。",
      "medicine_name": "忍冬藤",
      "suggest_dosage": "9～30g"
    },
    {
      "id": 1675686944601936000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/9b15d63bcd544e53bf840c17c91f6cc7.png",
      "organ": "肺、脾、心",
      "effect": "大补元气，补脾益肺，生津，安神益智",
      "property": "甘、微苦、平",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.元气虚脱证；\n2.肺脾心肾气虚证；\n3.热病气虚津伤口渴及消渴证。",
      "medicine_name": "人参",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944601936000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/a2ea0dfc51cc4bf7af4895975a45fc46.png",
      "organ": "肺、肝、大肠",
      "effect": "清热解毒，止血，消肿",
      "property": "微寒、苦、涩",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.赤痢热泻，湿热痢疾；\n2.热毒痈肿；\n3.肺热咳嗽；\n4.痈肿瘰疬，咽喉肿痛，口舌生疮以及蛇虫咬伤。",
      "medicine_name": "拳参",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944601936000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/30d62dba678847c9bd9b8f66d86e8f77.png",
      "organ": "脾、胃、肺",
      "effect": "燥湿化痰，降逆止呕，消痞散结",
      "property": "辛、温、有毒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.痰多咳喘，痰饮眩悸；\n2.风痰眩晕，痰厥头痛；\n3.呕吐反胃，胸脘痞闷；\n4.梅核气；\n5.生外痈肿痰核。",
      "medicine_name": "清半夏",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944601936000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/3961508223744453a96ef3806c64d633.png",
      "organ": "肝、胆",
      "effect": "清虚透热，凉血除蒸，解暑，截疟",
      "property": "苦、辛、寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.温邪伤阴，夜热早凉；\n2.阴虚发热，劳热骨蒸；\n3.暑热外感，发热口渴；\n4.疟疾寒热。",
      "medicine_name": "青蒿",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944597741600,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/81584ebbd56d42f68128042a2452322f.png",
      "organ": "肺、胃",
      "effect": "清热，利咽，生津，解毒",
      "property": "平、甘、酸",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.咽喉肿痛，咳嗽；\n2.烦渴；\n3.鱼蟹中毒症。",
      "medicine_name": "青果",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944597741600,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/766ecf71c179479590f41fb32e4b0ffa.png",
      "organ": "肝、脾",
      "effect": "祛风湿，通经络，利小便",
      "property": "苦、辛、平",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.风湿痹证；\n2.水肿，脚气。",
      "medicine_name": "青风藤",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944597741600,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/42adfb749e664a32a1fbcf7313429e1c.png",
      "organ": "肝、胆、大肠",
      "effect": "清热燥湿，收涩止痢，止带，明目",
      "property": "苦、涩、寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.湿热泻痢，带下阴痒；\n2.肝热目赤肿痛，目生翳膜。",
      "medicine_name": "秦皮",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944597741600,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/921b802c166a4a9084f27d665c6cb632.png",
      "organ": "胃、肝、胆",
      "effect": "祛风湿，清湿热，止痹痛",
      "property": "辛、苦、平",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.风湿痹痛，筋脉拘挛，骨节酸痛；\n2.日晡潮热，小儿疳积发热。",
      "medicine_name": "秦艽",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944597741600,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/42dd36a7eba44b8ebf0aea3fa23f2579.png",
      "organ": "膀胱、肾",
      "effect": "解表散寒，祛风胜湿，止痛",
      "property": "辛、苦、温",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.风寒感冒；\n2.风寒湿痹。",
      "medicine_name": "羌活",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944597741600,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/c0470949f786403ebacd640ec87358ec.png",
      "organ": "肝",
      "effect": "凉血化瘀止血，痛经",
      "property": "苦、寒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.出血证；\n2.血瘀经闭，跌打损伤，风湿痹痛。",
      "medicine_name": "茜草",
      "suggest_dosage": "6～10g"
    },
    {
      "id": 1675686944597741600,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/e15ac6a84af545e98f0439e2d1d68fb2.png",
      "organ": "肺",
      "effect": "降气化痰，疏散风热",
      "property": "苦、辛、微寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.痰热咳喘；\n2.风热咳嗽。",
      "medicine_name": "前胡",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944597741600,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/b6667b2c800a439da9f79a1e2fd166e2.png",
      "organ": "肝、心包",
      "effect": "止血，化瘀，通淋",
      "property": "甘、平",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.吐血，衄血，咯血，崩漏，外伤出血，经闭通经，胸腹刺痛；\n2.跌扑肿痛；\n3.血淋涩痛。",
      "medicine_name": "蒲黄炭",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944597741600,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/a603c4f258814ceabef1cf63263fe850.png",
      "organ": "肝、胃",
      "effect": "清热解毒，消肿散结，利湿通淋",
      "property": "苦、甘、寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.痈肿疔毒，乳痈内痈；\n2.热淋涩痛，湿热黄疸。",
      "medicine_name": "蒲公英",
      "suggest_dosage": "10～15g"
    },
    {
      "id": 1675686944597741600,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/3aea6c68a9214aa9bcaa6825468efab6.png",
      "organ": "脾、胃、肺",
      "effect": "化湿，解暑",
      "property": "辛、平",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.湿阻中焦；\n2.暑湿，湿温初起。",
      "medicine_name": "佩兰",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944597741600,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/b48d4eaeca104de784ab133144f2e983.png",
      "organ": "脾、肝",
      "effect": "温经止血，温中止痛",
      "property": "苦、涩、温",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.出血证；\n2.腹痛，腹泻。",
      "medicine_name": "炮姜",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944597741600,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/83bb36c785d3470bbc7dec824110a7b6.png",
      "organ": "肺、大肠",
      "effect": "清肺化痰，利咽开音，润肠通便",
      "property": "甘、寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.肺热音哑，咽喉疼痛，咳嗽；\n2.燥热便秘，头痛目赤。",
      "medicine_name": "胖大海",
      "suggest_dosage": "2～3枚"
    },
    {
      "id": 1675686944597741600,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/37ade8072cce434bb47ebf2a79a9a997.png",
      "organ": "肝、肺、胃",
      "effect": "收敛止血，化瘀",
      "property": "甘、涩、平",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.吐血；\n2.咯血；\n3.衄血；\n4.尿血；\n5.崩漏。",
      "medicine_name": "藕节",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944597741600,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/0501423277b1485580be74807fef3958.png",
      "organ": "肝、肾",
      "effect": "活血调经，补肝肾，强筋骨，利水通淋，引火（血）下行",
      "property": "苦、甘、酸、平",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.瘀血阻滞经闭，痛经，经行腹痛，胞衣不下，跌打伤痛；\n2.腰膝酸痛，下肢痿软；\n3.淋证，水肿，小便不利；4.头痛，眩晕，齿痛，口舌生疮，吐血，血。",
      "medicine_name": "牛膝",
      "suggest_dosage": "5～12g"
    },
    {
      "id": 1675686944597741600,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/a7bd9071b5d04be3a33dfde3cc16c478.png",
      "organ": "肺、胃",
      "effect": "养阴清肺，清胃生津，补气，化痰",
      "property": "甘、微寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.肺阴虚证；\n2.胃阴虚证。",
      "medicine_name": "南沙参",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944589353000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/5a79a07f4f8143e486c8f4b6effbfdd4.png",
      "organ": "肺、心、小肠",
      "effect": "清热解毒，消肿散结，疏散风热",
      "property": "苦、微寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.痈肿疮毒，療痂痰核；\n2.风热外感，温病初起；\n3.热淋涩痛。",
      "medicine_name": "连翘",
      "suggest_dosage": "6～15g"
    },
    {
      "id": 1675686944589353000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/de1c68a02bd34db89a691db5440c30af.png",
      "organ": "肝、肾",
      "effect": "行气散结，祛寒止痛",
      "property": "辛、微苦、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.寒疝腹痛；\n2.睾丸肿痛。",
      "medicine_name": "荔枝核",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944585158700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/cffe4422aba74494be498f2d43b0ceba.png",
      "organ": "肝、胆、肺",
      "effect": "清热利湿，止血",
      "property": "甘、辛、平、无毒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.肺热咯血，衄血，吐血，便血，痔疮出血；\n2.发热，湿热黄疸；\n3.小儿惊风；\n4.淋病，水肿，水火烫伤。",
      "medicine_name": "卷柏",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944601936000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/b37e99b495ef46659c0c23873473b797.png",
      "organ": "心、小肠",
      "effect": "利尿通淋，破血通经",
      "property": "苦、寒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.淋证；\n2.闭经，月经不调。",
      "medicine_name": "瞿麦",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944585158700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/a030c02fe5b7431e98fc6557bed890d0.png",
      "organ": "肺、脾",
      "effect": "理气宽中，燥湿化痰",
      "property": "辛、苦、温",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.咳嗽痰多；\n2.食积伤酒；\n3.呕恶痞闷。",
      "medicine_name": "橘红",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944585158700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/64cd373aced342b7a1d5798ef53c020f.png",
      "organ": "肺、肝",
      "effect": "疏散风热，平抑肝阳，清肝明目，清热解毒",
      "property": "辛、甘、苦、微寒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.风热感冒，温病初起；\n2.肝阳眩晕，肝风实证；\n3.目赤昏花；4.疮疡肿毒。",
      "medicine_name": "菊花",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944585158700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/21aae370fd794517bcf466d706c4cc45.png",
      "organ": "肝、肾",
      "effect": "补益肝肾，收涩固脱",
      "property": "酸、微温",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.眩晕耳鸣，腰膝酸痛，阳痿遗精，遗尿尿频；\n2.崩漏带下；\n3.大汗虚脱，内热消渴。",
      "medicine_name": "酒萸肉",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944585158700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/df7a5f6ad8854425b6f3039595d2d0d1.png",
      "organ": "肝、肾",
      "effect": "滋补肝肾，乌须明目",
      "property": "甘、苦、凉",
      "dict_name": "国标",
      "consumption": 20,
      "indications": "1.肝肾阴虚诸证。",
      "medicine_name": "酒女贞子",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944585158700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/026b383737d541828ee17314c08e411e.png",
      "organ": "脾、肺、肾",
      "effect": "补气养阴，健脾，润肺，益肾",
      "property": "甘、平",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.阴虚肺燥，干嗽少痰，肺肾阴虚，劳嗽久咳；\n2.脾胃虚弱；\n3.肾精亏虚，内热消渴。",
      "medicine_name": "酒黄精",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944585158700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/14d52c59a001464293d008b622258351.png",
      "organ": "脾、胃、大肠、肝、心包",
      "effect": "泻下攻积，清热泻火，凉血解毒，逐瘀通经",
      "property": "苦、寒",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.积滞便秘；\n2.血热吐,目赤咽肿；\n3.热毒疮疡，烧烫伤；\n4.瘀血诸证；\n5.湿热痢疾，黄疸，淋证。",
      "medicine_name": "酒大黄",
      "suggest_dosage": "3～15g"
    },
    {
      "id": 1675686944585158700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/e078ff421e0945628cb765ddd3af7a67.png",
      "organ": "肾、肝",
      "effect": "温补肝肾，壮阳固精",
      "property": "辛、甘、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.阳痿遗精，白带白淫；\n2.肝肾不足，腰膝痿软。",
      "medicine_name": "韭菜子",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944585158700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/e6b73ef42dd14ba5bff007019a83beba.png",
      "organ": "肺、肝",
      "effect": "收涩止血",
      "property": "辛、涩、微温",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.便血，崩漏；\n2.产后血晕。",
      "medicine_name": "荆芥穗炭",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944585158700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/d725eca6103243c8a9e6ea1af34014dc.png",
      "organ": "肺、肝",
      "effect": "解表散风，透疹，消疮",
      "property": "辛、微温",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.感冒，头痛；\n2.麻疹，风疹；\n3.疮疡初起。",
      "medicine_name": "荆芥穗",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944585158700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/4bb0645f9fdf4d61a057426a1aafb726.png",
      "organ": "肺、肝",
      "effect": "祛风解表，透疹消疮，止血",
      "property": "辛、微温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.外感表证；\n2.麻疹不透，风疹瘙痒；\n3.疮疡初起兼有表证；\n4.吐下血。",
      "medicine_name": "荆芥",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944585158700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/a51a598379674edeac9d773477f95104.png",
      "organ": "肾、膀胱、大肠",
      "effect": "固精缩尿，固崩止带，涩肠止泻",
      "property": "酸、涩、平",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.遗精滑精；2.遗尿尿频；3.崩漏带下；4.久泻久痢。",
      "medicine_name": "金樱子",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944585158700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/57eca5d4c40e4a078dc081d9508d0039.png",
      "organ": "肺、心、胃",
      "effect": "清热解毒，疏散风热",
      "property": "甘、寒",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.痈肿疔疮；\n2.外感风热，温病初起；\n3.热毒血痢。",
      "medicine_name": "金银花",
      "suggest_dosage": "6～15g"
    },
    {
      "id": 1675686944585158700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/09b6b21f17354859a68507d579741161.png",
      "organ": "肺、脾、肾",
      "effect": "补虚，安神，降压，降血压，益气健脾，清热解毒，止咳祛痰",
      "property": "苦、寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.脾虚气滞；\n2.脾虚肝郁湿阻；\n3.气虚血瘀；\n4.气阴两虚证；\n5.痰浊阻肺证6.热毒证。",
      "medicine_name": "绞股蓝",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944580964400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/ce7aa3ca9a614212bd288302dc697af5.png",
      "organ": "脾、胃",
      "effect": "消食和胃",
      "property": "肝、辛、温",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.饮食积滞。",
      "medicine_name": "焦神曲",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944580964400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/a5fd9ba2e357481483b7771d52f710f8.png",
      "organ": "脾、胃、肝",
      "effect": "消食化积，行气散瘀",
      "property": "酸、甘、微温",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.饮食积滞；\n2.泻痢腹痛，疝气痛；\n3.瘀阻胸腹痛，痛经。",
      "medicine_name": "焦山楂",
      "suggest_dosage": "9～12g"
    },
    {
      "id": 1675686944580964400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/336e180fa8d34f0290ca86a537481493.png",
      "organ": "脾、胃",
      "effect": "健脾益气，燥湿利尿，止汗，安胎",
      "property": "甘、苦、温",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.脾气虚证；\n2.气虚自汗；\n3.脾虚胎动不安。",
      "medicine_name": "焦白术",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944580964400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/59f1c4bf36b9451aa3d59dd129e32120.png",
      "organ": "肝、脾",
      "effect": "化瘀止血，理气止痛",
      "property": "辛、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.出血证；\n2.胸胁疼痛，跌损瘀痛；\n3.呕吐腹痛。",
      "medicine_name": "降香",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944580964400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/63678d23abff4c4aa1005a63b3e11167.png",
      "organ": "肝、脾",
      "effect": "活血行气，痛经止痛",
      "property": "辛、苦、温",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.气滞血瘀痛证；\n2.风湿痹痛。",
      "medicine_name": "姜黄",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944580964400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/932a3173afda4aca975e4e8ac191e038.png",
      "organ": "脾、胃、肺",
      "effect": "燥湿化痰，降逆止呕，消痞散结；外用消肿止痛",
      "property": "辛、温、有毒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.湿痰，寒痰证；\n2.呕吐；\n3.心下痞，结胸，梅核气；\n4.瘻瘤，痰核，痈疽肿毒，毒蛇咬伤。",
      "medicine_name": "姜半夏",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944572575700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/0774d30ca9094cf2a2c5be57936fd2bf.png",
      "organ": "脾、胃、肾、心、肺",
      "effect": "温中散寒，回阳通脉，温肺化饮",
      "property": "辛、热",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.腹痛，呕吐，泄泻；\n2.亡阳诂；\n3.寒饮喘咳。",
      "medicine_name": "干姜",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944572575700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/05728e835e1b4bbd93c6df3463129c7a.png",
      "organ": "心",
      "effect": "固表止汗，益气，除热",
      "property": "甘、凉",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.自汗，盗汗；\n2.骨蒸劳热。",
      "medicine_name": "浮小麦",
      "suggest_dosage": "10～30g"
    },
    {
      "id": 1675686944572575700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/0f482b8ccb8840ad9522c037f04f60e7.png",
      "organ": "肺、膀胱",
      "effect": "发汗解表，透疹止痒，利尿消肿",
      "property": "辛、寒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.风热感冒；\n2.麻疹不透；\n3.风疹瘙痒；\n4.水肿尿少。",
      "medicine_name": "浮萍",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944568381400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/26724a60a30645a9a1ab697916fcf45b.png",
      "organ": "胃、脾",
      "effect": "温中止血，温中止呕，温脾止泻，燥湿敛疮",
      "property": "辛、温、无毒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.吐血，衂血，便血，崩漏脾气虚寒性出血证；2.中焦虚寒之呕吐；3.妊娠恶阻；4.脘腹疼痛；5.久泻不止，痈肿疮疡。",
      "medicine_name": "伏龙肝",
      "suggest_dosage": "15～30g"
    },
    {
      "id": 1675686944568381400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/0b0f79d9a3fd424bb5912eeb02e1ee95.png",
      "organ": "脾、胃、大肠",
      "effect": "破气消痞，消痰消积",
      "property": "苦、辛、酸、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.胃肠积滞，湿热泻痢；\n2.胸痹，结胸；\n3.气滞胸肋疼痛；\n4.产后腹痛。",
      "medicine_name": "麸炒枳实",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944568381400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/cd9a98f594ed456dbf2b696d14e673ad.png",
      "organ": "脾、胃、肺",
      "effect": "利水消肿，渗湿，健脾，除痹，清热排脓",
      "property": "甘、淡、凉",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.水肿，小便不利，脚气；\n2.脾虚泄泻；\n3.湿痹拘挛；\n4.肺痈，肠痈。",
      "medicine_name": "麸炒薏苡仁",
      "suggest_dosage": "9～30g"
    },
    {
      "id": 1675686944568381400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/adc0117ea7a4476aac4ac93235235a5b.png",
      "organ": "脾、肾",
      "effect": "益肾固精，补脾止泻，除湿止带",
      "property": "甘、涩、平",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.遗精滑精，遗尿尿频；\n2.脾虚久泻；\n3.白浊，带下。",
      "medicine_name": "麸炒芡实",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944568381400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/96363333b5b34bc694824d20c60ec117.png",
      "organ": "脾、胃",
      "effect": "健脾益气，燥湿利尿，止汗，安胎",
      "property": "甘、苦、温",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.脾气虚证；\n2.气虚自汗；\n3.脾虚胎动不安。",
      "medicine_name": "麸炒白术",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944568381400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/df6964245cad4a5190910fd62d9695ba.png",
      "organ": "肝、脾、胃、肺",
      "effect": "疏肝解郁，理气和中，燥湿化痰",
      "property": "辛、苦、温",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.肝郁胸胁胀痛；\n2.气滞脘腹疼痛；\n3.久咳痰多，胸闷作痛。",
      "medicine_name": "佛手",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944568381400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/94670c5d35d744e5bd7de10b38f9cfc1.png",
      "organ": "膀胱、肝、脾",
      "effect": "祛风解表，胜湿止痛，止痉",
      "property": "辛、甘、微温",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.祛风解表；\n2.风疹瘙痒；\n3.风湿痹痛；\n4.破伤风证。",
      "medicine_name": "防风",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944568381400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/8a8998e31cc548ec9136ec5bc41d4c88.png",
      "organ": "大肠",
      "effect": "泻下通便",
      "property": "甘、苦、寒",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.热结便秘；2.腹水肿胀。",
      "medicine_name": "番泻叶",
      "suggest_dosage": "2～6g"
    },
    {
      "id": 1675686944568381400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/07394e27363c469cbd227f2bff9d9a1d.png",
      "organ": "脾、胃、肺",
      "effect": "燥湿化痰，降逆止呕，消痞散结；外用消肿止痛",
      "property": "辛、温、有毒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.湿痰，寒痰证；\n2.呕吐；\n3.心下痞，结胸，梅核气；\n4.瘻瘤，痰核，痈疽肿毒，毒蛇咬伤。",
      "medicine_name": "法半夏",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944568381400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/99a19fdcc6b8406c875ca0b664be51f9.png",
      "organ": "肝",
      "effect": "平肝潜阳，清肝明目",
      "property": "咸、寒",
      "dict_name": "企标",
      "consumption": 30,
      "indications": "1.肝阳上亢，头晕目眩；\n2.目赤，翳障，视物昏花。",
      "medicine_name": "煅石决明",
      "suggest_dosage": "6～20g"
    },
    {
      "id": 1675686944568381400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/9b1425040ab54baaba2b43c79ac83355.png",
      "organ": "肺、肾、胃",
      "effect": "清热化痰，软坚散结，制酸止痛；外用收湿敛疮",
      "property": "咸、寒",
      "dict_name": "企标",
      "consumption": 30,
      "indications": "1.痰火咳嗽，胸胁疼痛，痰中带血；\n2.瘰疬瘿瘤；\n3.胃痛吞酸。",
      "medicine_name": "煅蛤壳",
      "suggest_dosage": "6～15g"
    },
    {
      "id": 1675686944568381400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/efe0b715d8ff4dfe982b9396d6191b03.png",
      "organ": "肾、膀胱",
      "effect": "祛风湿，止痛，解表",
      "property": "辛、苦、微温",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.风寒湿痹；\n2.风寒挟湿表证；\n3.少阴头痛。",
      "medicine_name": "独活",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944564187100,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/b7d2e422ff534156be7a0f779fc1eb2f.png",
      "organ": "肺、脾、胃",
      "effect": "化湿行气，温中止呕",
      "property": "辛、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.湿阻中焦及脾胃气滞证；\n2.呕吐。",
      "medicine_name": "豆蔻",
      "suggest_dosage": "3～6g"
    },
    {
      "id": 1675686944564187100,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/3a4ed6998a6f4b48b5c87ef7a51039b6.png",
      "organ": "大肠、小肠、膀胱",
      "effect": "利尿通淋，下乳，润肠",
      "property": "甘、涩、凉",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.淋证；\n2.乳汁不通，乳房胀痛；\n3.便秘。",
      "medicine_name": "冬葵子",
      "suggest_dosage": "6～15g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/50ab9d9346cf4bb2b54db1aa66dcc298.png",
      "organ": "肺、心",
      "effect": "清热化痰，润肺止咳，散结消肿",
      "property": "苦、甘、微寒",
      "dict_name": "企标",
      "consumption": 1,
      "indications": "1.虚劳咳嗽，肺热燥咳；\n2.療痂，乳痈，肺痈。",
      "medicine_name": "川贝母",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944606130200,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/8c68c9ce6d2e403e8d42236513d3fdae.png",
      "organ": "辛、肺、三焦",
      "effect": "泻火除烦，清热利尿，凉血解毒焦梔子：凉血止血",
      "property": "苦、寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.热病心烦；\n2.湿热黄疸；\n3.血淋涩痛；\n4.血热吐旭；\n5.目赤肿痛；\n6.火毒疮疡。",
      "medicine_name": "生栀子",
      "suggest_dosage": "6～10g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/ab47c8863be340638fc905eae1e620e0.png",
      "organ": "心、小肠",
      "effect": "利水消肿，解毒排脓",
      "property": "甘、酸、平",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.水肿胀满，脚气浮肿；\n2.黄疸尿赤；\n3.风湿热痹；\n4.痈肿疮毒，肠痈腹痛。",
      "medicine_name": "赤小豆",
      "suggest_dosage": "9～30g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/bd69d6772e124d4c9c9b9655661040c9.png",
      "organ": "肝",
      "effect": "清热凉血，散瘀止痛",
      "property": "苦、微寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.温毒发斑，血热吐；\n2.目赤肿痛，痈肿疮疡；\n3.肝郁胁痛，经闭痛经，症痕腹痛，跌打损伤。",
      "medicine_name": "赤芍",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/0b695661f4754762a31d7602816bf3e8.png",
      "organ": "肝、肾、肺、小肠",
      "effect": "利尿通淋，渗湿止泻，明目，祛痰",
      "property": "甘、微寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.淋证，水肿；\n2.泄泻；\n3.目赤肿痛，目暗昏花，翳障；4.痰热咳嗽。",
      "medicine_name": "车前草",
      "suggest_dosage": "9～30g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/af3742f341e445319394a3f15cc73b5d.png",
      "organ": "肺、大肠",
      "effect": "降气化痰，止咳平喘，润肠通便",
      "property": "辛、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.咳嗽痰多；\n2.肠燥便秘。",
      "medicine_name": "炒紫苏子",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/2871646fcb9944818a708d0ab2184180.png",
      "organ": "心、肝、胆",
      "effect": "养心益肝，安神，敛汗",
      "property": "甘、酸、平",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.心悸失眠；\n2.自汗，盗汗。",
      "medicine_name": "炒酸枣仁",
      "suggest_dosage": "10～15g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/81afb9cf09dc496dbafa755afe2c29ad.png",
      "organ": "脾、胃",
      "effect": "消食和胃",
      "property": "肝、辛、温",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "饮食积滞",
      "medicine_name": "炒神曲",
      "suggest_dosage": "10～15g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/62d6036bd8794868bd6378ec28bb7f35.png",
      "organ": "肺、胃",
      "effect": "疏散风热，宣肺祛痰，利咽透疹，解毒消肿",
      "property": "辛、苦、寒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.风热感冒，温病初起；\n2.麻疹不透，风疹瘙痒；\n3.痈肿疮毒，丹毒，定腮，喉痹。",
      "medicine_name": "炒牛蒡子",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/c7ec0738bbba4d0daa59f2495d362c86.png",
      "organ": "脾、胃、肝",
      "effect": "消食健胃，回乳消胀",
      "property": "甘、平",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.米面薯芋食滞；\n2.断乳，乳房胀痛。",
      "medicine_name": "炒麦芽",
      "suggest_dosage": "10～15g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/7bf4b8df4a854ceb96aea866e1f18960.png",
      "organ": "肺、脾、胃",
      "effect": "消食除胀，降气化痰",
      "property": "辛、甘、平",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.食积气滞；\n2.咳喘痰多，胸闷食少。",
      "medicine_name": "炒莱菔子",
      "suggest_dosage": "5～12g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/93a8c311b15c4dacb93a9158a6244579.png",
      "organ": "肝、大肠",
      "effect": "清热明目，润肠通便",
      "property": "甘、苦、咸、微寒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.目赤肿痛，羞明多泪，目暗不明；\n2.头痛，眩晕；\n3.肠燥便秘。",
      "medicine_name": "炒决明子",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944618713000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/7fff1c877eb846dcbeec85552f67c186.png",
      "organ": "肾、肝、脾",
      "effect": "补肾益精，养肝明目，止泻，安胎",
      "property": "辛、甘、平",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.肾虚腰痛，阳痿遗精，尿频，宫冷不孕；\n2.肝肾不足，目暗不明；\n3.脾肾阳虚，便溏泄泻；\n4.肾虚胎动不安。",
      "medicine_name": "盐菟丝子",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944618713000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/86314980bf1843b4bba8715722535388.png",
      "organ": "肝、肾",
      "effect": "补肾固精，养肝明目",
      "property": "甘、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.肾虚腰痛，阳痿遗精，遗尿尿频，白带过多；\n2.目暗不明，头昏眼花。",
      "medicine_name": "盐沙苑子",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944618713000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/98ab08dbb886463188013dddade5c91c.png",
      "organ": "肝、肾",
      "effect": "补肝肾，强筋骨，安胎",
      "property": "甘、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.肾虚腰痛及各种腰痛；\n2.胎动不安，习惯性堕胎。",
      "medicine_name": "盐杜仲",
      "suggest_dosage": "6～10g"
    },
    {
      "id": 1675686944564187100,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/d53b900a97554d6ab2b010d9bf898705.png",
      "organ": "脾、小肠",
      "effect": "利水消肿，清热解暑",
      "property": "甘、凉",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.水肿；\n2.暑热证。",
      "medicine_name": "冬瓜皮",
      "suggest_dosage": "9～30g"
    },
    {
      "id": 1675686944564187100,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/3d1faef68caa4e6881c8a8b0f1047eb3.png",
      "organ": "脾、胃、肺、肾",
      "effect": "温中降逆,散寒止痛，温肾助阳",
      "property": "辛、温",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.胃寒呕吐，呃逆；\n2.脘腹冷痛；\n3.阳痿，宫冷。",
      "medicine_name": "丁香",
      "suggest_dosage": "1～3g"
    },
    {
      "id": 1675686944564187100,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/a1b85206486d4c9b8de306cf655dd2b0.png",
      "organ": "肝、脾、膀胱",
      "effect": "清热定惊，通络，平喘，利尿",
      "property": "咸、寒",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.高热惊痫，癫狂；\n2.气虚血滞，半身不遂；\n3.痹证；\n4.肺热哮喘；\n5.小便不利，尿闭不通。",
      "medicine_name": "地龙",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944564187100,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/60a54099315740f291ab63190e570be0.png",
      "organ": "肺、肝、肾",
      "effect": "凉血除蒸，清肺降火",
      "property": "甘、寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.阴虚发热，盗汗骨蒸；\n2.肺热咳嗽；\n3.血热出血证。",
      "medicine_name": "地骨皮",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944564187100,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/8e6b243d0f334e63869e753ada30788b.png",
      "organ": "肾、膀胱",
      "effect": "利尿通淋，清热利湿，止痒",
      "property": "辛、苦、寒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.淋证；\n2.阴痒带下，风疹，湿疹。",
      "medicine_name": "地肤子",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944564187100,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/3141688824284401a4d88018fdc67907.png",
      "organ": "脾、肺",
      "effect": "补脾肺气，补血，生津",
      "property": "甘、平",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.脾肺气虚证；\n2.气虚两虚证；\n3.气津两伤证。",
      "medicine_name": "党参",
      "suggest_dosage": "9～30g"
    },
    {
      "id": 1675686944564187100,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/6a7364e8c6204fcd9055d548009ba7ac.png",
      "organ": "肝、心、脾",
      "effect": "补血调经，活血止痛，润肠通便",
      "property": "甘、辛、温",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.血虚诸证；\n2.血虚血瘀，月经不调，经闭，痛经；\n3.虚寒性腹痛，跌打损伤，痈疽疮疡，风寒痹痛；\n4.血虚肠燥便秘。",
      "medicine_name": "当归",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944564187100,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/f8733562b6434e7bad503ef1116fb2b6.png",
      "organ": "辛、胃、小肠",
      "effect": "清热泻火，除烦，利尿",
      "property": "甘、淡、寒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.热病烦渴；\n2.口疮尿赤，热淋涩痛。",
      "medicine_name": "淡竹叶",
      "suggest_dosage": "6～10g"
    },
    {
      "id": 1675686944564187100,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/0786c9c61f8a487fa3ca17c0a0efd822.png",
      "organ": "肺、肝、脾",
      "effect": "燥湿化痰，祛风解痉；外用散结消肿",
      "property": "苦、辛、温、有毒",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.湿痰，寒痰证；\n2.风痰眩晕，中风，癫痫，破伤风；\n3.痈疽肿痛，蛇虫咬伤。",
      "medicine_name": "胆南星",
      "suggest_dosage": "3～6g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/af3837e80f834773ad5a64272614d06e.png",
      "organ": "肺、胃",
      "effect": "温肺化痰，利气，散结消肿",
      "property": "辛、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.寒痰咳喘，悬饮；\n2.阴疽流注，肢体麻木，关节肿痛。",
      "medicine_name": "炒芥子",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/1969c395b72c4460b650f2bd79c06c3e.png",
      "organ": "肝",
      "effect": "平肝解郁，活血祛风，明目，止痒",
      "property": "辛、苦、微温、小毒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.头痛眩晕；\n2.胸胁胀痛；\n3.乳闭乳痈；\n4.目赤翳障；\n5.风疹瘙痒。",
      "medicine_name": "炒蒺藜",
      "suggest_dosage": "6～10g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/ac8065c3548541e3bdb613968f645384.png",
      "organ": "肝、大肠",
      "effect": "凉血止血，清肝泻火",
      "property": "苦、微寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.血热出血证；\n2.目赤，头痛。",
      "medicine_name": "炒槐花",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/4ae50166338c4cfcb4ae60fd18f9f6d6.png",
      "organ": "肝、肾",
      "effect": "补肝肾，强筋骨，安胎",
      "property": "甘、温",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.肾虚腰痛及各种腰痛；\n2.胎动不安，习惯性堕胎。",
      "medicine_name": "炒杜仲",
      "suggest_dosage": "6～10g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/660086d385a1487a8fbeb94a11dbd1d4.png",
      "organ": "肺、大肠",
      "effect": "清热利尿，消肿排脓，消痰止咳",
      "property": "甘、寒、无毒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.水肿胀满，小便不利；\n2.肺痈，肠痈；\n3.痰热咳嗽，白油，白带。",
      "medicine_name": "炒冬瓜子",
      "suggest_dosage": "10～15g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/4bf1f0a43b424f769acbda891ea153a3.png",
      "organ": "脾、胃",
      "effect": "消食和中，健脾开胃",
      "property": "甘、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.食积不消，腹胀口臭；\n2.脾胃虚弱，不饥食少。",
      "medicine_name": "炒稻芽",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/324d9b8a6f7b4e5d8512357e68db81dc.png",
      "organ": "肺",
      "effect": "发散风寒，通鼻窍，祛风湿，止痛",
      "property": "辛、苦温、有毒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.风寒感冒；\n2.鼻渊；\n3.风湿痹痛。",
      "medicine_name": "炒苍耳子",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/048e4a6c8c4d486fa61fb5e26a28eeb7.png",
      "organ": "脾、胃",
      "effect": "补脾和中，化湿",
      "property": "甘、微温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.脾气虚证；\n2.暑湿吐泻。",
      "medicine_name": "炒白扁豆",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944555798500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/d99569fea93e415787fb9ac2f747ead8.png",
      "organ": "脾、胃",
      "effect": "燥湿行气，温中止呕",
      "property": "辛、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.寒湿中阻证；\n2.寒湿呕吐证。",
      "medicine_name": "草豆蔻",
      "suggest_dosage": "3～6g"
    },
    {
      "id": 1675686944555798500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/a897e4fcb22e4866b876811df1ba63ab.png",
      "organ": "肝、脾、胃",
      "effect": "祛风除湿，活血定痛，和胃化湿",
      "property": "甘、辛、温",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.风湿痹痛；\n2.风疹瘙痒，头风头痛，皮肤不仁；\n3.关节不遂，吐泻转筋；\n4.风疹，湿疹；\n5.腰脚冷痛；\n6.烂弦风眼。",
      "medicine_name": "蚕砂",
      "suggest_dosage": "10～15g"
    },
    {
      "id": 1675686944555798500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/2ad0e2ad286549fab3d99c2c42f8d228.png",
      "organ": "肺、肝",
      "effect": "疏散风热，清利头目，利咽透疹，疏肝行气",
      "property": "辛、凉",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.风热感冒，温病初起；\n2.风热头痛，目赤多泪，咽喉肿痛；\n3.麻疹不透，风疹瘙痒；\n4.肝郁气滞，胸闷胁痛。",
      "medicine_name": "薄荷",
      "suggest_dosage": "3～6g"
    },
    {
      "id": 1675686944555798500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/413e6851cb7044278abfe7b222342195.png",
      "organ": "胃、大肠",
      "effect": "杀虫消积，行气，利水，截疟",
      "property": "苦、辛、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.肠道寄生虫病；\n2.食积气滞，泻痢后重；\n3.水肿，脚气肿痛；\n4.疟疾。",
      "medicine_name": "槟榔",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944555798500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/b8736936468e4816bbb93bce8e2cd8ad.png",
      "organ": "脾、胃",
      "effect": "温中散寒，下气止痛",
      "property": "辛、热",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.心腹冷痛；\n2.呕吐吞酸，肠鸣泄泻，冷痢，阴疝；\n3.头痛，牙痛；\n4.痛经，月经失调。",
      "medicine_name": "荜茇",
      "suggest_dosage": "1～3g"
    },
    {
      "id": 1675686944555798500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/1919c4cbf42847f8a221c4d164627513.png",
      "organ": "肺、胃",
      "effect": "养阴清肺，益胃生津",
      "property": "甘、微苦、微寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.肺阴虚证；\n2.胃阴虚证。",
      "medicine_name": "北沙参",
      "suggest_dosage": "5～12g"
    },
    {
      "id": 1675686944555798500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/1364b847aa4a4722a36891f016370e33.png",
      "organ": "肺、胃",
      "effect": "清热解毒，利咽消肿",
      "property": "苦、寒、有毒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.咽喉肿痛；\n2.牙龈肿痛。",
      "medicine_name": "北豆根",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944555798500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/cf5105be33f54b1ab2a51ce067f077d0.png",
      "organ": "心、小肠、肺",
      "effect": "清热解毒，利水消肿",
      "property": "辛、平",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.疮痈肿毒，蛇虫咬伤；\n2.腹胀水肿；\n3.湿疮湿疹。",
      "medicine_name": "半枝莲",
      "suggest_dosage": "15～30g"
    },
    {
      "id": 1675686944555798500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/3f8e5382a40d42f18017bccf10d9760e.png",
      "organ": "心、小肠、肺",
      "effect": "清热解毒，利水消肿",
      "property": "辛、平",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.疮痈肿毒，蛇虫咬伤；\n2.腹胀水肿；\n3.湿疮湿疹。",
      "medicine_name": "半边莲",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944555798500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/d5212d48fcf24ccb8e62f7b002208ffd.png",
      "organ": "心、胃",
      "effect": "清热解毒，凉血，利咽",
      "property": "苦、寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.外感发热，温病初起，咽喉肿痛；\n2.温毒发斑，炸腮，丹毒，痈肿疮毒。",
      "medicine_name": "板蓝根",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944555798500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/fea6c8e3f10240cab93b4091b11f2d0f.png",
      "organ": "心、肾、大肠",
      "effect": "养心安神，润肠通便",
      "property": "甘、平",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.心悸失眠；\n2.肠燥便秘。",
      "medicine_name": "柏子仁",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944555798500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/507be10f6fae4dcf9c5d749b54122625.png",
      "organ": "肺、心、胃",
      "effect": "养阴清肺，清心安神",
      "property": "甘、微寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.阴虚燥咳，劳嗽咳血；\n2.阴虚有热之失眠心悸及百合病心肺阴虚内热证。",
      "medicine_name": "百合",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944555798500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/0a965654392842709bd7a285da8431f3.png",
      "organ": "肺",
      "effect": "润肺止咳，杀虫灭虱",
      "property": "甘、苦、微温",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.新久咳嗽，百日咳，肺捞咳嗽；\n2.蛻虫，阴道滴虫，头虱及疥廨。",
      "medicine_name": "百部",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944555798500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/1b30592a145e44cf8c03b6cd2be2f12e.png",
      "organ": "脾、胃、膀胱",
      "effect": "清热燥湿，祛风解毒",
      "property": "苦、寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.湿热疮毒，湿疹，疥廨；\n2.湿热黄疸，风湿热痹。",
      "medicine_name": "白鲜皮",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944555798500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/25c040905ca4475c97d60a0fa691a718.png",
      "organ": "胃、肝、肾",
      "effect": "清热凉血，利尿通淋，解毒疗疮",
      "property": "苦、咸、寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.阴虚发热，产后虚热；\n2.热淋，血淋；\n3.疮痈肿毒，毒蛇咬伤，咽喉肿痛；\n4.阴虚外感。",
      "medicine_name": "白薇",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944555798500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/4b44b93689424c6e9ca8ff61a24fefd0.png",
      "organ": "肝、脾",
      "effect": "养血敛阴，柔肝止痛，平抑肝阳",
      "property": "苦、酸、微寒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.肝血亏虚，月经不调；\n2.肝脾不和，胸胁脘腹疼痛，四肢挛急疼痛；\n3.肝阳上亢，头痛眩晕。",
      "medicine_name": "白芍",
      "suggest_dosage": "6～15g"
    },
    {
      "id": 1675686944555798500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/98aa9e0412e14a14b51226210ff968b0.png",
      "organ": "肺",
      "effect": "降气化痰",
      "property": "辛、苦、微温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "咳嗽痰多，气喘。",
      "medicine_name": "白前",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944555798500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/5759017a90f041698c4fbbc88373fa9d.png",
      "organ": "肺、胃、膀胱",
      "effect": "凉血止血，清热利尿，清肺胃热",
      "property": "甘、寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.血热出血证；2.水肿，热淋，黄疸；3.胃热呕吐，肺热咳喘。",
      "medicine_name": "白茅根",
      "suggest_dosage": "9～30g"
    },
    {
      "id": 1675686944555798500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/49aaea8de4b2481f9a3f38be472a5845.png",
      "organ": "肺、胃、肝",
      "effect": "收敛止血，消肿生肌",
      "property": "苦、甘、涩、寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.出血证；\n2.痈肿疮疡，手足皺裂，水火烫伤。",
      "medicine_name": "白及",
      "suggest_dosage": "6～15g"
    },
    {
      "id": 1675686944555798500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/ac1f46cf821543689a2f84afe2ec1c8b.png",
      "organ": "胃、大肠、小肠",
      "effect": "清热解毒，利湿通淋",
      "property": "微苦、甘、寒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.痈肿疮毒，咽喉肿痛，毒蛇咬伤；\n2.热淋涩痛。",
      "medicine_name": "白花蛇舌草",
      "suggest_dosage": "15～30g"
    },
    {
      "id": 1675686944589353000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/f6e57917e750405a840a777ed5924721.png",
      "organ": "脾、胃",
      "effect": "消食，消积，健脾，开胃，和胃",
      "property": "甘辛、温",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.食积不化，脘腹胀满；\n2.腹痛泻痢，时疫暴泻；\n3.癫痫。",
      "medicine_name": "六神曲",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944580964400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/c222ad949cd347a19904be05f3532986.png",
      "organ": "脾、胃",
      "effect": "消食，消积，健脾，开胃，和胃",
      "property": "甘、辛、温",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.食积不化，脘腹胀满；\n2.腹痛泻痢，时疫暴泻；\n3.癫痫。",
      "medicine_name": "焦建曲",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944580964400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/1611ba5fa631400eb0b0828826c24028.png",
      "organ": "脾、胃",
      "effect": "消食，消积，健脾，开胃，和胃",
      "property": "甘辛、温",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.食积不化，脘腹胀满，腹痛泻痢；\n2.时疫暴泻，癫痫。",
      "medicine_name": "建曲",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944568381400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/f54d838a1ce64223a4344d8391d26841.png",
      "organ": "肝",
      "effect": "散瘀止痛，续筋接骨",
      "property": "辛、平",
      "dict_name": "企标",
      "consumption": 60,
      "indications": "1.跌打损伤，筋骨折伤，瘀肿疼痛。",
      "medicine_name": "煅自然铜",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944606130200,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/dd25f5f3d87245ae80d5c1ec7403e9d2.png",
      "organ": "心、肝经",
      "effect": "镇心，安神，降逆气，暖子宫",
      "property": "甘、温",
      "dict_name": "企标",
      "consumption": 60,
      "indications": "1.虚劳惊悸，咳逆上气；\n2.妇女血海虚寒不孕。",
      "medicine_name": "生紫石英",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944606130200,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/9315f8b0ddcf4bf888e10f02255a8956.png",
      "organ": "心、胃、肾",
      "effect": "清热泻火",
      "property": "辛、咸、寒",
      "dict_name": "企标",
      "consumption": 60,
      "indications": "1.热病烦渴，癫狂；\n2.口疮，热毒疮肿，丹毒烫伤。",
      "medicine_name": "生寒水石",
      "suggest_dosage": "6～15g"
    },
    {
      "id": 1675686944614518800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/d05cdcf6517243979110ded3a6fa4c3b.png",
      "organ": "肝、肾、心",
      "effect": "利水消肿，祛风湿，强筋骨",
      "property": "辛、苦温、有毒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.水肿，小便不利；\n2.风湿痹证。",
      "medicine_name": "香加皮",
      "suggest_dosage": "3～6g"
    },
    {
      "id": 1675686944614518800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/75424b88a21346f3b265cc6ff3a6f359.png",
      "organ": "肺、脾",
      "effect": "杀虫，止痒",
      "property": "辛、温、有毒",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.体癣，手足癣，头癣多宗癣病；\n2.湿疹，皮炎，皮肤瘙痒。",
      "medicine_name": "土荆皮",
      "suggest_dosage": "外用适量"
    },
    {
      "id": 1675686944589353000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/31fa9ad01e674fcd9ea3dc23aad6a814.png",
      "organ": "肝",
      "effect": "化瘀止血",
      "property": "苦、涩、温",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.崩漏，尿血，痔疮出血；2.产后瘀阻，恶露不尽。",
      "medicine_name": "莲房",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944589353000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/9190ade70c124626b1eddd2554e6eb64.png",
      "organ": "辛、脾",
      "effect": "清热解毒，散结消肿",
      "property": "辛、苦、寒、有小毒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.时疫感冒，咽喉肿痛；\n2.疔疮肿痛，痈疽发背，痄腮丹毒。",
      "medicine_name": "苦丁茶",
      "suggest_dosage": "4.5～9g"
    },
    {
      "id": 1675686944564187100,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/2bbaa5fc105349679b67ecc0ecba37e6.png",
      "organ": "心、胃",
      "effect": "清热解毒，凉血消斑",
      "property": "苦、寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.热入营血，温毒发斑；\n2.喉痹口疮，炸腮丹毒。",
      "medicine_name": "大青叶",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944597741600,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/badac3bf3bb642cb95b0476a87994fc0.png",
      "organ": "心、脾",
      "effect": "破血通经，敛疮消肿",
      "property": "苦、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.经闭症瘕，胸腹胀痛，产后血瘀，跌打损伤；\n2.金疮出血，痈毒焮肿。",
      "medicine_name": "南刘寄奴",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944618713000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/1e97f64eec6e449593544c26130a75d5.png",
      "organ": "肺、胃、小肠",
      "effect": "祛风除湿，舒筋活络，活血止痛，解毒化疹",
      "property": "甘、微苦、寒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.风湿疼痛挛缩，肢体筋脉收缩抽急，心腹腰膝；\n2.内外各种疼痛；\n3.肿毒初起。",
      "medicine_name": "鸭跖草",
      "suggest_dosage": "15～30g"
    },
    {
      "id": 1675686944618713000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/63baf2aa0d0649c88660233e772394e4.png",
      "organ": "肝",
      "effect": "祛风湿，通络止痛",
      "property": "辛、苦、平",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.风湿痹痛；\n2.跌打损伤。",
      "medicine_name": "寻骨风",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944589353000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/828a83f7b5fd45388530684a36321115.png",
      "organ": "脾、膀胱",
      "effect": "祛风湿，通经络，止泻痢",
      "property": "苦、辛、平",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.风湿痹痛，麻木拘挛；\n2.筋骨酸痛；\n3.泄泻痢疾。",
      "medicine_name": "老鹳草",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944555798500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/e4f9fdbccefc4dfcac4e10cce0877262.png",
      "organ": "肺",
      "effect": "敛肺定喘，止带缩尿痰多喘咳，带下白浊，遗尿尿频",
      "property": "甘、苦涩、平、有小毒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.痰多喘咳；\n2.带下白浊，遗尿尿频。",
      "medicine_name": "白果仁",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944593547300,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/8782b5df83d84654869aa8cfd98b9fad.png",
      "organ": " 肝、脾、胃",
      "effect": "散结消肿，攻毒疗疮",
      "property": "苦、微甘、凉、有毒",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.疮疡肿毒，乳痈，瘰疠，痔漏；\n2.干癣，秃疮。",
      "medicine_name": "木鳖子",
      "suggest_dosage": "0.9～1.2g"
    },
    {
      "id": 1675686944580964400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/afbfe7761ff140089e0bb06737fc2503.png",
      "organ": "肝",
      "effect": "平肝解郁，活血祛风，明目，止痒",
      "property": "辛、苦、微温、小毒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.头痛眩晕；\n2.胸胁胀痛，乳闭乳痈；\n3.目赤翳障；\n4.风疹瘙痒。",
      "medicine_name": "蒺藜",
      "suggest_dosage": "6～10g"
    },
    {
      "id": 1675686944576770000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/63644b52a0454a8ca8ddbd9692ddc8a1.png",
      "organ": "肝、大肠",
      "effect": "清热泻火，凉血止血",
      "property": "苦、寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.肠热便血，痔肿出血；\n2.肝热头痛，眩晕目赤。",
      "medicine_name": "槐角",
      "suggest_dosage": "6～9g"
    },
    {
      "id": 1675686944606130200,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/d5f6cbaa70ab479dbbf8d37b659da535.png",
      "organ": "脾、胃经",
      "effect": "消食和中，健脾开胃",
      "property": "甘、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.食积不消，腹胀口臭；\n2.脾胃虚弱，不饥食少。",
      "medicine_name": "生稻芽",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944555798500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/0e3a61925ae049aab4c02b932ca343ee.png",
      "organ": "脾、胃",
      "effect": "燥湿温中，除痰截疟",
      "property": "辛、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.寒湿中阻证；\n2.疟疾。",
      "medicine_name": "草果仁",
      "suggest_dosage": "3～6g"
    },
    {
      "id": 1675686944555798500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/843c77226ee54e81b6d86b8210088bec.png",
      "organ": "肺",
      "effect": "发散风寒，通鼻窍，祛风湿，止痛",
      "property": "辛、苦温、有毒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.风寒感冒；\n2.鼻渊；\n3.风湿痹痛。",
      "medicine_name": "苍耳子",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944555798500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/14a3d2019b844096b785640d44a200ea.png",
      "organ": "肝、肾、脾、胃",
      "effect": "散寒止痛，理气和胃",
      "property": "辛、温",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.寒疝腹痛，睾丸偏坠胀痛，少腹冷痛，痛经；\n2.中焦虚寒气滞证。",
      "medicine_name": "八角茴香",
      "suggest_dosage": "3～6g"
    },
    {
      "id": 1675686944618713000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/7a98543c0fd84d649c7d664b21c586c9.png",
      "organ": "心、肝、脾",
      "effect": "活血，行气，止痛",
      "property": "辛、苦、温",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "气血瘀滞痛证",
      "medicine_name": "延胡索",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944614518800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/462739152d9b42aba940dc5ead750498.png",
      "organ": "肝、脾、三焦",
      "effect": "疏肝解郁，调经止痛，理气调中",
      "property": "辛、微苦、微甘、平",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.肝郁气滞胁痛，腹痛；\n2.月经不调，痛经，乳房胀痛；\n3.气滞腹痛。",
      "medicine_name": "香附",
      "suggest_dosage": "6～10g"
    },
    {
      "id": 1675686944597741600,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/cf096f88d2b14c16bd7504018d2d043b.png",
      "organ": "肝、肾",
      "effect": "祛风湿，强腰膝",
      "property": "甘、微温、平",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.风湿性关节炎，腰腿痛，腰肌劳损；\n2.白带；\n3.跌打损伤。",
      "medicine_name": "千斤拔",
      "suggest_dosage": "12～20g"
    },
    {
      "id": 1675686944585158700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/db8720beff444778b3cd3d8a53e9881b.png",
      "organ": "心、脾、肝",
      "effect": "开窍，豁痰，祛风，宣湿，健胃，解毒",
      "property": "辛、温、无毒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.热病神昏，癫癎，气闭耳聋；2.多梦健忘；\n3.胸痞呕恶，食欲不振；\n4.风湿痹痛，痈疽，疥癣。",
      "medicine_name": "九节菖蒲",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944580964400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/9ac8dc4f7d85406abc0a833a5ff98cd1.png",
      "organ": "心、肝、肾",
      "effect": "凉血，清热，降火，化痰，解毒，平喘，止血，软坚散结，止咳平喘",
      "property": "苦、辛、凉、有小毒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.吐血，衄血；\n2.瘿瘤，瘰疬；\n3.喉痹肿痛，百日咳，咳嗽气喘；\n4.疮痈瘰疬。",
      "medicine_name": "黄药子",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944606130200,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/5a1eef82bff8466fb62f7953902acd07.png",
      "organ": "肝、肾",
      "effect": "制用：补益精血生用：解毒，截疟，润肠通便",
      "property": "苦、甘、涩、微温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.精血亏虚，头晕眼花，须发早白，腰膝酸软；\n2.久疟，痈疽，療痂，肠燥便秘。",
      "medicine_name": "生何首乌",
      "suggest_dosage": "3～6g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/0774d83e075a4388ae84ae7eb554fa05.png",
      "organ": "脾、胃、大肠、胆、三焦",
      "effect": "行气止痛，健脾消食",
      "property": "辛、苦、温",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.脾胃气滞证；\n2.泻痢里急后重；\n3.腹痛胁痛，黄疸，疝气疼痛；4.胸痛。",
      "medicine_name": "川木香",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944593547300,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/523eab85fe0949fa98f47aaf835a621f.png",
      "organ": "肺、心、胃",
      "effect": "养阴清肺，清心安神",
      "property": "甘、微寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.阴虚燥咳，劳嗽咳血；\n2.阴虚有热之失眠心悸及百合病心肺阴虚内热证。",
      "medicine_name": "蜜百合",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944622907400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/174d33e0a79b452baf12c7e04d0a8eaa.png",
      "organ": "肺、肝",
      "effect": "祛风除湿，解毒止痛",
      "property": "甘、辛、温",
      "dict_name": "企标",
      "consumption": 6,
      "indications": "1.风湿关节痛；\n2.外疮疡肿毒。",
      "medicine_name": "珍珠透骨草",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944618713000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/96d97c0f15434205989eb02bc382d133.png",
      "organ": "肝、肾",
      "effect": "补益肝肾，强筋健骨，止血安胎，疗伤续折",
      "property": "苦、辛、微温",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.阳痿不举，遗精遗尿；\n2.腰膝酸痛，寒湿痹痛；\n3.崩漏下血，胎动不安；\n4.跌打损伤，筋伤骨折。",
      "medicine_name": "盐续断",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944618713000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/ee02feed074f4227b77d662f763bd802.png",
      "organ": "肾、膀胱、大肠",
      "effect": "清热燥湿，泻火除蒸，解毒疗疮",
      "property": "苦、寒",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.湿热带下，热淋涩痛；\n2.湿热泻痢，黄疸；\n3.湿热脚气，痿证；\n4.骨蒸劳热，盗汗，遗精；\n5.疮疡肿毒，湿疹瘙痒。",
      "medicine_name": "盐黄柏",
      "suggest_dosage": "3～12g"
    },
    {
      "id": 1675686944618713000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/955185943c5d4779907000b71ac989ed.png",
      "organ": "大肠、肝",
      "effect": "清热解毒，止痢，截疟，腐蚀赘疣",
      "property": "苦、寒、有小毒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.热毒血痢，冷积久痢；\n2.各型疟疾；\n3.鸡眼赘疣。",
      "medicine_name": "鸦胆子",
      "suggest_dosage": "0.5～2g"
    },
    {
      "id": 1675686944614518800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/c8dcd0d6dc4146218b12de258ba67443.png",
      "organ": "肺、大肠",
      "effect": "清热生津，解毒",
      "property": "苦、酸、涩、平",
      "dict_name": "企标",
      "consumption": 3,
      "indications": "阴虚白喉",
      "medicine_name": "西青果",
      "suggest_dosage": "1.5～3g"
    },
    {
      "id": 1675686944606130200,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/945d5c5dee33488a9e3543f89309ed31.png",
      "organ": "肝、胆、肾",
      "effect": "重镇安神，潜阳补阴，软坚散结",
      "property": "咸、微寒",
      "dict_name": "企标",
      "consumption": 30,
      "indications": "1.心神不安，惊悸失眠；\n2.肝阳上亢，头晕目眩；\n3.痰核，療痂，癥核积聚；\n4.滑脱诸证。",
      "medicine_name": "生牡蛎",
      "suggest_dosage": "9～30g"
    },
    {
      "id": 1675686944580964400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/43a06aa71ac341dcbea68701ca77384a.png",
      "organ": "肝、胃",
      "effect": "利湿退黄，清热解毒，疏肝止痛",
      "property": "甘、微苦、凉",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.黄疸；\n2.乳痈；\n3.胁肋不舒，胃脱胀痛。",
      "medicine_name": "鸡骨草",
      "suggest_dosage": "15～30g"
    },
    {
      "id": 1675686944622907400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/f273cdab45634f46b83db2588ca0a859.webp",
      "organ": "肺、肾、胃",
      "effect": "温肺，助阳，平喘，制酸",
      "property": "甘、温、无毒",
      "dict_name": "企标",
      "consumption": 30,
      "indications": "1.寒痰喘咳，阳虚冷喘；\n2.腰膝冷痛；\n3.胃痛泛酸；\n4.乳汁不通。",
      "medicine_name": "钟乳石",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944564187100,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/323fb3e723774c4ab31ef4a1c0a8ad90.png",
      "organ": "肝、胃",
      "effect": "活血消癥，通经下乳，消肿排脓，搜风通络",
      "property": "咸、微寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.经闭癥瘕；\n2.乳汁不通；\n3.痈肿疮毒；\n4.风湿痹痛；\n5.中风瘫痪；\n6.麻木拘挛。",
      "medicine_name": "醋山甲",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1691779191164506000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-10-13/8729b7b9-378f-4d16-8284-80caf5845325.png",
      "organ": "心、肝、大肠",
      "effect": "活血祛瘀，润肠通便，止咳平喘",
      "property": "苦、甘、平、小毒",
      "dict_name": "企标",
      "consumption": 6,
      "indications": "1.瘀血阻滞诸证；\n2.肺痈，肠痈；\n3.肠燥便秘；\n4.咳嗽气喘。",
      "medicine_name": "燀桃仁（新标）",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944601936000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/3bca934075e24e189ce0446272ae7e5e.png",
      "organ": "肝",
      "effect": "息风镇痉，攻毒散结，通络止痛",
      "property": "辛、平、有毒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.痉挛抽搐；\n2.疮疡肿毒，療痂结核；\n3.风湿顽痹；\n4.顽固性偏正头痛。",
      "medicine_name": "全蝎",
      "suggest_dosage": "3～6g"
    },
    {
      "id": 1675686944614518800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/c5a3cc9a11324652a6c77c8a96ee0cb8.png",
      "organ": "肝",
      "effect": "息风镇痉，攻毒散结，通络止痛",
      "property": "辛、温、有毒",
      "dict_name": "企标",
      "consumption": 4,
      "indications": "1.痉挛抽搐；\n2.疮疡肿毒，療痂痰核。",
      "medicine_name": "蜈蚣",
      "suggest_dosage": "3～5g"
    },
    {
      "id": 1691780573833597000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-10-13/50a8bdcd-030f-48ab-bf82-4873dd749908.png",
      "organ": "肝",
      "effect": "破血通经，解毒消肿，杀虫",
      "property": "苦、寒、有毒",
      "dict_name": "国标",
      "consumption": 20,
      "indications": "1.跌打损伤；\n2.月经不调，产后腹痛；\n3.心腹疼痛，癥瘕结块；\n4.风湿痹痛。",
      "medicine_name": "鬼箭羽（新标）",
      "suggest_dosage": "4.5～9g"
    },
    {
      "id": 1691781010863296500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-10-13/75784bb1-a440-4ff7-a142-1adb059197d9.png",
      "organ": "肾、膀胱",
      "effect": "利水消肿，渗湿",
      "property": "甘、淡、平",
      "dict_name": "国标",
      "consumption": 14,
      "indications": "1.水肿；\n2.小便不利；\n3.泄泻。",
      "medicine_name": "猪苓（新标）",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944568381400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/6651dc67e0604dbc81097895f67472ee.png",
      "organ": "胃",
      "effect": "攻毒杀虫，祛风止痛",
      "property": "甘、平",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.疮疡肿毒，乳痈療痂，顽癣瘙痒，癌肿；\n2.风湿痹痛，牙痛，风疹瘙痒。",
      "medicine_name": "蜂房",
      "suggest_dosage": "3～5g"
    },
    {
      "id": 1675686944627101700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/156837f372bf4a9ea2a9467ebd7f7148.png",
      "organ": "心、肝",
      "effect": "清热凉血，活血，解毒透疹",
      "property": "甘、咸、寒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.温病血热毒盛，斑疹紫黑，麻疹不透；\n2.疮疡，湿疹，水火烫伤。",
      "medicine_name": "紫草",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944610324500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/7d84bb3c875342a290fa5f3589ea0890.png",
      "organ": "肺、胃",
      "effect": "利尿通淋，通气下乳",
      "property": "甘、淡、微寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.淋证，水肿；\n2.产后乳汁不下。",
      "medicine_name": "通草",
      "suggest_dosage": "3～5g"
    },
    {
      "id": 1675686944585158700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/c0448512d7bf493488fa6540ee3d0c13.png",
      "organ": "肝",
      "effect": "祛风，通络，止痉",
      "property": "甘、平",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.风湿顽痹，中风半身不遂；\n2.小儿惊风，破伤风；\n3.麻风，疥廨。",
      "medicine_name": "酒乌梢蛇",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944572575700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/07992bfc7b224d9091c98bd88c58f454.png",
      "organ": "肾、肝、心",
      "effect": "滋阴潜阳，益肾健骨，养血补心",
      "property": "甘、寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.阴虚阳亢，阴虚内热，虚风内动；\n2.肾虚骨痿，図门不合；\n3.阴血亏虚，惊悸，失眠，健忘。",
      "medicine_name": "龟甲",
      "suggest_dosage": "9～24g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/41100bea3ad947998ba3ec40491f8920.png",
      "organ": "脾、肝、肾",
      "effect": "理气止痛，温中助阳",
      "property": "咸、温、无毒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.胃寒胀痛；\n2.肝胃气痛；\n3.肾虚阳痿，腰膝酸痛。",
      "medicine_name": "炒九香虫",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944568381400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/a63d83f68e9b4759a1b3b3667f8866dc.webp",
      "organ": "肺、肾",
      "effect": "软坚，通淋，利尿，清肺火，化老痰",
      "property": "咸、寒、无毒",
      "dict_name": "企标",
      "consumption": 30,
      "indications": "1.痰热咳嗽，肺热久咳及痰稠；\n2.瘰疬，痰核；\n3.血淋，石淋，尿道涩痛；\n4.消渴；\n5.疝气；\n6.疔疮；\n7.发背；\n8.恶疮。",
      "medicine_name": "煅海浮石",
      "suggest_dosage": "3～5g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/7f770e0f187347d58ab7b6c511391ef4.png",
      "organ": "肝、肾",
      "effect": "滋阴潜阳，退热除蒸，软坚散结",
      "property": "甘、咸、寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.肝肾阴虚证；\n2.癥核积聚。",
      "medicine_name": "醋鳖甲",
      "suggest_dosage": "9～24g"
    },
    {
      "id": 1675686944555798500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/ea2d43a8f94d4bf5a5db15a01f573998.png",
      "organ": "肺、肝",
      "effect": "疏散风热，利咽开音，透疹，明目退翳，息风止痉",
      "property": "甘、寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.风热感冒，温病初起；\n2.麻疹不透，风疹瘙痒；\n3.目赤翳障；\n4.急慢惊风，破伤风证。",
      "medicine_name": "蝉蜕",
      "suggest_dosage": "3～6g"
    },
    {
      "id": 1675686944572575700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/df2873b6804b49c88b261352c3c7f35e.png",
      "organ": "肾、肝、心",
      "effect": "滋阴潜阳，益肾健骨，养血补心",
      "property": "甘、寒",
      "dict_name": "企标",
      "consumption": 1,
      "indications": "1.阴虚阳亢，阴虚内热，虚风内动；\n2.肾虚骨痿，図门不合；\n3.阴血亏虚，惊悸，失眠，健忘。",
      "medicine_name": "龟甲胶",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944568381400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/c956e5d608f3424f800ca69c9ca05242.png",
      "organ": "肝、心",
      "effect": "平肝潜阳，安神，定惊明目",
      "property": "咸、寒",
      "dict_name": "企标",
      "consumption": 30,
      "indications": "1.肝阳上亢，头晕目眩；\n2.惊悸失眠，心神不宁；\n3.目赤翳障，视物昏花。",
      "medicine_name": "煅珍珠母",
      "suggest_dosage": "10～25g"
    },
    {
      "id": 1691779906440138800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-10-13/a520c011-e93e-4c7d-a0c9-a7d3854da523.png",
      "organ": "心、脾、肾",
      "effect": "利水消肿，渗湿，健脾，宁心",
      "property": "甘、淡、平",
      "dict_name": "国标",
      "consumption": 12.5,
      "indications": "1.水肿；\n2.痰饮；\n3.脾虚泄泻；\n4.心悸，失眠。",
      "medicine_name": "茯苓（新标）",
      "suggest_dosage": "10～15g"
    },
    {
      "id": 1675686944601936000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/e7a8d2ef4b7e4218a71dfbce76877136.png",
      "organ": "肝、肾",
      "effect": "补肾固精，养肝明目",
      "property": "甘、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.肾虚腰痛，阳痿遗精，遗尿尿频，白带过多；\n2.目暗不明，头昏眼花。",
      "medicine_name": "沙苑子",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944601936000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/6b3755648c314d53aaacd6c0156bb498.png",
      "organ": "脾、胃、大肠",
      "effect": "涩肠止泻，温中行气",
      "property": "辛、温",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.虚泻，冷痢；\n2.胃寒胀痛，食少呕吐。",
      "medicine_name": "肉豆蔻",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944564187100,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/b8f2fc2810ee4bf4bff008c71c99394d.png",
      "organ": "心、肺、小肠",
      "effect": "利尿通淋，清心降火",
      "property": "甘、淡、微寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.淋证；\n2.心烦失眠，口舌生疮。",
      "medicine_name": "灯心草",
      "suggest_dosage": "1～3g"
    },
    {
      "id": 1675686944576770000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/067d3e74b04945a1b31957387797a435.png",
      "organ": "心、肺、脾",
      "effect": "大补元气，复脉固脱，益气摄血",
      "property": "甘、苦、温",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.体虚欲脱，肢冷脉微；\n2.气不摄血；3.崩漏下血。",
      "medicine_name": "红参",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/d92836e62da64e8883d93136cad1edb7.png",
      "organ": "脾、胃、肾",
      "effect": "行气止痛，温中止呕，纳气平喘",
      "property": "辛、苦、微温",
      "dict_name": "企标",
      "consumption": 1,
      "indications": "1.胸腹胀痛；\n2.胃寒呕吐；\n3.虚喘证。",
      "medicine_name": "沉香",
      "suggest_dosage": "1～5g"
    },
    {
      "id": 1675686944555798500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/ab2b4caaf2b94cdda50a6d567f506ecb.png",
      "organ": "肺、肝、肾",
      "effect": "补血，滋阴，润肺，止血",
      "property": "甘、平",
      "dict_name": "企标",
      "consumption": 1,
      "indications": "1.血虚诸证；\n2.出血证；\n3.肺阴虚燥咳；\n4.热病伤阴，心烦失眠，阴虚风动，手足癒从。",
      "medicine_name": "阿胶",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944585158700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/5185db946fde4cc2a48a79564f4eb86f.png",
      "organ": "肺",
      "effect": "清热解毒，利咽化痰，利尿通淋",
      "property": "苦、寒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.咽痛音哑，痰热咳嗽；\n2.小便不利，热淋涩痛；\n3.天疱疮；\n4.湿疹。",
      "medicine_name": "锦灯笼",
      "suggest_dosage": "5～9g"
    },
    {
      "id": 1675686944572575700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/c34c48d206bf4490ac6c25fa1ea2c3fb.png",
      "organ": "肝、肾",
      "effect": "补益肝肾，强筋壮骨",
      "property": "甘、微苦、平",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.阳痿，遗精；\n2.腰腿疼痛。",
      "medicine_name": "隔山撬",
      "suggest_dosage": "3～5g"
    },
    {
      "id": 1675686944622907400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/ac1cf93a499e449dbc67e2b5427deac4.png",
      "organ": "肺、肝、脾",
      "effect": "燥湿化痰，祛风解痉；外用散结消肿",
      "property": "苦、辛、温、有毒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.湿痰，寒痰证；\n2.风痰眩晕，中风，癫痫，破伤风；\n3.痈疽肿痛，蛇虫咬伤。",
      "medicine_name": "制天南星",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944614518800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/0dc99d81fb50477a9af5cc029606fc5a.png",
      "organ": "脾、胃、大肠",
      "effect": "涩肠止泻，温中行气",
      "property": "辛、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.虚泻，冷痢；\n2.胃寒胀痛，食少呕吐。",
      "medicine_name": "煨肉豆蔻",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944564187100,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/50dd471d87ff49e4abdc4d1b95a09640.png",
      "organ": "肝、胃",
      "effect": "疏肝，和胃，理气，化痰，解郁",
      "property": "辛、苦、甘、平",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.胸中痞闷，脘腹胀痛；\n2.呕吐，少食。",
      "medicine_name": "玳玳花",
      "suggest_dosage": "1.5～2.5g"
    },
    {
      "id": 1675686944610324500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/87576811bf9643c7821ed17fd531c79d.png",
      "organ": "肺、胃",
      "effect": "生津止渴；降血压",
      "property": "甘、怀平",
      "dict_name": "企标",
      "consumption": 2,
      "indications": "1.消渴；\n2.高血压病。",
      "medicine_name": "甜叶菊",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944589353000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/2d4a8e99073e42b3892cc255e3d13f8f.png",
      "organ": "胃、心",
      "effect": "清热解毒，凉血消斑",
      "property": "苦、寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.温病发热，发斑发瘆；2.肺热咳喘，喉痹；3.痄腮，丹毒，痈肿。",
      "medicine_name": "蓼大青叶",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944576770000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/90759c0df1e84177840970fab7a126c5.png",
      "organ": "肺、肾、肝、大肠",
      "effect": "清肺火，化老痰，软坚，通淋",
      "property": "咸、寒",
      "dict_name": "企标",
      "consumption": 30,
      "indications": "1.痰热喘嗽，老痰积块；\n2.瘿瘤，瘰疬；\n3.淋病；\n4.疝气；\n5.疮肿；6.目翳。",
      "medicine_name": "海浮石",
      "suggest_dosage": "3～5g"
    },
    {
      "id": 1675686944580964400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/74ea88fcdd73479d8a0adc7194ec5457.png",
      "organ": "脾、胃、小肠、膀胱",
      "effect": "消食健脾，涩精止遗",
      "property": "甘、平",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.饮食积滞，小儿疳积；2.肾虚遗精，遗尿；3.砂石淋证，胆结石。",
      "medicine_name": "鸡内金",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944576770000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/abb9fdb8509e448582fdb4863cf0a542.png",
      "organ": "肺、肾、胃",
      "effect": "清热化痰，软坚散结，制酸止痛",
      "property": "苦、咸、寒",
      "dict_name": "企标",
      "consumption": 30,
      "indications": "1.痰火咳嗽，胸胁疼痛，痰中带血；\n2.瘰疠瘿瘤；\n3.胃痛吞酸；\n4.外湿疹，烫伤。",
      "medicine_name": "蛤壳",
      "suggest_dosage": "6～15g"
    },
    {
      "id": 1675686944614518800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/b7c127f91f8d41ad8de440b3ee7cab34.png",
      "organ": "肝",
      "effect": "破血逐瘀，续筋接骨",
      "property": "咸、寒、小毒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.跌打损伤，筋伤骨折，瘀肿疼痛；\n2.血瘀经闭，产后瘀滞腹痛，积聚痞块（煎服）；\n3.1.Og,研末。",
      "medicine_name": "土鳖虫",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944572575700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/09f1c1d16bf14dd1ac0bc2996317cd87.png",
      "organ": "肝",
      "effect": "疏肝解郁，清热衬湿，消肿通乳",
      "property": "辛、寒",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.肝气郁结，情志不畅，心胸郁闷；\n2.关节肿痛；\n3.乳痈，乳少。",
      "medicine_name": "贯叶金丝桃",
      "suggest_dosage": "2～3g"
    },
    {
      "id": 1675686944589353000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/1574026be8eb4d03873e100ac7ec9af9.png",
      "organ": "肝、肾",
      "effect": "温补肝肾，益精养血",
      "property": "甘、咸、温、无毒",
      "dict_name": "企标",
      "consumption": 1,
      "indications": "1.肝肾不足所致的腰膝酸冷，阳痿遗精，虚劳羸瘦；2.崩漏下血，便血尿血；\n3.阴疽肿痛。",
      "medicine_name": "鹿角胶",
      "suggest_dosage": "3～6g"
    },
    {
      "id": 1691779671886270500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-10-13/94228424-d99e-4ae6-a9aa-49031596443c.png",
      "organ": "肝、胆、心",
      "effect": "活血止痛，行气解郁，清心凉血，利胆退黄",
      "property": "辛、苦、寒",
      "dict_name": "国标",
      "consumption": 5.5,
      "indications": "1.气滞血瘀痛证；\n2.热病神昏，癫痫痰闭；\n3.吐血，旭血，倒经，尿血，血淋；\n4.肝胆湿热黄疸，胆石证。",
      "medicine_name": "郁金（广西莪术）",
      "suggest_dosage": "3～10g"
    },
    {
      "id": 1675686944572575700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/557b8bb2320142cdb5c3b6cd460116ef.png",
      "organ": "心、脾",
      "effect": "宁心，安神，利水",
      "property": "甘、平、无毒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.心虚惊悸，健忘，失眠；\n2.惊痫；\n3.小便不利。",
      "medicine_name": "茯神",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944618713000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/d8ab74791dbe4683a8dc64009f621f93.png",
      "organ": "肝、肾",
      "effect": "理气，散结，止痛",
      "property": "苦、平",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.疝气疼痛，睾丸肿痛；\n2.乳痈乳癖。",
      "medicine_name": "盐橘核",
      "suggest_dosage": "3～9g"
    },
    {
      "id": 1675686944589353000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/f37699e52750496cbe876554428d1092.png",
      "organ": "肝、肾",
      "effect": "温肾助阳，收敛止血",
      "property": "咸、温、无毒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.脾肾阳虚，白带过多，遗尿尿频；\n2.崩漏下血；\n3.疮疡不敛。",
      "medicine_name": "鹿角霜",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944589353000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/161933786e9d4170a565984bc556ec2e.png",
      "organ": "肝",
      "effect": "舒筋活络，祛风止痛",
      "property": "苦、凉",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.风湿痹痛；\n2.腰肌劳损；3.跌打损伤。",
      "medicine_name": "宽筋藤",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944568381400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/5649f6815f874439a6f7a3abdb9e9f43.png",
      "organ": "心、肝、肾",
      "effect": "镇惊安神，平肝潜阳，聪耳明目，纳气平喘",
      "property": "咸、寒",
      "dict_name": "企标",
      "consumption": 60,
      "indications": "1.心神不宁，惊悸，失眠，癫痫；\n2.头晕目眩；\n3.耳鸣耳聋，视物昏花；\n4.肾虚气喘。",
      "medicine_name": "煅磁石",
      "suggest_dosage": "9～30g"
    },
    {
      "id": 1691780106663628800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-10-13/f3ef82aa-ce2d-4a2b-bcd1-d08fd18e085b.png",
      "organ": "肝、胃",
      "effect": "活血通经，下乳消痈，利尿通淋",
      "property": "苦、平",
      "dict_name": "国标",
      "consumption": 8,
      "indications": "1.血瘀经闭，痛经，难产；\n2.产后乳汁不下，乳痈肿痛；\n3.热淋，血淋，石淋。",
      "medicine_name": "炒王不留行（新标）",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944555798500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/70baa9226309442bb782ddd4ce2ca89e.png",
      "organ": "脾、胃",
      "effect": "补脾和中，化湿",
      "property": "甘、微温",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.脾气虚证；\n2.暑湿吐泻。",
      "medicine_name": "白扁豆",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944589353000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/c2a32a46dacc4b99afe9dded507c08c9.png",
      "organ": "肝、肾",
      "effect": "温肾阳，强筋骨，行血消肿",
      "property": "咸、温、无毒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.肾阳不足，阳痿遗精，腰脊冷痛；\n2.阴疽疮疡；\n3.乳痈初起；\n4.瘀血肿痛。",
      "medicine_name": "鹿角",
      "suggest_dosage": "6～15g"
    },
    {
      "id": 1691780347106300000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-10-13/e25c750f-cc95-471a-a42e-7e576d96ed47.png",
      "organ": "肾、大肠",
      "effect": "补肾阳，益精血，润肠通便",
      "property": "甘、咸、温",
      "dict_name": "国标",
      "consumption": 2.1,
      "indications": "1.肾阳不足，精血亏虚，阳痿不孕；\n2.腰膝酸软，筋骨无力；\n3.肠燥便秘。",
      "medicine_name": "酒苁蓉（新标）",
      "suggest_dosage": "6～10g"
    },
    {
      "id": 1675686944589353000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/d0f39c2eef0f4459abf67d62ae52d2b5.png",
      "organ": "肺、大肠",
      "effect": "清热化痰，润肺通便",
      "property": "甘、淡、平",
      "dict_name": "企标",
      "consumption": 3,
      "indications": "1.肺燥咳嗽，咯血；\n2.大便秘结。",
      "medicine_name": "龙脷叶",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944568381400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/90a8f626d2a74763be5c8e7983d6b5f0.png",
      "organ": "胃、肝、大肠",
      "effect": "清热燥湿，收涩止带，止泻，止血",
      "property": "苦、涩、寒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.赤白带下；\n2.湿热泻痢，久泻久痢；\n3.便血，崩漏。",
      "medicine_name": "麸炒椿皮",
      "suggest_dosage": "6～9g"
    },
    {
      "id": 1675686944606130200,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/9e2f8d8cf90c409cb1c711b8f6bbfd40.png",
      "organ": "肝、心",
      "effect": "平肝潜阳，降逆，止血",
      "property": "苦、寒",
      "dict_name": "企标",
      "consumption": 60,
      "indications": "1.眩晕耳鸣；\n2.呕吐，噫气，呃逆，喘息；\n3.吐血，衄血，崩漏下血。",
      "medicine_name": "生赭石",
      "suggest_dosage": "9～30g"
    },
    {
      "id": 1675686944593547300,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/d19454ea7bf14df1954d24f0d8eb72e4.png",
      "organ": "心、肝、脾",
      "effect": "活血止痛，消肿生肌",
      "property": "辛、苦、平",
      "dict_name": "企标",
      "consumption": 6,
      "indications": "1.胸痹心痛，胃脘疼痛，痛经经闭．产后瘀阻，癥瘕腹痛；\n2.风湿痹痛；\n3.跌打损伤；\n4.痈肿疮疡。",
      "medicine_name": "没药",
      "suggest_dosage": "3～5g"
    },
    {
      "id": 1675686944568381400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/22ae571bb47a44a78964520fda0a61c0.png",
      "organ": "肝、心、肺、胃",
      "effect": "平肝潜阳，重镇降逆，凉血止血",
      "property": "苦、寒",
      "dict_name": "企标",
      "consumption": 60,
      "indications": "1.眩晕耳鸣，呕吐；\n2.噫气，呃逆，喘息；\n3.吐血，衄血，崩漏下血。",
      "medicine_name": "煅赭石",
      "suggest_dosage": "9～30g"
    },
    {
      "id": 1675686944589353000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/8c51261c80da4ce7816fc2caff65347c.png",
      "organ": "肝、膀胱、肺、肾、胃",
      "effect": "止咳，抗癌，解毒，除疲，清热利湿，散淤消肿",
      "property": "苦、寒、有小毒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.热毒痈肿，疔疮，乳痈，丹毒，蛇咬伤；\n2.跌打损伤，瘀血肿痛；\n3.血热出血；\n4.头晕头痛；\n5.小便不利，水肿，湿疮，湿瘆；\n6.喘咳。",
      "medicine_name": "龙葵",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944572575700,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/461f8e3e306a4df597dadc4027115910.png",
      "organ": "心、脾、肾",
      "effect": "利水消肿，渗湿，健脾，宁心",
      "property": "甘、淡、平",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.水肿；\n2.痰饮；\n3.脾虚泄泻；\n4.心悸，失眠。",
      "medicine_name": "茯苓皮",
      "suggest_dosage": "15～30g"
    },
    {
      "id": 1675686944564187100,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/d5cf7b1ec5724976b06bb6247c200949.png",
      "organ": "肺、胃",
      "effect": "解表，除烦，宣发郁热",
      "property": "苦、辛、凉",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.外感表证；\n2.热病烦闷。",
      "medicine_name": "淡豆豉",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944580964400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/e62ca29d582e40549ca9b0403f0682e9.png",
      "organ": "心、肝、脾、肾",
      "effect": "祛风活血，止痛解毒，消食导滞，除湿消肿",
      "property": "甘、酸、平",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.风湿疼痛；\n2.腹泻痢疾，脘腹疼痛；\n3.气虚浮肿；\n4.头昏食少；\n5.肝脾肿大，瘰疬，肠痈；6.无名肿毒，跌打损伤。",
      "medicine_name": "鸡矢藤",
      "suggest_dosage": "30～60g"
    },
    {
      "id": 1675686944597741600,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/3685a1671fac4c97a6d2455bee8192cb.png",
      "organ": "心、肝",
      "effect": "养阴，止汗，健胃",
      "property": "甘、平",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.自汗，盗汗，乳糜尿。",
      "medicine_name": "糯稻根",
      "suggest_dosage": "5～12g"
    },
    {
      "id": 1675686944576770000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/42ca794131f94973a0c63d6c03e1b167.png",
      "organ": "肝、肾",
      "effect": "温肾助阳，祛寒止痛",
      "property": "苦、温、无毒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.肾阳不足，下元虚冷；\n2.小腹冷痛，寒疝腹痛；\n3.寒湿脚气。",
      "medicine_name": "胡芦巴",
      "suggest_dosage": "5～10g"
    },
    {
      "id": 1675686944559992800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/4394fc9ed9a74c86910825439bec4840.png",
      "organ": "心、脾、肾",
      "effect": "利水消肿，渗湿，健脾，宁心",
      "property": "甘、淡、平",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.水肿；\n2.痰饮；\n3.脾虚泄泻；\n4.心悸，失眠。",
      "medicine_name": "赤茯苓",
      "suggest_dosage": "10～15g"
    },
    {
      "id": 1675686944610324500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/55ae7ca868e8425cae68224ba016e7c9.png",
      "organ": "心、肝",
      "effect": "清热凉血，解毒，定惊",
      "property": "苦、寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.温病高热，神昏檐语，惊风，癫狂；\n2.血热妄行斑疹，吐旭；\n3.痈肿疮疡，咽喉肿痛。",
      "medicine_name": "水牛角",
      "suggest_dosage": "15～30g"
    },
    {
      "id": 1675686944589353000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/213e31d4873c4c92923eaf84b55e5091.png",
      "organ": "肝、胃、大肠",
      "effect": "泻下通便，清肝，杀虫",
      "property": "苦、寒",
      "dict_name": "企标",
      "consumption": 1,
      "indications": "1.热结便秘；\n2.烦躁惊痫；\n3.小儿疳积。",
      "medicine_name": "芦荟",
      "suggest_dosage": "2～5g"
    },
    {
      "id": 1675686944622907400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/5e486f006ed34dcbaf9b1a74015a232c.png",
      "organ": "心、肝、脾",
      "effect": "活血止痛，消肿生肌",
      "property": "辛、苦、平",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.胸痹心痛；\n2.胃脘疼痛；\n3.痛经经闭．产后瘀阻，癥瘕腹痛；\n4.风湿痹痛；\n5.跌打损伤；\n6.痈肿疮疡。",
      "medicine_name": "炙没药",
      "suggest_dosage": "3～5g"
    },
    {
      "id": 1675686944589353000,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/e5b237bc1db3453b8aab086bf1d8fd06.png",
      "organ": "肝、肾",
      "effect": "消痰软坚，利水消肿",
      "property": "咸、寒",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.瘿瘤，瘰疬；\n2.睾丸肿痛；\n3.痰饮水肿。",
      "medicine_name": "昆布",
      "suggest_dosage": "6～12g"
    },
    {
      "id": 1675686944568381400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/63986e00cd364decb9c396ecaa5cd205.png",
      "organ": "肝、胆、肾",
      "effect": "重镇安神，潜阳补阴，软坚散结",
      "property": "咸、微寒",
      "dict_name": "企标",
      "consumption": 30,
      "indications": "1.心神不安，惊悸失眠；\n2.肝阳上亢，头晕目眩；\n3.痰核，療痂，癥核积聚；\n4.滑脱诸证。",
      "medicine_name": "煅牡蛎",
      "suggest_dosage": "9～30g"
    },
    {
      "id": 1675686944606130200,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/99d176996e5b45199a43528cfe67ae5e.png",
      "organ": "脾、胃经",
      "effect": "消食和中，健脾开胃",
      "property": "甘、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.食积不消，腹胀口臭；\n2.脾胃虚弱，不饥食少。",
      "medicine_name": "生谷芽",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944622907400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/50b17c9461754e99966122bdbffa5b76.png",
      "organ": "肺、大肠",
      "effect": "祛痰开窍，散结消肿",
      "property": "辛、咸、温、有小毒",
      "dict_name": "企标",
      "consumption": 1,
      "indications": "1.中风口噤，昏迷不醒，癫痫痰盛，关窍不通；\n2.喉痹痰阻，顽痰喘咳，咯痰不爽；\n3.大便燥结，外痈肿。",
      "medicine_name": "猪牙皂",
      "suggest_dosage": "1～1.5g"
    },
    {
      "id": 1675686944568381400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/443682a414ee438992e19d0046c0fa3e.png",
      "organ": "肺、胃、肝",
      "effect": "消痰软坚，化瘀散结，制酸止痛",
      "property": "咸、平",
      "dict_name": "企标",
      "consumption": 30,
      "indications": "1.療痂，瘻瘤；\n2.癥痕痞块。",
      "medicine_name": "煅瓦楞子",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944622907400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/4c96e18a18b44f62a0d0e6b46fcbf6dd.png",
      "organ": "心、肝、脾",
      "effect": "活血行气止痛，消肿生肌",
      "property": "辛、苦、温",
      "dict_name": "企标",
      "consumption": 5,
      "indications": "1.跌打损伤，疮疡肿痛；\n2.气滞血瘀痛证。",
      "medicine_name": "炙乳香",
      "suggest_dosage": "3～5g"
    },
    {
      "id": 1675686944614518800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/124ab525c0034c89a89570e03d3b193a.png",
      "organ": "肝、胆、大肠",
      "effect": "清热利湿，退黄祛湿，凉血散瘀",
      "property": "苦、寒",
      "dict_name": "企标",
      "consumption": 7,
      "indications": "1.急性黄疸型肝炎，急性胆囊炎；\n2.肠炎，痢疾；\n3.跌打肿痛。",
      "medicine_name": "溪黄草",
      "suggest_dosage": "15～30g"
    },
    {
      "id": 1675686944610324500,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/4f9cb4ca1ad942389f5e27c75ca76acf.png",
      "organ": "肺、肝、胃",
      "effect": "清热利湿，解毒，散瘀消肿",
      "property": "甘、苦、凉",
      "dict_name": "企标",
      "consumption": 10,
      "indications": "1.湿热黄疸；\n2.泄泻，痢疾，肠痈，痈疖肿毒；\n3.乳蛾，口疮；\n4.目赤肿痛，毒蛇咬伤，跌打损伤。",
      "medicine_name": "田基黄",
      "suggest_dosage": "10～15g"
    },
    {
      "id": 1675686944568381400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/8b9586a354574e839aacf9eeb27c1d84.png",
      "organ": "心、肝、肾",
      "effect": "镇惊安神，平肝潜阳，收敛固涩",
      "property": "甘、涩、平",
      "dict_name": "企标",
      "consumption": 30,
      "indications": "1.心神不宁，心悸失眠，惊痫癫狂；\n2.肝阳眩晕；\n3.滑脱诸证；\n4.湿疮痒疹，疮疡久溃不敛。",
      "medicine_name": "煅龙骨",
      "suggest_dosage": "15～30g"
    },
    {
      "id": 1675686944606130200,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/92d4deff63e545c6b93d5e8f1835e8e5.png",
      "organ": "肺、胃、肝",
      "effect": "消痰软坚，化瘀散结，制酸止痛",
      "property": "咸、平",
      "dict_name": "企标",
      "consumption": 30,
      "indications": "1.療痂，瘻瘤；\n2.癥痕痞块。",
      "medicine_name": "生瓦楞子",
      "suggest_dosage": "9～15g"
    },
    {
      "id": 1675686944568381400,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/19b582225c1749ca93dcd126a2c173bd.png",
      "organ": "胃、大肠",
      "effect": "涩肠，止血，生肌敛疮",
      "property": "甘、涩、温、无毒",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.久泻久痢，大便出血，崩漏带下；\n2.疮疡久溃不敛，湿疮脓水浸淫。",
      "medicine_name": "煅赤石脂",
      "suggest_dosage": "9～12g"
    },
    {
      "id": 1675686944606130200,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/880cc99123304b828df3f211dd21d79a.png",
      "organ": "大肠、胃经",
      "effect": "涩肠，止血，生肌敛疮",
      "property": "甘、酸、涩、温",
      "dict_name": "企标",
      "consumption": 20,
      "indications": "1.久泻久痢，大便出血，崩漏带下；\n2.疮疡久溃不敛，湿疮脓水浸淫。",
      "medicine_name": "生赤石脂",
      "suggest_dosage": "9～12g"
    },
    {
      "id": 1675686944606130200,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/cda857d01bec424f93277327f8adc3af.png",
      "organ": "心、肝、肾",
      "effect": "镇惊安神，平肝潜阳，聪耳明目，纳气平喘",
      "property": "咸、寒",
      "dict_name": "企标",
      "consumption": 60,
      "indications": "1.心神不宁，惊悸，失眠，癫痫；\n2.头晕目眩；\n3.耳鸣耳聋，视物昏花；\n4.肾虚气喘。",
      "medicine_name": "生磁石",
      "suggest_dosage": "9～30g"
    },
    {
      "id": 1675686944614518800,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/effb5933d6484c98be9474b1b6fecfc8.png",
      "organ": "脾、肺",
      "effect": "健脾化湿，行气化痰，舒筋活络",
      "property": "甘、微温",
      "dict_name": "企标",
      "consumption": 8,
      "indications": "1.脾虚浮肿，食少无力；\n2.肺痨咳嗽，盗汗；\n3.带下，产后无乳；\n4.风湿痹痛；\n5.水肿，肝硬化腹水；\n6.跌打损伤。",
      "medicine_name": "五指毛桃",
      "suggest_dosage": "60～90g"
    },
    {
      "id": 1675686944593547300,
      "img": "https://huiyiimage.oss-cn-shenzhen.aliyuncs.com/medicine/2022-09-14/9e5e4f631061463c8754dbda10329413.png",
      "organ": "胃、大肠",
      "effect": "泻下攻积，润燥软坚，清热消肿",
      "property": "咸、苦、寒",
      "dict_name": "企标",
      "consumption": 1,
      "indications": "1.积滞便秘；\n2.咽痛，口疮，目赤，痈疮肿痛。",
      "medicine_name": "芒硝",
      "suggest_dosage": "6～12g"
    }
]
